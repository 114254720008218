export default {
  manneds: [
    {
      stationID: '466900',
      stationName: '淡水',
      stationNameEN: 'TAMSUI',
      stationAltitude: 19,
      longitude: 121.448906,
      latitude: 25.164888,
      countyName: '新北市',
      stationAddress: '淡水區中正東路42巷6號',
      startDate: '1942-10-01',
    },
    {
      stationID: '466920',
      stationName: '臺北',
      stationNameEN: 'TAIPEI',
      stationAltitude: 5.3,
      longitude: 121.514854,
      latitude: 25.037659,
      countyName: '臺北市',
      stationAddress: '中正區公園路64號',
      startDate: '1896-08-11',
    },
    {
      stationID: '467610',
      stationName: '成功',
      stationNameEN: 'CHENGGONG',
      stationAltitude: 33.5,
      longitude: 121.37343,
      latitude: 23.097486,
      countyName: '臺東縣',
      stationAddress: '成功鎮公民路84號',
      startDate: '1940-01-01',
    },
    {
      stationID: '466940',
      stationName: '基隆',
      stationNameEN: 'KEELUNG',
      stationAltitude: 26.7,
      longitude: 121.74048,
      latitude: 25.133314,
      countyName: '基隆市',
      stationAddress: '仁愛區港西街6號6樓(海港大樓6樓)',
      startDate: '1946-10-01',
    },
    {
      stationID: '467300',
      stationName: '東吉島',
      stationNameEN: 'DONGJIDAO',
      stationAltitude: 43,
      longitude: 119.667465,
      latitude: 23.25695,
      countyName: '澎湖縣',
      stationAddress: '望安鄉東吉村156號',
      startDate: '1963-01-01',
    },
    {
      stationID: '466850',
      stationName: '五分山雷達站',
      stationNameEN: 'WUFENSHAN',
      stationAltitude: 756,
      longitude: 121.781204,
      latitude: 25.071182,
      countyName: '新北市',
      stationAddress: '瑞芳區靜安路四段1巷1號',
      startDate: '1988-07-01',
    },
    {
      stationID: '467620',
      stationName: '蘭嶼',
      stationNameEN: 'LANYU',
      stationAltitude: 324,
      longitude: 121.55834,
      latitude: 22.036968,
      countyName: '臺東縣',
      stationAddress: '蘭嶼鄉紅頭村2號',
      startDate: '1941-11-01',
    },
    {
      stationID: '466990',
      stationName: '花蓮',
      stationNameEN: 'HUALIEN',
      stationAltitude: 16.1,
      longitude: 121.61327,
      latitude: 23.975128,
      countyName: '花蓮縣',
      stationAddress: '花蓮市花崗街24號',
      startDate: '1910-11-01',
    },
    {
      stationID: '467540',
      stationName: '大武',
      stationNameEN: 'DAWU',
      stationAltitude: 8.1,
      longitude: 120.903786,
      latitude: 22.355675,
      countyName: '臺東縣',
      stationAddress: '大武鄉大武街115號',
      startDate: '1940-01-01',
    },
    {
      stationID: '467420',
      stationName: '永康',
      stationNameEN: 'YONGKANG',
      stationAltitude: 8.1,
      longitude: 120.2367,
      latitude: 23.038385,
      countyName: '臺南市',
      stationAddress: '永康區鹽行里正南五街520巷88號',
      startDate: '1947-06-01',
    },
    {
      stationID: '467440',
      stationName: '高雄',
      stationNameEN: 'KAOHSIUNG',
      stationAltitude: 2.3,
      longitude: 120.315735,
      latitude: 22.565992,
      countyName: '高雄市',
      stationAddress: '前鎮區明孝里26鄰漁港南二路2號',
      startDate: '1931-04-01',
    },
    {
      stationID: '467660',
      stationName: '臺東',
      stationNameEN: 'TAITUNG',
      stationAltitude: 9,
      longitude: 121.15459,
      latitude: 22.75221,
      countyName: '臺東縣',
      stationAddress: '臺東市大同路106號',
      startDate: '1901-01-01',
    },
    {
      stationID: '467480',
      stationName: '嘉義',
      stationNameEN: 'CHIAYI',
      stationAltitude: 26.9,
      longitude: 120.43291,
      latitude: 23.495926,
      countyName: '嘉義市',
      stationAddress: '西區北新里海口寮路56號',
      startDate: '1968-09-01',
    },
    {
      stationID: '467060',
      stationName: '蘇澳',
      stationNameEN: 'SU-AO',
      stationAltitude: 24.9,
      longitude: 121.85737,
      latitude: 24.596737,
      countyName: '宜蘭縣',
      stationAddress: '蘇澳鎮港區路1號6樓(蘇澳港行政大樓6樓)',
      startDate: '1982-01-01',
    },
    {
      stationID: '467080',
      stationName: '宜蘭',
      stationNameEN: 'YILAN',
      stationAltitude: 7.2,
      longitude: 121.75653,
      latitude: 24.763975,
      countyName: '宜蘭縣',
      stationAddress: '宜蘭市力行路150號',
      startDate: '1935-12-06',
    },
    {
      stationID: '466910',
      stationName: '鞍部',
      stationNameEN: 'ANBU',
      stationAltitude: 837.6,
      longitude: 121.52973,
      latitude: 25.182587,
      countyName: '臺北市',
      stationAddress: '北投區陽明山竹子湖路111號',
      startDate: '1943-01-01',
    },
    {
      stationID: '466930',
      stationName: '竹子湖',
      stationNameEN: 'ZHUZIHU',
      stationAltitude: 607.1,
      longitude: 121.54455,
      latitude: 25.162079,
      countyName: '臺北市',
      stationAddress: '北投區陽明山竹子湖路2號',
      startDate: '1943-01-01',
    },
    {
      stationID: '466950',
      stationName: '彭佳嶼',
      stationNameEN: 'PENGJIAYU',
      stationAltitude: 101.7,
      longitude: 122.07974,
      latitude: 25.627975,
      countyName: '基隆市',
      stationAddress: '中正區彭佳嶼',
      startDate: '1936-05-01',
    },
    {
      stationID: '467410',
      stationName: '臺南',
      stationNameEN: 'TAINAN',
      stationAltitude: 40.8,
      longitude: 120.20477,
      latitude: 22.993238,
      countyName: '臺南市',
      stationAddress: '中西區公園路21號',
      startDate: '1897-01-01',
    },
    {
      stationID: '466880',
      stationName: '板橋',
      stationNameEN: 'BANQIAO',
      stationAltitude: 9.7,
      longitude: 121.44202,
      latitude: 24.997646,
      countyName: '新北市',
      stationAddress: '板橋區大觀路二段265巷62號',
      startDate: '2002-01-01',
    },
    {
      stationID: '467650',
      stationName: '日月潭',
      stationNameEN: 'SUN MOON LAKE',
      stationAltitude: 1017.5,
      longitude: 120.90805,
      latitude: 23.881325,
      countyName: '南投縣',
      stationAddress: '魚池鄉水社村中山路270巷14號',
      startDate: '1941-11-01',
    },
    {
      stationID: '467530',
      stationName: '阿里山',
      stationNameEN: 'ALISHAN',
      stationAltitude: 2413.4,
      longitude: 120.81324,
      latitude: 23.508207,
      countyName: '嘉義縣',
      stationAddress: '阿里山鄉中正村4鄰東阿里山73-1號',
      startDate: '1933-04-01',
    },
    {
      stationID: '467571',
      stationName: '新竹',
      stationNameEN: 'HSINCHU',
      stationAltitude: 26.9,
      longitude: 121.01422,
      latitude: 24.827852,
      countyName: '新竹縣',
      stationAddress: '竹北市北崙里3鄰光明五街60號',
      startDate: '1991-07-01',
    },
    {
      stationID: '467110',
      stationName: '金門',
      stationNameEN: 'KINMEN',
      stationAltitude: 47.9,
      longitude: 118.28928,
      latitude: 24.407307,
      countyName: '金門縣',
      stationAddress: '金城鎮金水里西海路一段250號',
      startDate: '2004-01-16',
    },
    {
      stationID: '467550',
      stationName: '玉山',
      stationNameEN: 'YUSHAN',
      stationAltitude: 3844.8,
      longitude: 120.95952,
      latitude: 23.487614,
      countyName: '南投縣',
      stationAddress: '信義鄉玉山北峰1號',
      startDate: '1943-10-01',
    },
    {
      stationID: '467770',
      stationName: '梧棲',
      stationNameEN: 'WUQI',
      stationAltitude: 31.7,
      longitude: 120.523384,
      latitude: 24.256002,
      countyName: '臺中市',
      stationAddress: '梧棲區臺灣大道十段2號海港大樓6樓',
      startDate: '1978-07-01',
    },
    {
      stationID: '467990',
      stationName: '馬祖',
      stationNameEN: 'MATSU',
      stationAltitude: 97.8,
      longitude: 119.923416,
      latitude: 26.16927,
      countyName: '連江縣',
      stationAddress: '南竿鄉四維村6鄰88號',
      startDate: '2004-01-01',
    },
    {
      stationID: '467350',
      stationName: '澎湖',
      stationNameEN: 'PENGHU',
      stationAltitude: 10.7,
      longitude: 119.563095,
      latitude: 23.565502,
      countyName: '澎湖縣',
      stationAddress: '馬公市新興路2號',
      startDate: '1896-11-21',
    },
    {
      stationID: '467790',
      stationName: '墾丁雷達站',
      stationNameEN: 'KENTING',
      stationAltitude: 42,
      longitude: 120.80803,
      latitude: 21.948235,
      countyName: '屏東縣',
      stationAddress: '恆春鎮燈塔路51巷33號',
      startDate: '2001-11-01',
    },
    {
      stationID: '467050',
      stationName: '新屋',
      stationNameEN: 'XINWU',
      stationAltitude: 20.6,
      longitude: 121.047485,
      latitude: 25.006744,
      countyName: '桃園市',
      stationAddress: '新屋區東興路二段946號',
      startDate: '2013-07-01',
    },
    {
      stationID: '467270',
      stationName: '田中',
      stationNameEN: 'TianZhong',
      stationAltitude: 48.6,
      longitude: 120.581345,
      latitude: 23.873775,
      countyName: '彰化縣',
      stationAddress: '田中鎮中潭里4鄰高鐵三路1段167號',
      startDate: '2020-04-09',
    },
    {
      stationID: '467490',
      stationName: '臺中',
      stationNameEN: 'TAICHUNG',
      stationAltitude: 84,
      longitude: 120.684074,
      latitude: 24.145737,
      countyName: '臺中市',
      stationAddress: '北區精武路295號',
      startDate: '1897-01-01',
    },
    {
      stationID: '467590',
      stationName: '恆春',
      stationNameEN: 'HENGCHUN',
      stationAltitude: 22.3,
      longitude: 120.74634,
      latitude: 22.003897,
      countyName: '屏東縣',
      stationAddress: '恆春鎮天文路50號',
      startDate: '1896-11-20',
    },
  ],
  unmanneds: [
    {
      stationID: 'C0Z220',
      stationName: '和平林道',
      stationNameEN: 'Hepinglindao',
      stationAltitude: 589,
      longitude: 121.65632,
      latitude: 24.343922,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉臺電公司東部發電廠碧海機組廠區內(進入管制哨約7公里處)',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C1S870',
      stationName: '南美山',
      stationNameEN: 'NanMaiSan',
      stationAltitude: 222,
      longitude: 121.38233,
      latitude: 23.15025,
      countyName: '臺東縣',
      stationAddress: '成功鎮南美山產業道路盡頭柑橘園',
      startDate: '2012-01-01',
    },
    {
      stationID: 'C0G740',
      stationName: '大城',
      stationNameEN: 'Dacheng',
      stationAltitude: 24,
      longitude: 120.32094,
      latitude: 23.852488,
      countyName: '彰化縣',
      stationAddress: '大城鄉東城村中平路185號(大城鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0R730',
      stationName: '大坪頂',
      stationNameEN: 'Dapingding',
      stationAltitude: 163,
      longitude: 120.69355,
      latitude: 22.024633,
      countyName: '屏東縣',
      stationAddress: '屏東縣恆春鎮大坪頂電信機房旁',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0X290',
      stationName: '北門',
      stationNameEN: 'Beimen',
      stationAltitude: 10,
      longitude: 120.12548,
      latitude: 23.267773,
      countyName: '臺南市',
      stationAddress: '北門區北門里舊埕108號(北門區公所)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1S860',
      stationName: '利嘉',
      stationNameEN: 'LiChai',
      stationAltitude: 516,
      longitude: 121.05053,
      latitude: 22.809418,
      countyName: '臺東縣',
      stationAddress: '卑南鄉利嘉林道6k附近梅子園',
      startDate: '2012-01-01',
    },
    {
      stationID: 'C1T950',
      stationName: '太安',
      stationNameEN: 'Tai-an',
      stationAltitude: 1050,
      longitude: 121.370125,
      latitude: 23.66672,
      countyName: '花蓮縣',
      stationAddress: '萬榮鄉太田事業區128林班地(馬太鞍苗圃)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0G750',
      stationName: '竹塘',
      stationNameEN: 'Zhutang',
      stationAltitude: 31,
      longitude: 120.382484,
      latitude: 23.846617,
      countyName: '彰化縣',
      stationAddress: '竹塘鄉永安村東陽路二段221號(永安派出所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0Z210',
      stationName: '瑞穗',
      stationNameEN: 'Ruisui',
      stationAltitude: 126,
      longitude: 121.39021,
      latitude: 23.5239,
      countyName: '花蓮縣',
      stationAddress: '瑞穗鄉鶴岡村167號(鶴岡國小)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0D480',
      stationName: '打鐵坑',
      stationNameEN: 'Datiekeng',
      stationAltitude: 223,
      longitude: 121.15079,
      latitude: 24.849665,
      countyName: '新竹縣',
      stationAddress: '新埔鎮新北里8鄰大北坑28號旁',
      startDate: '2008-01-01',
    },
    {
      stationID: 'C1A9N0',
      stationName: '四十份',
      stationNameEN: 'Sihshihfen',
      stationAltitude: 435,
      longitude: 121.593285,
      latitude: 24.934158,
      countyName: '新北市',
      stationAddress: '新店區北宜路旁(文山事業區第四林班)',
      startDate: '2000-06-01',
    },
    {
      stationID: 'C0S830',
      stationName: '長濱',
      stationNameEN: 'Changbin',
      stationAltitude: 288,
      longitude: 121.41197,
      latitude: 23.286818,
      countyName: '臺東縣',
      stationAddress: '長濱鄉竹湖村移民橋後方山頂三角點旁',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0R740',
      stationName: '獅子',
      stationNameEN: 'Shizi',
      stationAltitude: 58,
      longitude: 120.70525,
      latitude: 22.201569,
      countyName: '屏東縣',
      stationAddress: '屏東縣獅子鄉楓林村2巷26號',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0X280',
      stationName: '將軍',
      stationNameEN: 'Jiangjun',
      stationAltitude: 22,
      longitude: 120.13577,
      latitude: 23.214998,
      countyName: '臺南市',
      stationAddress: '將軍區將貴里58號(將軍國小)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1U830',
      stationName: '烏石鼻',
      stationNameEN: 'Wushibi',
      stationAltitude: 618,
      longitude: 121.839386,
      latitude: 24.482681,
      countyName: '宜蘭縣',
      stationAddress: '蘇澳鎮之烏石鼻(南澳事業區第10鄰班地上)',
      startDate: '2011-01-01',
    },
    {
      stationID: 'C1T980',
      stationName: '龍澗',
      stationNameEN: 'Longjian',
      stationAltitude: 1306,
      longitude: 121.41133,
      latitude: 24.023289,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉龍澗發電廠旁',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0G760',
      stationName: '高鐵彰化',
      stationNameEN: 'THSR Changhua',
      stationAltitude: 53,
      longitude: 120.57461,
      latitude: 23.875246,
      countyName: '彰化縣',
      stationAddress: '田中鎮站區路二段99號(高鐵彰化站外)',
      startDate: '2017-08-01',
    },
    {
      stationID: 'C0R510',
      stationName: '萬丹',
      stationNameEN: 'WanDan',
      stationAltitude: 31,
      longitude: 120.490395,
      latitude: 22.587774,
      countyName: '屏東縣',
      stationAddress: '萬丹鄉萬安村和平東路69號(萬丹消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1I510',
      stationName: '線浸林道',
      stationNameEN: 'Xianjinlindao',
      stationAltitude: 1208,
      longitude: 120.832954,
      latitude: 23.761509,
      countyName: '南投縣',
      stationAddress: '鹿谷鄉線浸林道11k附近',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1Z040',
      stationName: '立山',
      stationNameEN: 'Lishan',
      stationAltitude: 434,
      longitude: 121.32738,
      latitude: 23.443356,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉立山村後山上',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0S840',
      stationName: '南田',
      stationNameEN: 'Nantian',
      stationAltitude: 22,
      longitude: 120.889206,
      latitude: 22.279724,
      countyName: '臺東縣',
      stationAddress: '達仁鄉南田村南田風景區公園對面',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0R750',
      stationName: '四林格山',
      stationNameEN: 'Silingeshan',
      stationAltitude: 551,
      longitude: 120.80244,
      latitude: 22.076988,
      countyName: '屏東縣',
      stationAddress: '屏東縣牡丹鄉四林格山遊樂區第2涼亭旁大草原',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0Z230',
      stationName: '和平',
      stationNameEN: 'Heping',
      stationAltitude: 31,
      longitude: 121.77007,
      latitude: 24.30717,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉克來寶段399地號(和平工業港安檢所綠洲分隊)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0X050',
      stationName: '東河',
      stationNameEN: 'Donghe',
      stationAltitude: 25,
      longitude: 120.385605,
      latitude: 23.296598,
      countyName: '臺南市',
      stationAddress: '東山區東河里54號(東河水利工作站)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1T970',
      stationName: '大農',
      stationNameEN: 'Danong',
      stationAltitude: 183,
      longitude: 121.41333,
      latitude: 23.615232,
      countyName: '花蓮縣',
      stationAddress: '光復鄉花蓮糖廠之大農農場內(大農段691號)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C1S880',
      stationName: '壽卡',
      stationNameEN: 'Shouka',
      stationAltitude: 474,
      longitude: 120.8589,
      latitude: 22.23891,
      countyName: '臺東縣',
      stationAddress: '達仁鄉(大武事業區第48林班內)電信轉播站後方',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0G770',
      stationName: '福興',
      stationNameEN: 'Fuxing',
      stationAltitude: 15,
      longitude: 120.43767,
      latitude: 24.041288,
      countyName: '彰化縣',
      stationAddress: '福興鄉員鹿路二段270號(福興國中)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0E590',
      stationName: '通霄',
      stationNameEN: 'Tongxiao',
      stationAltitude: 40,
      longitude: 120.70462,
      latitude: 24.471891,
      countyName: '苗栗縣',
      stationAddress: '通霄鎮城北里1鄰13號(通霄鎮城中國民小學內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R520',
      stationName: '崁頂',
      stationNameEN: 'Kanding',
      stationAltitude: 18,
      longitude: 120.50691,
      latitude: 22.514782,
      countyName: '屏東縣',
      stationAddress: '崁頂鄉崁頂村興農路29-10號(崁頂消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1Z030',
      stationName: '紅葉',
      stationNameEN: 'Hongye',
      stationAltitude: 218,
      longitude: 121.338806,
      latitude: 23.493118,
      countyName: '花蓮縣',
      stationAddress: '萬榮鄉紅葉村教堂附近',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R760',
      stationName: '南仁湖',
      stationNameEN: 'Nanrenhu',
      stationAltitude: 336,
      longitude: 120.86335,
      latitude: 22.08468,
      countyName: '屏東縣',
      stationAddress: '屏東縣滿州鄉南仁湖步道4K山頂觀測站旁',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1I500',
      stationName: '大尖山',
      stationNameEN: 'Dajianshan',
      stationAltitude: 2017,
      longitude: 120.99489,
      latitude: 23.858572,
      countyName: '南投縣',
      stationAddress: '仁愛鄉大尖山',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0X060',
      stationName: '下營',
      stationNameEN: 'Xiaying',
      stationAltitude: 19,
      longitude: 120.25626,
      latitude: 23.22695,
      countyName: '臺南市',
      stationAddress: '下營區仁里里下營國小附近',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1U850',
      stationName: '觀音海岸',
      stationNameEN: 'Guanyin Coast',
      stationAltitude: 665,
      longitude: 121.77856,
      latitude: 24.38319,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉之林務局羅東林區管理處和平事業區91林班',
      startDate: '2011-07-01',
    },
    {
      stationID: 'C0Z020',
      stationName: '明里',
      stationNameEN: 'Mingli',
      stationAltitude: 211,
      longitude: 121.262344,
      latitude: 23.208717,
      countyName: '花蓮縣',
      stationAddress: '富里鄉明里村明里社區後方山上',
      startDate: '2010-07-01',
    },
    {
      stationID: 'C1S670',
      stationName: '摩天',
      stationNameEN: 'Motian',
      stationAltitude: 1580,
      longitude: 121.027,
      latitude: 23.199503,
      countyName: '臺東縣',
      stationAddress: '海端鄉(省道臺20線169K福德宮後方)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0T960',
      stationName: '光復',
      stationNameEN: 'Guangfu',
      stationAltitude: 120,
      longitude: 121.424965,
      latitude: 23.660694,
      countyName: '花蓮縣',
      stationAddress: '光復鄉大進村糖廠街19號(台糖公司花蓮光復糖廠內)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0G780',
      stationName: '秀水',
      stationNameEN: 'Xiushui',
      stationAltitude: 27,
      longitude: 120.50387,
      latitude: 24.03405,
      countyName: '彰化縣',
      stationAddress: '秀水鄉安東村中山路257號(秀水國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R530',
      stationName: '林邊',
      stationNameEN: 'Linbian',
      stationAltitude: 16,
      longitude: 120.50998,
      latitude: 22.430836,
      countyName: '屏東縣',
      stationAddress: '林邊鄉光林村和平路45號(林邊鄉衛生所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0R770',
      stationName: '保力',
      stationNameEN: 'Baoli',
      stationAltitude: 93,
      longitude: 120.764114,
      latitude: 22.071121,
      countyName: '屏東縣',
      stationAddress: '屏東縣車城鄉保力村竹社路5號',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0Z270',
      stationName: '蕃薯寮',
      stationNameEN: 'Fanshuliao',
      stationAltitude: 343,
      longitude: 121.54011,
      latitude: 23.73835,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉水璉村蕃薯寮附近山區道路旁(牛山段607地號內)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C0Z250',
      stationName: '瑞穗林道',
      stationNameEN: 'Ruisuilindao',
      stationAltitude: 1124,
      longitude: 121.30688,
      latitude: 23.49284,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉瑞穗林道約7.5K瑞欣礦區下方空地',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1U840',
      stationName: '東澳嶺',
      stationNameEN: 'Dongaoling',
      stationAltitude: 750,
      longitude: 121.8485,
      latitude: 24.5378,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉',
      startDate: '2011-07-01',
    },
    {
      stationID: 'C1T990',
      stationName: '高寮',
      stationNameEN: 'Gaoliao',
      stationAltitude: 128,
      longitude: 121.35681,
      latitude: 23.39424,
      countyName: '花蓮縣',
      stationAddress: '玉里鎮觀音里15鄰9號(觀音國民小學校園內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R540',
      stationName: '佳冬',
      stationNameEN: 'Jiadong',
      stationAltitude: 17,
      longitude: 120.552376,
      latitude: 22.419456,
      countyName: '屏東縣',
      stationAddress: '佳冬鄉佳昌路2號(佳冬鄉公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0R780',
      stationName: '滿州',
      stationNameEN: 'Manzhou',
      stationAltitude: 72,
      longitude: 120.83989,
      latitude: 22.024403,
      countyName: '屏東縣',
      stationAddress: '屏東縣滿州鄉文化路32號',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0X080',
      stationName: '佳里',
      stationNameEN: 'Jiali',
      stationAltitude: 2,
      longitude: 120.14509,
      latitude: 23.173038,
      countyName: '臺南市',
      stationAddress: '佳里區西方之埔尾，近七股區之篤加',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C1R120',
      stationName: '上德文',
      stationNameEN: 'Shangdewun',
      stationAltitude: 820,
      longitude: 120.704445,
      latitude: 22.763271,
      countyName: '屏東縣',
      stationAddress: '三地門鄉德文村口之公墓旁',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0H890',
      stationName: '埔里',
      stationNameEN: 'Puli',
      stationAltitude: 440,
      longitude: 120.95242,
      latitude: 23.97217,
      countyName: '南投縣',
      stationAddress: '埔里鎮大城里大城路169號(南投縣立大成國民中學內)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0FA00',
      stationName: '烏石坑',
      stationNameEN: 'Shinmei',
      stationAltitude: 983,
      longitude: 120.94762,
      latitude: 24.2735,
      countyName: '臺中市',
      stationAddress: '和平區東崎路2段烏石巷109號',
      startDate: '2020-10-31',
    },
    {
      stationID: 'C0R550',
      stationName: '新埤',
      stationNameEN: 'Xinpi',
      stationAltitude: 38,
      longitude: 120.58289,
      latitude: 22.467947,
      countyName: '屏東縣',
      stationAddress: '新埤鄉民眾路103號(新埤消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0R790',
      stationName: '九棚',
      stationNameEN: 'Jiupeng',
      stationAltitude: 111,
      longitude: 120.88928,
      latitude: 22.14195,
      countyName: '屏東縣',
      stationAddress: '屏東縣牡丹鄉九棚中科院基地',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0Z050',
      stationName: '佳心',
      stationNameEN: 'Jiaxin',
      stationAltitude: 824,
      longitude: 121.21316,
      latitude: 23.34567,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉佳心(玉山國家公園內，距花蓮卓溪鄉南安遊客步行2.5時之日據時代派出所舊址)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C1I550',
      stationName: '國六W023K',
      stationNameEN: 'Freeway No. 6 - RAIN – W023K',
      stationAltitude: 373,
      longitude: 120.867744,
      latitude: 23.979067,
      countyName: '南投縣',
      stationAddress: '國道6號西向23.7K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C0Z290',
      stationName: '赤柯山',
      stationNameEN: 'Chikeshan',
      stationAltitude: 946,
      longitude: 121.38293,
      latitude: 23.36893,
      countyName: '花蓮縣',
      stationAddress: '玉里鎮赤柯山段338地號',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1I310',
      stationName: '西巒',
      stationNameEN: 'Xiluan',
      stationAltitude: 1033,
      longitude: 120.88396,
      latitude: 23.754486,
      countyName: '南投縣',
      stationAddress: '信義鄉台21線道之人倫林道9.5k處',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1R110',
      stationName: '口社',
      stationNameEN: 'Gusia',
      stationAltitude: 110,
      longitude: 120.645226,
      latitude: 22.770111,
      countyName: '屏東縣',
      stationAddress: '三地門鄉口社村5鄰信義巷58-5號',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0AI00',
      stationName: '五股',
      stationNameEN: 'Wugu',
      stationAltitude: 188,
      longitude: 121.42728,
      latitude: 25.07445,
      countyName: '新北市',
      stationAddress: '五股區外寮路12號',
      startDate: '2018-07-10',
    },
    {
      stationID: 'C0F000',
      stationName: '大肚',
      stationNameEN: 'Dadu',
      stationAltitude: 273,
      longitude: 120.57212,
      latitude: 24.15295,
      countyName: '臺中市',
      stationAddress: '大肚區瑞井里遊園路一段2號(臺中區監理所內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R320',
      stationName: '車城',
      stationNameEN: 'Checheng',
      stationAltitude: 7,
      longitude: 120.71607,
      latitude: 22.074034,
      countyName: '屏東縣',
      stationAddress: '車城鄉福興村中山路66號(車城國小)',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0R560',
      stationName: '新園',
      stationNameEN: 'Xinyuan',
      stationAltitude: 22,
      longitude: 120.461555,
      latitude: 22.5434,
      countyName: '屏東縣',
      stationAddress: '新園鄉仙吉村店口路116號(新園消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0S890',
      stationName: '關山',
      stationNameEN: 'Guanshan',
      stationAltitude: 238,
      longitude: 121.17502,
      latitude: 23.0395,
      countyName: '臺東縣',
      stationAddress: '關山鎮親水公園內',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0Z280',
      stationName: '德武',
      stationNameEN: 'Dewu',
      stationAltitude: 224,
      longitude: 121.41071,
      latitude: 23.47426,
      countyName: '花蓮縣',
      stationAddress: '玉里鎮德武里附近山區道路旁(德武段680地號內)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0C540',
      stationName: '大園',
      stationNameEN: 'Dayuan',
      stationAltitude: 46,
      longitude: 121.22596,
      latitude: 25.04775,
      countyName: '桃園市',
      stationAddress: '大園區埔心里25鄰中正東路1段189巷35號(埔心國民小學)',
      startDate: '2008-01-01',
    },
    {
      stationID: 'C0V260',
      stationName: '月眉',
      stationNameEN: 'Yuemei',
      stationAltitude: 126,
      longitude: 120.539856,
      latitude: 22.971003,
      countyName: '高雄市',
      stationAddress: '杉林區月眉里山仙路1號月眉農場',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0F9T0',
      stationName: '西屯',
      stationNameEN: 'Xitun',
      stationAltitude: 111,
      longitude: 120.64127,
      latitude: 24.179495,
      countyName: '臺中市',
      stationAddress: '西屯區西屯路二段300號(西屯國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0G900',
      stationName: '線西',
      stationNameEN: 'xianxi',
      stationAltitude: 20,
      longitude: 120.443535,
      latitude: 24.143358,
      countyName: '彰化縣',
      stationAddress: '線西鄉慶福路300號（彰濱工業區線西服務大樓內)',
      startDate: '2019-11-22',
    },
    {
      stationID: 'C0X200',
      stationName: '左鎮',
      stationNameEN: 'Zuozhen',
      stationAltitude: 61,
      longitude: 120.40852,
      latitude: 23.056759,
      countyName: '臺南市',
      stationAddress: '左鎮區中正里171號之4(左鎮區公所)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0W110',
      stationName: '東莒',
      stationNameEN: 'Dongju',
      stationAltitude: 16,
      longitude: 119.98069,
      latitude: 25.9652,
      countyName: '連江縣',
      stationAddress: '莒光鄉福正村1號(福正抽水機房，莒光遊客中心後方)',
      startDate: '2016-07-01',
    },
    {
      stationID: 'C0O860',
      stationName: '大內',
      stationNameEN: 'Danei',
      stationAltitude: 38,
      longitude: 120.36083,
      latitude: 23.11885,
      countyName: '臺南市',
      stationAddress: '大內區74201大內里63號(大內國民小學)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0M680',
      stationName: '太保',
      stationNameEN: 'Taibao',
      stationAltitude: 37,
      longitude: 120.33145,
      latitude: 23.455063,
      countyName: '嘉義縣',
      stationAddress: '太保市太保里31號(太保市圖書館)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0I080',
      stationName: '信義',
      stationNameEN: 'Xinyi',
      stationAltitude: 536,
      longitude: 120.851036,
      latitude: 23.689693,
      countyName: '南投縣',
      stationAddress: '信義鄉自強村綠美巷(內茅埔營林區23林班地)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1E770',
      stationName: '公館',
      stationNameEN: 'Gongguan',
      stationAltitude: 83,
      longitude: 120.8308,
      latitude: 24.526915,
      countyName: '苗栗縣',
      stationAddress: '公館鄉鶴岡村178號(鶴岡國中)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0F9U0',
      stationName: '南屯',
      stationNameEN: 'Nantun',
      stationAltitude: 74,
      longitude: 120.63797,
      latitude: 24.137007,
      countyName: '臺中市',
      stationAddress: '南屯區黎明路一段968號(南屯國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0V490',
      stationName: '新興',
      stationNameEN: 'Sinsing',
      stationAltitude: 20,
      longitude: 120.298805,
      latitude: 22.627422,
      countyName: '高雄市',
      stationAddress: '新興區大同一路231號(大同國小內5樓頂)',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0G910',
      stationName: '花壇',
      stationNameEN: 'Huatan',
      stationAltitude: 42,
      longitude: 120.54946,
      latitude: 24.032076,
      countyName: '彰化縣',
      stationAddress: '彰化縣花壇鄉彰員路2段580號（花壇國中內）',
      startDate: '2019-12-20',
    },
    {
      stationID: 'C0V250',
      stationName: '甲仙',
      stationNameEN: 'Jiasian',
      stationAltitude: 298,
      longitude: 120.59176,
      latitude: 23.080107,
      countyName: '高雄市',
      stationAddress: '甲仙區(甲仙國民中學操場後方小山丘上)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0A9C0',
      stationName: '天母',
      stationNameEN: 'Tianmu',
      stationAltitude: 35,
      longitude: 121.53717,
      latitude: 25.117495,
      countyName: '臺北市',
      stationAddress: '士林區天母東路116號(三玉國小內)',
      startDate: '1997-12-01',
    },
    {
      stationID: 'C0E730',
      stationName: '頭份',
      stationNameEN: 'Toufen',
      stationAltitude: 26,
      longitude: 120.91221,
      latitude: 24.688248,
      countyName: '苗栗縣',
      stationAddress: '頭份市中正路219號(苗栗縣頭份鎮頭份國小校園內)',
      startDate: '2007-12-01',
    },
    {
      stationID: 'C1M480',
      stationName: '獨立山',
      stationNameEN: 'Dulishan',
      stationAltitude: 840,
      longitude: 120.607796,
      latitude: 23.537022,
      countyName: '嘉義縣',
      stationAddress: '竹崎鄉(獨立山三角點旁)',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C0I090',
      stationName: '鳳凰',
      stationNameEN: 'Fonghuang',
      stationAltitude: 910,
      longitude: 120.787346,
      latitude: 23.72808,
      countyName: '南投縣',
      stationAddress: '鹿谷鄉鳳凰鳥園旁',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0M690',
      stationName: '水上',
      stationNameEN: 'Shuishang',
      stationAltitude: 33,
      longitude: 120.38891,
      latitude: 23.419733,
      countyName: '嘉義縣',
      stationAddress: '水上鄉三鎮村三鎮路119號(水上消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0D650',
      stationName: '湖口',
      stationNameEN: 'Hukou',
      stationAltitude: 97,
      longitude: 121.043625,
      latitude: 24.904795,
      countyName: '新竹縣',
      stationAddress: '湖口鄉信勢村三鄰成功路109號(湖口消防分隊)',
      startDate: '2013-10-01',
    },
    {
      stationID: 'C0E740',
      stationName: '造橋',
      stationNameEN: 'Zaoqiao',
      stationAltitude: 27,
      longitude: 120.86478,
      latitude: 24.637056,
      countyName: '苗栗縣',
      stationAddress: '造橋鄉造橋村14鄰3號(造橋國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0F9V0',
      stationName: '新社',
      stationNameEN: 'Xinshe',
      stationAltitude: 525,
      longitude: 120.8158,
      latitude: 24.200172,
      countyName: '臺中市',
      stationAddress: '新社區大南里興中街46號(農委會種苗改良繁殖場)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X220',
      stationName: '學甲',
      stationNameEN: 'Xuejia',
      stationAltitude: 26,
      longitude: 120.18219,
      latitude: 23.230368,
      countyName: '臺南市',
      stationAddress: '學甲區華宗路313號(學甲區公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0W130',
      stationName: '花嶼',
      stationNameEN: 'Huayu',
      stationAltitude: 18,
      longitude: 119.32276,
      latitude: 23.40007,
      countyName: '澎湖縣',
      stationAddress: '望安鄉花嶼村2號(花嶼國民小學)',
      startDate: '2016-07-01',
    },
    {
      stationID: 'C0K280',
      stationName: '四湖',
      stationNameEN: 'Sihu',
      stationAltitude: 23,
      longitude: 120.22713,
      latitude: 23.630413,
      countyName: '雲林縣',
      stationAddress: '四湖鄉湖西村中正路766號(四湖國民中學內)',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C0D660',
      stationName: '新竹市東區',
      stationNameEN: 'Dongqu Hsinshu City',
      stationAltitude: 65,
      longitude: 120.986946,
      latitude: 24.798676,
      countyName: '新竹市',
      stationAddress: '東區光復路二段321號（工研院光復院區6館）',
      startDate: '2014-11-01',
    },
    {
      stationID: 'C0E750',
      stationName: '苗栗',
      stationNameEN: 'Miaoli',
      stationAltitude: 62,
      longitude: 120.82458,
      latitude: 24.56457,
      countyName: '苗栗縣',
      stationAddress: '苗栗市中正路241號(建功國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0W120',
      stationName: '西嶼',
      stationNameEN: 'Xiyu',
      stationAltitude: 58,
      longitude: 119.46844,
      latitude: 23.5626,
      countyName: '澎湖縣',
      stationAddress: '西嶼鄉195號(西嶼燈塔西方)',
      startDate: '2016-07-01',
    },
    {
      stationID: 'C0A9E0',
      stationName: '士林',
      stationNameEN: 'Shihlin',
      stationAltitude: 26,
      longitude: 121.50302,
      latitude: 25.090254,
      countyName: '臺北市',
      stationAddress: '士林區延平北路6段308號(社子國小內)',
      startDate: '1997-12-01',
    },
    {
      stationID: 'C0X210',
      stationName: '白河',
      stationNameEN: 'Baihe',
      stationAltitude: 38,
      longitude: 120.41443,
      latitude: 23.347466,
      countyName: '臺南市',
      stationAddress: '白河區中山路6號(白河消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0K291',
      stationName: '宜梧',
      stationNameEN: 'Yiwu',
      stationAltitude: 8,
      longitude: 120.16933,
      latitude: 23.536282,
      countyName: '雲林縣',
      stationAddress: '口湖鄉湖口村170號央廣口湖分台內(憲兵排樓頂)',
      startDate: '2010-09-27',
    },
    {
      stationID: 'C1F871',
      stationName: '上谷關',
      stationNameEN: 'Shangguguan',
      stationAltitude: 1000,
      longitude: 121.01865,
      latitude: 24.203484,
      countyName: '臺中市',
      stationAddress: '和平區(博愛國小谷關分校附近)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1S800',
      stationName: '華源',
      stationNameEN: 'Huayuan',
      stationAltitude: 80,
      longitude: 121.02325,
      latitude: 22.657883,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉華源村',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0F9X0',
      stationName: '大雅(中科園區)',
      stationNameEN: 'Daya',
      stationAltitude: 166,
      longitude: 120.62445,
      latitude: 24.21528,
      countyName: '臺中市',
      stationAddress: '大雅區中部科學工業園區臺中園區(汙水處理機房樓頂)',
      startDate: '2015-08-21',
    },
    {
      stationID: 'C0D430',
      stationName: '峨眉',
      stationNameEN: 'Emei',
      stationAltitude: 87,
      longitude: 121.01719,
      latitude: 24.6905,
      countyName: '新竹縣',
      stationAddress: '峨眉鄉31541峨眉村峨眉街54號(峨眉國中校園內)',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C0E520',
      stationName: '大湖',
      stationNameEN: 'Dahu',
      stationAltitude: 320,
      longitude: 120.87087,
      latitude: 24.41494,
      countyName: '苗栗縣',
      stationAddress: '大湖鄉大寮村5鄰竹高屋34-1號附近',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X240',
      stationName: '關子嶺',
      stationNameEN: 'Guanziling',
      stationAltitude: 394,
      longitude: 120.508026,
      latitude: 23.33117,
      countyName: '臺南市',
      stationAddress: '白河區關嶺里94-3號(仙草國小關嶺分校)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0F850',
      stationName: '東勢',
      stationNameEN: 'Dongshi',
      stationAltitude: 379,
      longitude: 120.833046,
      latitude: 24.246428,
      countyName: '臺中市',
      stationAddress: '東勢區東關路647號(東勢林區管理處雙崎工作站內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0W150',
      stationName: '金寧',
      stationNameEN: 'Jinning',
      stationAltitude: 42,
      longitude: 118.32899,
      latitude: 24.457703,
      countyName: '金門縣',
      stationAddress: '金寧鄉頂林路315號(金門縣消防局)',
      startDate: '2016-10-05',
    },
    {
      stationID: 'C0B010',
      stationName: '七堵',
      stationNameEN: 'Qidu',
      stationAltitude: 38,
      longitude: 121.71713,
      latitude: 25.094818,
      countyName: '基隆市',
      stationAddress: '七堵區東新街22號(基隆商工樓頂)',
      startDate: '2018-06-23',
    },
    {
      stationID: 'C1X040',
      stationName: '東原',
      stationNameEN: 'Dongyuan',
      stationAltitude: 232,
      longitude: 120.46382,
      latitude: 23.291597,
      countyName: '臺南市',
      stationAddress: '東山區高原里4鄰31號後方果園',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C1H840',
      stationName: '國三N238K',
      stationNameEN: 'Freeway No. 3 - RAIN –N238K',
      stationAltitude: 231,
      longitude: 120.70771,
      latitude: 23.841007,
      countyName: '南投縣',
      stationAddress: '國道3號北向238.2K',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C0A9F0',
      stationName: '內湖',
      stationNameEN: 'Neihu',
      stationAltitude: 35,
      longitude: 121.57545,
      latitude: 25.079422,
      countyName: '臺北市',
      stationAddress: '內湖區內湖路一段520號(內湖高工內)',
      startDate: '1997-12-01',
    },
    {
      stationID: 'C1T920',
      stationName: '中興',
      stationNameEN: 'Zhongxing',
      stationAltitude: 68,
      longitude: 121.49865,
      latitude: 23.769503,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉中興大橋橋頭旁',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0W140',
      stationName: '金沙',
      stationNameEN: 'Jinsha ',
      stationAltitude: 21,
      longitude: 118.408775,
      latitude: 24.488293,
      countyName: '金門縣',
      stationAddress: '金沙鎮環島北路四段465號(金門縣自來水廠榮湖淨水場)',
      startDate: '2016-10-05',
    },
    {
      stationID: 'C0F861',
      stationName: '梨山',
      stationNameEN: 'Lishan',
      stationAltitude: 2215,
      longitude: 121.24367,
      latitude: 24.247522,
      countyName: '臺中市',
      stationAddress: '和平區梨山里福壽路29號（福壽山農場）',
      startDate: '2004-07-01',
    },
    {
      stationID: 'C0X230',
      stationName: '鹽水',
      stationNameEN: 'Yanshui',
      stationAltitude: 29,
      longitude: 120.24764,
      latitude: 23.272621,
      countyName: '臺南市',
      stationAddress: '鹽水區竹埔里1號(竹埔派出所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0R700',
      stationName: '枋山',
      stationNameEN: 'Fangshan',
      stationAltitude: 19,
      longitude: 120.66109,
      latitude: 22.246843,
      countyName: '屏東縣',
      stationAddress: '枋山鄉中山路三段52號(加祿國小)',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0B020',
      stationName: '基隆嶼',
      stationNameEN: 'Keelung Islet',
      stationAltitude: 86,
      longitude: 121.78391,
      latitude: 25.19141,
      countyName: '基隆市',
      stationAddress: '中正區基隆嶼',
      startDate: '2018-10-26',
    },
    {
      stationID: 'C0C590',
      stationName: '觀音',
      stationNameEN: 'Guanyin',
      stationAltitude: 72,
      longitude: 121.15332,
      latitude: 25.027073,
      countyName: '桃園市',
      stationAddress: '觀音區廣福里10-42號(逸福農場)',
      startDate: '2007-10-01',
    },
    {
      stationID: 'C0F9Y0',
      stationName: '桃山',
      stationNameEN: 'Taoshan',
      stationAltitude: 3313,
      longitude: 121.30381,
      latitude: 24.43274,
      countyName: '臺中市',
      stationAddress: '和平區(位於往桃山山屋叉路解說牌附近)',
      startDate: '2016-07-15',
    },
    {
      stationID: 'C1S820',
      stationName: '金峰',
      stationNameEN: 'Jinfeng',
      stationAltitude: 177,
      longitude: 120.933235,
      latitude: 22.59097,
      countyName: '臺東縣',
      stationAddress: '金峰鄉嘉蘭村嘉蘭國小旁',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C1F891',
      stationName: '稍來',
      stationNameEN: 'Shaolai',
      stationAltitude: 2205,
      longitude: 121.001144,
      latitude: 24.26569,
      countyName: '臺中市',
      stationAddress: '和平區八仙山事業區49林班地船型山苗圃廢棄觀測坪附近',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0E540',
      stationName: '後龍',
      stationNameEN: 'Houlong',
      stationAltitude: 32,
      longitude: 120.783936,
      latitude: 24.60649,
      countyName: '苗栗縣',
      stationAddress: '後龍鎮後龍溪橋旁高地',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X260',
      stationName: '後壁',
      stationNameEN: 'Houbi',
      stationAltitude: 39,
      longitude: 120.36233,
      latitude: 23.366358,
      countyName: '臺南市',
      stationAddress: '後壁區後壁里129號(後壁圖書館)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0G720',
      stationName: '溪州',
      stationNameEN: 'Xizhou',
      stationAltitude: 50,
      longitude: 120.49909,
      latitude: 23.85159,
      countyName: '彰化縣',
      stationAddress: '溪州鄉溪下路四段560號(溪州鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0W170',
      stationName: '東引',
      stationNameEN: 'Dongyin',
      stationAltitude: 42,
      longitude: 120.48363,
      latitude: 26.358835,
      countyName: '連江縣',
      stationAddress: '東引鄉（東引機場候機室旁）',
      startDate: '2017-06-08',
    },
    {
      stationID: 'C1T940',
      stationName: '大觀',
      stationNameEN: 'Daguan',
      stationAltitude: 539,
      longitude: 121.37313,
      latitude: 23.714207,
      countyName: '花蓮縣',
      stationAddress: '萬榮鄉(往臺16線過管制哨約12k處)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C1S850',
      stationName: '豐南',
      stationNameEN: 'Funan',
      stationAltitude: 489,
      longitude: 121.26239,
      latitude: 23.122139,
      countyName: '花蓮縣',
      stationAddress: '富里鄉豐南村花82縣道盡頭叉路1k旁',
      startDate: '2012-01-01',
    },
    {
      stationID: 'C0Z200',
      stationName: '萬榮',
      stationNameEN: 'Wanrong',
      stationAltitude: 153,
      longitude: 121.420105,
      latitude: 23.709219,
      countyName: '花蓮縣',
      stationAddress: '鳳林鎮長橋里長橋路2號(長橋國小)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C0E780',
      stationName: '銅鑼',
      stationNameEN: 'Tongluo',
      stationAltitude: 160,
      longitude: 120.790855,
      latitude: 24.489681,
      countyName: '苗栗縣',
      stationAddress: '銅鑼鄉福興村文化街1號(銅鑼國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R710',
      stationName: '龍磐',
      stationNameEN: 'Longpan',
      stationAltitude: 117,
      longitude: 120.83894,
      latitude: 21.931671,
      countyName: '屏東縣',
      stationAddress: '屏東縣恆春助航台(NDB歸航台)',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0F9Z0',
      stationName: '雪山東峰',
      stationNameEN: 'Xueshandongfeng',
      stationAltitude: 3193,
      longitude: 121.26868,
      latitude: 24.388792,
      countyName: '臺中市',
      stationAddress: '和平區(位於雪山東峰直升機停機坪東南東方60公尺處)',
      startDate: '2016-07-15',
    },
    {
      stationID: 'C1H860',
      stationName: '瑞岩',
      stationNameEN: 'Ruiyan',
      stationAltitude: 1840,
      longitude: 121.18345,
      latitude: 24.123772,
      countyName: '南投縣',
      stationAddress: '仁愛鄉',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0T900',
      stationName: '西林',
      stationNameEN: 'Xilin',
      stationAltitude: 160,
      longitude: 121.44146,
      latitude: 23.8119,
      countyName: '花蓮縣',
      stationAddress: '萬榮鄉西林路8鄰114號(西林國民小學運動場旁)',
      startDate: '2010-07-01',
    },
    {
      stationID: 'C1Z240',
      stationName: '中平林道',
      stationNameEN: 'Zhongpinglindao',
      stationAltitude: 1163,
      longitude: 121.26763,
      latitude: 23.42115,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉中平林道(玉里林道)約15K鐵牌處步行上山高地',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0G730',
      stationName: '二林',
      stationNameEN: 'Erlin',
      stationAltitude: 27,
      longitude: 120.37608,
      latitude: 23.903324,
      countyName: '彰化縣',
      stationAddress: '二林鎮仁愛路200號(二林消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0W160',
      stationName: '烏坵',
      stationNameEN: 'Wuqiu',
      stationAltitude: 49,
      longitude: 119.44956,
      latitude: 24.9944,
      countyName: '金門縣',
      stationAddress: '烏坵鄉',
      startDate: '2016-10-05',
    },
    {
      stationID: 'C0E550',
      stationName: '明德',
      stationNameEN: 'Mingde',
      stationAltitude: 84,
      longitude: 120.885414,
      latitude: 24.583487,
      countyName: '苗栗縣',
      stationAddress: '頭屋鄉明德水庫水利工作站屋頂上',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X250',
      stationName: '新營',
      stationNameEN: 'Xinying',
      stationAltitude: 33,
      longitude: 120.316696,
      latitude: 23.310713,
      countyName: '臺南市',
      stationAddress: '新營區中正路30號(南瀛親子館)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0S810',
      stationName: '東河',
      stationNameEN: 'Donghe',
      stationAltitude: 65,
      longitude: 121.303986,
      latitude: 22.972672,
      countyName: '臺東縣',
      stationAddress: '東河鄉北東河17鄰26號(東河國民小學操場旁)',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0E790',
      stationName: '卓蘭',
      stationNameEN: 'Zhuolan',
      stationAltitude: 344,
      longitude: 120.82475,
      latitude: 24.312763,
      countyName: '苗栗縣',
      stationAddress: '卓蘭鎮中山路127號(卓蘭鎮公所)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R720',
      stationName: '旭海',
      stationNameEN: 'Xuhai',
      stationAltitude: 214,
      longitude: 120.88761,
      latitude: 22.205402,
      countyName: '屏東縣',
      stationAddress: '牡丹鄉旭海大草原步道旁',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0T9B0',
      stationName: '水源',
      stationNameEN: 'Shuiyuan',
      stationAltitude: 558,
      longitude: 121.53717,
      latitude: 23.991861,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉原林務局水源段的苗圃內',
      startDate: '2000-10-01',
    },
    {
      stationID: 'C0SA40',
      stationName: '瑞和',
      stationNameEN: 'Ruihe',
      stationAltitude: 200,
      longitude: 121.1567,
      latitude: 22.974361,
      countyName: '臺東縣',
      stationAddress: '鹿野鄉瑞和村瑞景路3段1號(瑞源國中)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1V340',
      stationName: '大津',
      stationNameEN: 'Dajin',
      stationAltitude: 190,
      longitude: 120.64599,
      latitude: 22.888323,
      countyName: '高雄市',
      stationAddress: '六龜區大津里34號(龍興國小大津分校)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0A640',
      stationName: '石碇',
      stationNameEN: 'Shihding',
      stationAltitude: 241,
      longitude: 121.66293,
      latitude: 24.993858,
      countyName: '新北市',
      stationAddress: '石碇區湳窟34號附近',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0M720',
      stationName: '番路',
      stationNameEN: 'Fanlu',
      stationAltitude: 225,
      longitude: 120.55415,
      latitude: 23.464657,
      countyName: '嘉義縣',
      stationAddress: '番路鄉下坑村蔡公店101號(番路鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0I360',
      stationName: '水里',
      stationNameEN: 'Shuili',
      stationAltitude: 300,
      longitude: 120.84979,
      latitude: 23.812553,
      countyName: '南投縣',
      stationAddress: '水里鄉中山路一段515號(玉山國家公園管理處)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0O900',
      stationName: '善化',
      stationNameEN: 'Shanhua',
      stationAltitude: 9,
      longitude: 120.29722,
      latitude: 23.112883,
      countyName: '臺南市',
      stationAddress: '善化區益民寮60號(亞洲蔬菜研究發展中心內)',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C1V580',
      stationName: '溪南(特生中心)',
      stationNameEN: 'Xinan',
      stationAltitude: 1656,
      longitude: 120.788475,
      latitude: 23.084972,
      countyName: '高雄市',
      stationAddress: '桃源區石山林道7k(特有生物研究保育中心中海拔試驗站)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0A880',
      stationName: '福隆',
      stationNameEN: 'Fulong',
      stationAltitude: 6,
      longitude: 121.942085,
      latitude: 25.017841,
      countyName: '新北市',
      stationAddress: '貢寮區東北角海岸國家風景管理處之福隆遊客中心後方',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0R170',
      stationName: '屏東',
      stationNameEN: 'Pingdong',
      stationAltitude: 26,
      longitude: 120.49407,
      latitude: 22.660269,
      countyName: '屏東縣',
      stationAddress: '屏東市復興路245號(台灣糖業公司屏東區處場區內)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0T9A0',
      stationName: '月眉山',
      stationNameEN: 'Yuemeishan',
      stationAltitude: 498,
      longitude: 121.539825,
      latitude: 23.809929,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉月眉山旁的小山丘上(過米棧大橋往山上水璉方向至四號橋左轉之山上)',
      startDate: '2000-10-01',
    },
    {
      stationID: 'C0V770',
      stationName: '大社',
      stationNameEN: 'Dashe',
      stationAltitude: 26,
      longitude: 120.34664,
      latitude: 22.730307,
      countyName: '高雄市',
      stationAddress: '大社區自強街1號(大社區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0M730',
      stationName: '嘉義市東區',
      stationNameEN: 'Dongqu Chiayi City',
      stationAltitude: 40,
      longitude: 120.46053,
      latitude: 23.457672,
      countyName: '嘉義市',
      stationAddress: '親水路123 號(第五河川局水情中心)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0K550',
      stationName: '蔦松',
      stationNameEN: 'Niaosong',
      stationAltitude: 15,
      longitude: 120.22974,
      latitude: 23.514318,
      countyName: '雲林縣',
      stationAddress: '水林鄉松中村蔦松路213號(蔦松國小)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0V530',
      stationName: '阿蓮',
      stationNameEN: 'Alian',
      stationAltitude: 34,
      longitude: 120.32751,
      latitude: 22.883207,
      countyName: '高雄市',
      stationAddress: '阿蓮區阿蓮里民生路94號(阿蓮區公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1V570',
      stationName: '吉東',
      stationNameEN: 'JiaDong',
      stationAltitude: 82,
      longitude: 120.5454,
      latitude: 22.85422,
      countyName: '高雄市',
      stationAddress: '美濃區吉東里吉頂路19號(吉東國小)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0A650',
      stationName: '火燒寮',
      stationNameEN: 'Huoshaoliao',
      stationAltitude: 287,
      longitude: 121.74289,
      latitude: 25.00272,
      countyName: '新北市',
      stationAddress: '平溪區東勢里',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0I370',
      stationName: '魚池',
      stationNameEN: 'Yuchi',
      stationAltitude: 671,
      longitude: 120.94135,
      latitude: 23.895702,
      countyName: '南投縣',
      stationAddress: '魚池鄉東池村魚池街194號(魚池鄉衛生所)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0A890',
      stationName: '雙溪',
      stationNameEN: 'Shuangsi',
      stationAltitude: 40,
      longitude: 121.86424,
      latitude: 25.036003,
      countyName: '新北市',
      stationAddress: '雙溪區雙溪里社區活動中心旁花圃內',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C1O940',
      stationName: '東山服務區',
      stationNameEN: 'Dongshan Service Area',
      stationAltitude: 55,
      longitude: 120.398476,
      latitude: 23.286016,
      countyName: '臺南市',
      stationAddress: '國道3號北向319.9K',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C1F911',
      stationName: '新伯公',
      stationNameEN: 'Xinbogong',
      stationAltitude: 417,
      longitude: 120.84107,
      latitude: 24.221361,
      countyName: '臺中市',
      stationAddress: '東勢區詒福段163地號(台電公司門前右前方)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0K560',
      stationName: '棋山',
      stationNameEN: 'Qishan',
      stationAltitude: 117,
      longitude: 120.60342,
      latitude: 23.68645,
      countyName: '雲林縣',
      stationAddress: '古坑鄉棋盤村棋山49號(棋山國小)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0SA60',
      stationName: '知本（水試所）',
      stationNameEN: 'Zhiben (FRI)',
      stationAltitude: 11,
      longitude: 121.08276,
      latitude: 22.702394,
      countyName: '臺東縣',
      stationAddress: '臺東縣臺東市知本路2段291巷299號',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0A660',
      stationName: '瑞芳',
      stationNameEN: 'Rueifang',
      stationAltitude: 97,
      longitude: 121.80115,
      latitude: 25.113169,
      countyName: '新北市',
      stationAddress: '瑞芳區22403龍山里逢甲路360號(義方國民小學校園內)',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0M740',
      stationName: '六腳',
      stationNameEN: 'Liujiao',
      stationAltitude: 15,
      longitude: 120.29063,
      latitude: 23.49287,
      countyName: '嘉義縣',
      stationAddress: '六腳鄉蒜頭村73-2號(六腳消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0I380',
      stationName: '集集',
      stationNameEN: 'Jiji',
      stationAltitude: 258,
      longitude: 120.80147,
      latitude: 23.828205,
      countyName: '南投縣',
      stationAddress: '集集鎮民生東路1號(農委會特有生物研究保育中心)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0UA10',
      stationName: '鴛鴦湖',
      stationNameEN: 'Yuanyanghu',
      stationAltitude: 1711,
      longitude: 121.41223,
      latitude: 24.59027,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉100線林道約15K(太平山事業區32林班內，鴛鴦湖工作站旁空地)',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0R190',
      stationName: '赤山',
      stationNameEN: 'Chishan',
      stationAltitude: 32,
      longitude: 120.6143,
      latitude: 22.592333,
      countyName: '屏東縣',
      stationAddress: '萬巒鄉建興路40號（赤山國小）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0T9E0',
      stationName: '大坑',
      stationNameEN: 'Dakeng',
      stationAltitude: 415,
      longitude: 121.58198,
      latitude: 23.880629,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉10鄰山嶺80之1號(中華電信公司賀田山機房)旁',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0V790',
      stationName: '萬山',
      stationNameEN: 'Wanshan',
      stationAltitude: 410,
      longitude: 120.68806,
      latitude: 22.905945,
      countyName: '高雄市',
      stationAddress: '茂林區萬山段710地號',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0K330',
      stationName: '虎尾',
      stationNameEN: 'Huwei',
      stationAltitude: 38,
      longitude: 120.44204,
      latitude: 23.719183,
      countyName: '雲林縣',
      stationAddress: '虎尾鎮林森路一段64號(中正國民小學內)',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C0O930',
      stationName: '玉井',
      stationNameEN: 'Yujing',
      stationAltitude: 69,
      longitude: 120.46055,
      latitude: 23.126041,
      countyName: '臺南市',
      stationAddress: '玉井區台灣糖業公司玉井糖廠',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0V310',
      stationName: '美濃',
      stationNameEN: 'Meinong',
      stationAltitude: 46,
      longitude: 120.51915,
      latitude: 22.898743,
      countyName: '高雄市',
      stationAddress: '美濃區福安里中山路二段204巷65號(美濃區福安國小後方)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C1V590',
      stationName: '新發',
      stationNameEN: 'Xinfa',
      stationAltitude: 741,
      longitude: 120.646065,
      latitude: 23.057018,
      countyName: '高雄市',
      stationAddress: '六龜區旗山事業區61林班地',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0M750',
      stationName: '布袋',
      stationNameEN: 'Budai',
      stationAltitude: 10,
      longitude: 120.167786,
      latitude: 23.380878,
      countyName: '嘉義縣',
      stationAddress: '布袋鎮龍江里2-2號(布袋消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0I390',
      stationName: '仁愛',
      stationNameEN: "Ren'ai",
      stationAltitude: 1184,
      longitude: 121.13221,
      latitude: 24.022121,
      countyName: '南投縣',
      stationAddress: '仁愛鄉仁和路50號(台電公司仁愛服務所)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0UA00',
      stationName: '土場',
      stationNameEN: 'Tuchang',
      stationAltitude: 392,
      longitude: 121.49533,
      latitude: 24.576532,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉土場',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0T9D0',
      stationName: '和中',
      stationNameEN: 'Hezhong',
      stationAltitude: 8,
      longitude: 121.74103,
      latitude: 24.26676,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉秀林村62號(和平國小和中分校校園內)',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0C620',
      stationName: '蘆竹',
      stationNameEN: 'Luzhu',
      stationAltitude: 19,
      longitude: 121.26577,
      latitude: 25.084274,
      countyName: '桃園市',
      stationAddress: '蘆竹區坑口里14鄰旁',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C1E601',
      stationName: '南勢',
      stationNameEN: 'Nanshi',
      stationAltitude: 125,
      longitude: 120.73484,
      latitude: 24.572487,
      countyName: '苗栗縣',
      stationAddress: '後龍鎮南港里南勢山',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0SA80',
      stationName: '土坂',
      stationNameEN: 'Tuban',
      stationAltitude: 136,
      longitude: 120.88749,
      latitude: 22.452923,
      countyName: '臺東縣',
      stationAddress: '達仁鄉土坂村1鄰10號(土坂國民小學操場旁)',
      startDate: '2019-06-07',
    },
    {
      stationID: 'C0M760',
      stationName: '民雄',
      stationNameEN: 'Minxiong',
      stationAltitude: 40,
      longitude: 120.42846,
      latitude: 23.551754,
      countyName: '嘉義縣',
      stationAddress: '民雄鄉文化路7號(民雄鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0M520',
      stationName: '東後寮',
      stationNameEN: 'Donghouliao',
      stationAltitude: 15,
      longitude: 120.24806,
      latitude: 23.369907,
      countyName: '嘉義縣',
      stationAddress: '義竹鄉東光村7號(台糖東後寮農場)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0K580',
      stationName: '高鐵雲林',
      stationNameEN: 'THSR Yunlin',
      stationAltitude: 26,
      longitude: 120.417946,
      latitude: 23.737455,
      countyName: '雲林縣',
      stationAddress: '虎尾鎮站前東路301號(高鐵雲林站外)',
      startDate: '2017-08-01',
    },
    {
      stationID: 'C0UA30',
      stationName: '白嶺',
      stationNameEN: 'Bailing',
      stationAltitude: 1397,
      longitude: 121.51903,
      latitude: 24.527819,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉太平山白嶺苗圃內',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C1F941',
      stationName: '雪嶺',
      stationNameEN: 'Xueling',
      stationAltitude: 2620,
      longitude: 121.02664,
      latitude: 24.280586,
      countyName: '臺中市',
      stationAddress: '和平區雪嶺地區八仙山事業區60林班地小雪山莊後方水塔後山頂',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0T9G0',
      stationName: '鳳林山',
      stationNameEN: 'Fenglinshan',
      stationAltitude: 605,
      longitude: 121.42013,
      latitude: 23.73527,
      countyName: '花蓮縣',
      stationAddress: '鳳林鎮',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0AG90',
      stationName: '中和',
      stationNameEN: 'Zhonghe',
      stationAltitude: 25,
      longitude: 121.49044,
      latitude: 24.992647,
      countyName: '新北市',
      stationAddress: '中和區錦和路350號(錦和運動公園)',
      startDate: '2011-08-23',
    },
    {
      stationID: 'C0C630',
      stationName: '大溪',
      stationNameEN: 'Dasi',
      stationAltitude: 209,
      longitude: 121.26555,
      latitude: 24.882853,
      countyName: '桃園市',
      stationAddress: '大溪區員林路2段450號(員樹林國小校園內)',
      startDate: '2007-12-01',
    },
    {
      stationID: 'C0E810',
      stationName: '西湖',
      stationNameEN: 'Xihu',
      stationAltitude: 53,
      longitude: 120.7485,
      latitude: 24.562946,
      countyName: '苗栗縣',
      stationAddress: '西湖鄉二湖村2鄰22號(僑文國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0M770',
      stationName: '嘉義梅山',
      stationNameEN: 'Meishan Chiayi County',
      stationAltitude: 164,
      longitude: 120.55559,
      latitude: 23.585398,
      countyName: '嘉義縣',
      stationAddress: '梅山鄉中山路282號(梅山鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0SA90',
      stationName: '達仁林場',
      stationNameEN: 'Darenlinchang',
      stationAltitude: 497,
      longitude: 120.823975,
      latitude: 22.264347,
      countyName: '臺東縣',
      stationAddress: '臺東縣達仁鄉臺東達五號農路4K處',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0M530',
      stationName: '奮起湖',
      stationNameEN: 'Fenqihu',
      stationAltitude: 1385,
      longitude: 120.69925,
      latitude: 23.493902,
      countyName: '嘉義縣',
      stationAddress: '竹崎鄉阿里山事業區148林班地(中興苗圃內)',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C1AC50',
      stationName: '關渡',
      stationNameEN: 'Guandu',
      stationAltitude: 111,
      longitude: 121.46933,
      latitude: 25.133486,
      countyName: '臺北市',
      stationAddress: '北投區學園路2號(臺北城市科技大學校園內5樓頂)',
      startDate: '1992-05-20',
    },
    {
      stationID: 'C0O950',
      stationName: '安南',
      stationNameEN: 'Annan',
      stationAltitude: 4,
      longitude: 120.14486,
      latitude: 23.076694,
      countyName: '臺南市',
      stationAddress: '安南區公學路6段589號(學東國民小學內)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0UA20',
      stationName: '多加屯',
      stationNameEN: 'Duojiatun',
      stationAltitude: 2619,
      longitude: 121.37319,
      latitude: 24.368614,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉南湖大山指標8.4K處(多加屯避難山屋附近)',
      startDate: '2017-12-28',
    },
    {
      stationID: 'C0T9F0',
      stationName: '水璉',
      stationNameEN: 'Shuilian',
      stationAltitude: 251,
      longitude: 121.54251,
      latitude: 23.79713,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉水璉村',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0V360',
      stationName: '內門',
      stationNameEN: 'Neimen',
      stationAltitude: 94,
      longitude: 120.46677,
      latitude: 22.975481,
      countyName: '高雄市',
      stationAddress: '內門區木柵里木柵3號（木柵國民小學）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0E820',
      stationName: '獅潭',
      stationNameEN: 'Shitan',
      stationAltitude: 220,
      longitude: 120.920044,
      latitude: 24.539133,
      countyName: '苗栗縣',
      stationAddress: '獅潭鄉新店村11鄰103-2號(獅潭鄉公所圖書館樓頂)',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C0X300',
      stationName: '鹿寮',
      stationNameEN: 'Luliao',
      stationAltitude: 70,
      longitude: 120.47733,
      latitude: 23.386047,
      countyName: '臺南市',
      stationAddress: '白河區崎內里12號(鹿寮水庫)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0O960',
      stationName: '崎頂',
      stationNameEN: 'Qiding',
      stationAltitude: 112,
      longitude: 120.369316,
      latitude: 22.959547,
      countyName: '臺南市',
      stationAddress: '龍崎區中坑里5鄰13號',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1V160',
      stationName: '達卡努瓦',
      stationNameEN: 'Dakanuwa',
      stationAltitude: 1040,
      longitude: 120.70532,
      latitude: 23.279812,
      countyName: '高雄市',
      stationAddress: '那瑪夏區達卡努瓦里',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0M780',
      stationName: '鹿草',
      stationNameEN: 'Lucao',
      stationAltitude: 30,
      longitude: 120.30835,
      latitude: 23.41134,
      countyName: '嘉義縣',
      stationAddress: '鹿草鄉西井村287號(鹿草鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0UA50',
      stationName: '西帽山',
      stationNameEN: 'Ximaoshan',
      stationAltitude: 808,
      longitude: 121.80263,
      latitude: 24.55536,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉台泥大白山運石道附近山區(羅東事業區96林班內)',
      startDate: '2018-05-09',
    },
    {
      stationID: 'C1H900',
      stationName: '清流',
      stationNameEN: 'Qingliu',
      stationAltitude: 934,
      longitude: 120.963,
      latitude: 24.080774,
      countyName: '南投縣',
      stationAddress: '仁愛鄉互助村',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0T9I0',
      stationName: '豐濱',
      stationNameEN: 'Fengbin',
      stationAltitude: 152,
      longitude: 121.51481,
      latitude: 23.583897,
      countyName: '花蓮縣',
      stationAddress: '豐濱鄉豐濱村',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0C650',
      stationName: '平鎮',
      stationNameEN: 'Pingjhen',
      stationAltitude: 208,
      longitude: 121.21464,
      latitude: 24.897503,
      countyName: '桃園市',
      stationAddress: '平鎮區中豐路山頂段375巷45號(山豐國民小學校園內)',
      startDate: '2007-12-01',
    },
    {
      stationID: 'C0E830',
      stationName: '苑裡',
      stationNameEN: 'YUANLI',
      stationAltitude: 37,
      longitude: 120.65305,
      latitude: 24.439714,
      countyName: '苗栗縣',
      stationAddress: '苑裡鎮信義路1號(苑裡鎮公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0V350',
      stationName: '溪埔',
      stationNameEN: 'XIPU',
      stationAltitude: 36,
      longitude: 120.4468,
      latitude: 22.738579,
      countyName: '高雄市',
      stationAddress: '大樹區溪埔里之台29線近83K處',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0O970',
      stationName: '虎頭埤',
      stationNameEN: 'Hutoupi',
      stationAltitude: 71,
      longitude: 120.34788,
      latitude: 23.021376,
      countyName: '臺南市',
      stationAddress: '新化區口埤18號(新化分場)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1V390',
      stationName: '尖山',
      stationNameEN: 'Jianshan',
      stationAltitude: 60,
      longitude: 120.36779,
      latitude: 22.813152,
      countyName: '高雄市',
      stationAddress: '燕巢區尖山里尖山巷11之1號(燕巢國小尖山分校)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0M790',
      stationName: '新港',
      stationNameEN: 'Xingang',
      stationAltitude: 15,
      longitude: 120.345726,
      latitude: 23.553268,
      countyName: '嘉義縣',
      stationAddress: '新港鄉公園路28號(新港消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0UA40',
      stationName: '西德山',
      stationNameEN: 'Xideshan',
      stationAltitude: 1121,
      longitude: 121.75261,
      latitude: 24.560646,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉安平坑林道(力霸產業道路)附近山區(南澳事業區28林班內)',
      startDate: '2018-05-09',
    },
    {
      stationID: 'C0T9H0',
      stationName: '加路蘭山',
      stationNameEN: 'Jialulanshan',
      stationAltitude: 725,
      longitude: 121.5274,
      latitude: 23.682947,
      countyName: '花蓮縣',
      stationAddress: '豐濱鄉臨海段776-4號',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0AC40',
      stationName: '大屯山',
      stationNameEN: 'Datunshan',
      stationAltitude: 1079,
      longitude: 121.52241,
      latitude: 25.175674,
      countyName: '臺北市',
      stationAddress: '北投區湖田段461-6地號(民航局大屯山助航臺)',
      startDate: '2006-01-01',
    },
    {
      stationID: 'C0AI10',
      stationName: '屈尺',
      stationNameEN: 'Quchi',
      stationAltitude: 76,
      longitude: 121.54475,
      latitude: 24.92184,
      countyName: '新北市',
      stationAddress: '新店區屈尺路83號(衛福部北區老人之家)',
      startDate: '2018-11-20',
    },
    {
      stationID: 'C0I520',
      stationName: '玉山風口',
      stationNameEN: 'Yushanfengkou',
      stationAltitude: 3860,
      longitude: 120.95599,
      latitude: 23.47049,
      countyName: '南投縣',
      stationAddress: '信義鄉(玉山主峰往北峰叉路空地附近)',
      startDate: '2016-07-15',
    },
    {
      stationID: 'C0V700',
      stationName: '三民',
      stationNameEN: 'Sanmin',
      stationAltitude: 24,
      longitude: 120.31123,
      latitude: 22.645018,
      countyName: '高雄市',
      stationAddress: '三民區十全一路88號(十全消防分隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0S660',
      stationName: '下馬',
      stationNameEN: 'Xiama',
      stationAltitude: 794,
      longitude: 121.07031,
      latitude: 23.150427,
      countyName: '臺東縣',
      stationAddress: '海端鄉廢棄下馬派出所旁',
      startDate: '2019-06-07',
    },
    {
      stationID: 'C1AI60',
      stationName: '國一N039K',
      stationNameEN: 'Freeway No. 1 - RAIN – N039K',
      stationAltitude: 181,
      longitude: 121.383835,
      latitude: 25.064362,
      countyName: '新北市',
      stationAddress: '國道1號北向39.32K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C0R570',
      stationName: '麟洛',
      stationNameEN: 'Linluo',
      stationAltitude: 37,
      longitude: 120.52719,
      latitude: 22.65078,
      countyName: '屏東縣',
      stationAddress: '麟洛鄉中山路158號(麟洛鄉公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1I330',
      stationName: '楓樹林',
      stationNameEN: 'Fengshulin',
      stationAltitude: 1266,
      longitude: 121.07612,
      latitude: 23.986803,
      countyName: '南投縣',
      stationAddress: '仁愛鄉埔里事業區107林班地(臺14線近埔里市區)',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0Z070',
      stationName: '舞鶴',
      stationNameEN: 'Wuhe',
      stationAltitude: 247,
      longitude: 121.37407,
      latitude: 23.46811,
      countyName: '花蓮縣',
      stationAddress: '瑞穗鄉舞鶴村加納納山三角點旁',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C1H000',
      stationName: '翠峰',
      stationNameEN: 'Cuifeng',
      stationAltitude: 2454,
      longitude: 121.20521,
      latitude: 24.109398,
      countyName: '南投縣',
      stationAddress: '仁愛鄉翠峰段30地號東側旁(臺14甲線19-19.5k處的長途通信機房旁)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0AI20',
      stationName: '白沙灣',
      stationNameEN: 'Baishawan',
      stationAltitude: 25,
      longitude: 121.51906,
      latitude: 25.283129,
      countyName: '新北市',
      stationAddress: '石門區德茂里下員坑33-6號（白沙灣遊客中心樓頂）',
      startDate: '2018-11-28',
    },
    {
      stationID: 'C1G9D0',
      stationName: '國一S218K',
      stationNameEN: 'Freeway No. 1 - RAIN – S218K',
      stationAltitude: 30,
      longitude: 120.493256,
      latitude: 23.89064,
      countyName: '彰化縣',
      stationAddress: '國道1號南向218.3K',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C1U880',
      stationName: '北關',
      stationNameEN: 'Beiguan',
      stationAltitude: 8,
      longitude: 121.87188,
      latitude: 24.90651,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮濱海路三段(北關梗枋漁港北側路邊)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0I530',
      stationName: '小奇萊',
      stationNameEN: 'Xiaoqilai',
      stationAltitude: 3014,
      longitude: 121.295715,
      latitude: 24.131763,
      countyName: '南投縣',
      stationAddress: '仁愛鄉小奇萊步道指標1.5K處附近',
      startDate: '2017-12-28',
    },
    {
      stationID: 'C0U600',
      stationName: '礁溪',
      stationNameEN: 'Chiaoshi',
      stationAltitude: 10,
      longitude: 121.76553,
      latitude: 24.817545,
      countyName: '宜蘭縣',
      stationAddress: '礁溪鄉礁溪路四段23號(礁溪國民中學教室後宿舍旁)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0R100',
      stationName: '尾寮山',
      stationNameEN: 'Weiliaoshan',
      stationAltitude: 1006,
      longitude: 120.68389,
      latitude: 22.83311,
      countyName: '屏東縣',
      stationAddress: '三地門鄉口社檢查站車程約2小時(尾寮山瞭望台旁)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0R341',
      stationName: '牡丹',
      stationNameEN: 'Mudan',
      stationAltitude: 230,
      longitude: 120.79264,
      latitude: 22.130035,
      countyName: '屏東縣',
      stationAddress: '199縣道23K-23.5K間車城往旭海方向之右側',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C1AI50',
      stationName: '國三N016K',
      stationNameEN: 'Freeway No. 3 - RAIN - N016K',
      stationAltitude: 64,
      longitude: 121.61967,
      latitude: 25.034164,
      countyName: '臺北市',
      stationAddress: '國道3號北向16.51K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C0R580',
      stationName: '南州',
      stationNameEN: 'Nanzhou',
      stationAltitude: 10,
      longitude: 120.50274,
      latitude: 22.48591,
      countyName: '屏東縣',
      stationAddress: '南州鄉溪南村人和路241號(南州消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0Z061',
      stationName: '玉里',
      stationNameEN: 'Yuli',
      stationAltitude: 174,
      longitude: 121.33965,
      latitude: 23.3215,
      countyName: '花蓮縣',
      stationAddress: '玉里鎮樂合里新民41號(樂合國小)',
      startDate: '2008-02-20',
    },
    {
      stationID: 'C1I320',
      stationName: '奧萬大',
      stationNameEN: 'Aowanda',
      stationAltitude: 1275,
      longitude: 121.17856,
      latitude: 23.952955,
      countyName: '南投縣',
      stationAddress: '仁愛鄉奧萬大森林遊樂區內(員工宿舍)',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0AI30',
      stationName: '三重',
      stationNameEN: 'Sanchong',
      stationAltitude: 26,
      longitude: 121.500626,
      latitude: 25.057327,
      countyName: '新北市',
      stationAddress: '三重區環河南路100號',
      startDate: '2019-08-02',
    },
    {
      stationID: 'C1U670',
      stationName: '寒溪',
      stationNameEN: 'Hanxi',
      stationAltitude: 105,
      longitude: 121.71691,
      latitude: 24.634,
      countyName: '宜蘭縣',
      stationAddress: '冬山鄉大進村大進七路51號(大進國民小學校園內)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0U870',
      stationName: '大礁溪',
      stationNameEN: 'Dajiaoxi',
      stationAltitude: 474,
      longitude: 121.67505,
      latitude: 24.79104,
      countyName: '宜蘭縣',
      stationAddress: '礁溪鄉二結村一鄰9-1號(國立宜蘭大學實驗林場)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0V720',
      stationName: '林園',
      stationNameEN: 'Linyuan',
      stationAltitude: 15,
      longitude: 120.39451,
      latitude: 22.507748,
      countyName: '高雄市',
      stationAddress: '林園區林園北路179號(林園區公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0I540',
      stationName: '奇萊稜線',
      stationNameEN: 'Qilailengxian',
      stationAltitude: 3344,
      longitude: 121.326454,
      latitude: 24.109232,
      countyName: '南投縣',
      stationAddress: '仁愛鄉奇萊連峰指標6.4K處(奇萊山屋附近)',
      startDate: '2017-12-28',
    },
    {
      stationID: 'C0R350',
      stationName: '貓鼻頭',
      stationNameEN: 'Maobitou',
      stationAltitude: 35,
      longitude: 120.7358,
      latitude: 21.921774,
      countyName: '屏東縣',
      stationAddress: '恆春鎮(貓鼻頭風景區內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R590',
      stationName: '里港',
      stationNameEN: 'Ligang',
      stationAltitude: 72,
      longitude: 120.49459,
      latitude: 22.77919,
      countyName: '屏東縣',
      stationAddress: '里港鄉永春村中山路104號(里港鄉公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0AI40',
      stationName: '石牌',
      stationNameEN: 'Shipai',
      stationAltitude: 35,
      longitude: 121.51317,
      latitude: 25.1156,
      countyName: '臺北市',
      stationAddress: '北投區石牌路一段139號(石牌國中自強樓頂樓)',
      startDate: '2019-09-10',
    },
    {
      stationID: 'C0U860',
      stationName: '頭城',
      stationNameEN: 'Toucheng',
      stationAltitude: 5,
      longitude: 121.8308,
      latitude: 24.85323,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮協天路468號(中華電信頭城海纜站內)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0V710',
      stationName: '苓雅',
      stationNameEN: 'LingYa',
      stationAltitude: 34,
      longitude: 120.330666,
      latitude: 22.622742,
      countyName: '高雄市',
      stationAddress: '苓雅區三多一路162號(苓雅消防分隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0S690',
      stationName: '太麻里',
      stationNameEN: 'Taimali',
      stationAltitude: 522,
      longitude: 120.98533,
      latitude: 22.60902,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉(往金針山茶區之半路上)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0Z080',
      stationName: '富源',
      stationNameEN: 'Fuyuan',
      stationAltitude: 298,
      longitude: 121.37823,
      latitude: 23.587643,
      countyName: '花蓮縣',
      stationAddress: '光復鄉大豐村9鄰265號(富源國中對面之半山腰上)',
      startDate: '2012-01-01',
    },
    {
      stationID: 'C1I340',
      stationName: '新興橋',
      stationNameEN: 'Xinxingqiao',
      stationAltitude: 897,
      longitude: 120.86873,
      latitude: 23.563334,
      countyName: '南投縣',
      stationAddress: '信義鄉臺21線新興橋附近',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1I101',
      stationName: '溪頭',
      stationNameEN: 'Xitou',
      stationAltitude: 1810,
      longitude: 120.80765,
      latitude: 23.661789,
      countyName: '南投縣',
      stationAddress: '鹿谷鄉內湖村森林巷溪頭林營區(鳳凰山瞭望台旁)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0SA00',
      stationName: '歷坵',
      stationNameEN: 'Liqiu',
      stationAltitude: 466,
      longitude: 120.89185,
      latitude: 22.52097,
      countyName: '臺東縣',
      stationAddress: '金峰鄉歷坵產業道路附近山區',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1V300',
      stationName: '御油山',
      stationNameEN: 'Yuyoushan',
      stationAltitude: 1637,
      longitude: 120.71536,
      latitude: 23.001984,
      countyName: '高雄市',
      stationAddress: '桃源區農業委員會林業試驗所六龜分所鳳崗山苗圃觀測坪內',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0U890',
      stationName: '三星',
      stationNameEN: 'Sanxing',
      stationAltitude: 116,
      longitude: 121.65302,
      latitude: 24.66812,
      countyName: '宜蘭縣',
      stationAddress: '三星鄉月眉街54號(三星國中)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1U690',
      stationName: '新寮',
      stationNameEN: 'Xinliao',
      stationAltitude: 101,
      longitude: 121.75127,
      latitude: 24.62556,
      countyName: '宜蘭縣',
      stationAddress: '冬山鄉中山瀑布附近',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C1V780',
      stationName: '多納林道',
      stationNameEN: 'Duonalindao',
      stationAltitude: 1050,
      longitude: 120.737915,
      latitude: 22.888027,
      countyName: '高雄市',
      stationAddress: '茂林區多納林道9k(林試所六龜研究中心多納苗圃)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0U650',
      stationName: '玉蘭',
      stationNameEN: 'Yulan',
      stationAltitude: 442,
      longitude: 121.58706,
      latitude: 24.675285,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉松羅村鹿場路11號(往松羅山頂瞭望亭旁)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0V740',
      stationName: '旗山',
      stationNameEN: 'Qishan',
      stationAltitude: 60,
      longitude: 120.4836,
      latitude: 22.888615,
      countyName: '高雄市',
      stationAddress: '旗山區延平一路499號(旗山區公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0K500',
      stationName: '元長',
      stationNameEN: 'Yuanchang',
      stationAltitude: 12,
      longitude: 120.315025,
      latitude: 23.64981,
      countyName: '雲林縣',
      stationAddress: '元長鄉長南村仁愛街2號(元長鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0V500',
      stationName: '旗津',
      stationNameEN: 'Cijin',
      stationAltitude: 6,
      longitude: 120.28594,
      latitude: 22.5886,
      countyName: '高雄市',
      stationAddress: '旗津區中洲二路207號(旗津國中校園內)',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0R130',
      stationName: '阿禮',
      stationNameEN: 'Ali',
      stationAltitude: 1040,
      longitude: 120.743675,
      latitude: 22.742908,
      countyName: '屏東縣',
      stationAddress: '霧台鄉(屏東事業區第46林班)由山地文化村開車約2小時需徒步轉波站前面',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1SA50',
      stationName: '利嘉林道',
      stationNameEN: 'Lijialindao',
      stationAltitude: 1235,
      longitude: 121.00939,
      latitude: 22.82325,
      countyName: '臺東縣',
      stationAddress: '臺東縣卑南鄉利嘉林道17K處',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1I131',
      stationName: '桶頭',
      stationNameEN: 'Tongtou',
      stationAltitude: 311,
      longitude: 120.65411,
      latitude: 23.641945,
      countyName: '南投縣',
      stationAddress: '竹山鎮鯉南路338號旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0SA10',
      stationName: '檳榔四格山',
      stationNameEN: 'Binlangsigeshan',
      stationAltitude: 411,
      longitude: 121.10931,
      latitude: 22.810032,
      countyName: '臺東縣',
      stationAddress: '卑南鄉檳榔四格山山頂瞭望臺旁',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1A630',
      stationName: '下盆',
      stationNameEN: 'Siapen',
      stationAltitude: 527,
      longitude: 121.53862,
      latitude: 24.771004,
      countyName: '新北市',
      stationAddress: '烏來區東勢溪中游(步行一小時)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0T790',
      stationName: '大禹嶺',
      stationNameEN: 'Dayuling',
      stationAltitude: 2830,
      longitude: 121.316086,
      latitude: 24.186117,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉立霧溪事業區67林班地(救國團大禹山莊)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0V730',
      stationName: '大寮',
      stationNameEN: 'Daliao',
      stationAltitude: 33,
      longitude: 120.39571,
      latitude: 22.605614,
      countyName: '高雄市',
      stationAddress: '大寮區鳳林三路492號(大寮區公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0K510',
      stationName: '水林',
      stationNameEN: 'Shuilin',
      stationAltitude: 10,
      longitude: 120.24597,
      latitude: 23.572924,
      countyName: '雲林縣',
      stationAddress: '水林鄉水北村水林路12號(水林鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0R140',
      stationName: '瑪家',
      stationNameEN: 'Majia',
      stationAltitude: 740,
      longitude: 120.68664,
      latitude: 22.682903,
      countyName: '屏東縣',
      stationAddress: '瑪家鄉瑪家村4鄰24號(後山)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1I121',
      stationName: '大鞍',
      stationNameEN: 'Da-an',
      stationAltitude: 1515,
      longitude: 120.76002,
      latitude: 23.678381,
      countyName: '南投縣',
      stationAddress: '竹山鎮樟空倫山之三角點附近',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1K540',
      stationName: '口湖',
      stationNameEN: 'Kouhu',
      stationAltitude: 15,
      longitude: 120.18052,
      latitude: 23.585344,
      countyName: '雲林縣',
      stationAddress: '口湖鄉口湖村中正路一段280號(口湖消防分隊)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0SA20',
      stationName: '金崙山',
      stationNameEN: 'Jinlunshan',
      stationAltitude: 857,
      longitude: 120.95966,
      latitude: 22.56742,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉富山產業道路附近山區(與金峰鄉交界附近)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0A860',
      stationName: '大坪',
      stationNameEN: 'Daping',
      stationAltitude: 362,
      longitude: 121.63297,
      latitude: 25.165915,
      countyName: '新北市',
      stationAddress: '萬里區溪底里香員林6-3號',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0V760',
      stationName: '橋頭',
      stationNameEN: 'Qiaotou',
      stationAltitude: 30,
      longitude: 120.30575,
      latitude: 22.757807,
      countyName: '高雄市',
      stationAddress: '橋頭區隆豐路1號(橋頭區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0M700',
      stationName: '竹崎',
      stationNameEN: 'Zhuqi',
      stationAltitude: 150,
      longitude: 120.55551,
      latitude: 23.526178,
      countyName: '嘉義縣',
      stationAddress: '竹崎鄉竹崎村舊車站79號(竹崎消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0K520',
      stationName: '雲林東勢',
      stationNameEN: 'Dongshi Yunlin County',
      stationAltitude: 10,
      longitude: 120.15478,
      latitude: 23.67445,
      countyName: '雲林縣',
      stationAddress: '東勢鄉東南村所前街3號(東勢鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0R150',
      stationName: '三地門',
      stationNameEN: 'Sandimen',
      stationAltitude: 105,
      longitude: 120.639946,
      latitude: 22.709888,
      countyName: '屏東縣',
      stationAddress: '內埔鄉水門村成功路83號(國立內埔高級農工職業學校內)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C1I150',
      stationName: '青雲',
      stationNameEN: 'Qingyun',
      stationAltitude: 393,
      longitude: 120.94938,
      latitude: 23.793362,
      countyName: '南投縣',
      stationAddress: '信義鄉地利村5鄰開信巷98-3號旁',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C1SA70',
      stationName: '都蘭',
      stationNameEN: 'Dulan',
      stationAltitude: 62,
      longitude: 121.22688,
      latitude: 22.87794,
      countyName: '臺東縣',
      stationAddress: '東河鄉都蘭村431號(都蘭國民小學教室頂樓)',
      startDate: '2019-06-07',
    },
    {
      stationID: 'C0SA30',
      stationName: '都歷',
      stationNameEN: 'Duli',
      stationAltitude: 93,
      longitude: 121.32364,
      latitude: 23.020332,
      countyName: '臺東縣',
      stationAddress: '成功鎮新村路25號(東部海岸國家風景區管理處)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0V750',
      stationName: '路竹',
      stationNameEN: 'Luzhu',
      stationAltitude: 50,
      longitude: 120.25944,
      latitude: 22.854998,
      countyName: '高雄市',
      stationAddress: '路竹區國昌路76號(路竹區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0A870',
      stationName: '五指山',
      stationNameEN: 'Wujhihshan',
      stationAltitude: 685,
      longitude: 121.60869,
      latitude: 25.132153,
      countyName: '新北市',
      stationAddress: '汐止區國軍示範公墓旁之復興廣播電台門口外',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0M710',
      stationName: '東石',
      stationNameEN: 'Dongshi',
      stationAltitude: 15,
      longitude: 120.15385,
      latitude: 23.458927,
      countyName: '嘉義縣',
      stationAddress: '東石鄉東石村3號(東石鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0I110',
      stationName: '竹山',
      stationNameEN: 'Zhushan',
      stationAltitude: 161,
      longitude: 120.688156,
      latitude: 23.761225,
      countyName: '南投縣',
      stationAddress: '竹山鎮延和里向學街32號(竹山國民小學內)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0K530',
      stationName: '臺西',
      stationNameEN: 'Taixi',
      stationAltitude: 12,
      longitude: 120.197556,
      latitude: 23.701548,
      countyName: '雲林縣',
      stationAddress: '台西鄉中山路110巷38號(臺西消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0R160',
      stationName: '鹽埔',
      stationNameEN: 'yanpuxinwei',
      stationAltitude: 45,
      longitude: 120.53078,
      latitude: 22.739557,
      countyName: '屏東縣',
      stationAddress: '屏東縣鹽埔鄉七份路21號(彭厝國小)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1I140',
      stationName: '卡奈托灣',
      stationNameEN: 'Kanaituowan',
      stationAltitude: 1700,
      longitude: 121.087875,
      latitude: 23.754364,
      countyName: '南投縣',
      stationAddress: '信義鄉丹大事業區第16林班地',
      startDate: '1993-03-01',
    },
    {
      stationID: 'C1O921',
      stationName: '楠西',
      stationNameEN: 'Nanxi',
      stationAltitude: 115,
      longitude: 120.484085,
      latitude: 23.183512,
      countyName: '臺南市',
      stationAddress: '楠西區中興路107號(楠西國民中學內)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0G620',
      stationName: '芬園',
      stationNameEN: 'Fenyuan',
      stationAltitude: 110,
      longitude: 120.6213,
      latitude: 24.015678,
      countyName: '彰化縣',
      stationAddress: '芬園鄉楓坑村大埔崙產業道路上(芬園往臺灣民俗村路上)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X160',
      stationName: '仁德',
      stationNameEN: 'Rende',
      stationAltitude: 26,
      longitude: 120.25769,
      latitude: 22.968258,
      countyName: '臺南市',
      stationAddress: '仁德區仁義路432號(仁德消防分隊)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0G860',
      stationName: '社頭',
      stationNameEN: 'Shetou',
      stationAltitude: 45,
      longitude: 120.5862,
      latitude: 23.897648,
      countyName: '彰化縣',
      stationAddress: '社頭鄉社斗路一段258號(社頭國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0H950',
      stationName: '中寮',
      stationNameEN: 'Zhongliao',
      stationAltitude: 192,
      longitude: 120.7664,
      latitude: 23.884447,
      countyName: '南投縣',
      stationAddress: '中寮鄉復興村初中巷50號(中寮國中校園內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0Z100',
      stationName: '東華',
      stationNameEN: 'Donghwa',
      stationAltitude: 36,
      longitude: 121.549835,
      latitude: 23.89525,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉大學路二段1號（國立東華大學）',
      startDate: '2014-01-01',
    },
    {
      stationID: 'C0D590',
      stationName: '新豐',
      stationNameEN: 'Sinfong',
      stationAltitude: 143,
      longitude: 120.97721,
      latitude: 24.87068,
      countyName: '新竹縣',
      stationAddress: '新豐鄉松柏村康和路199號(小叮噹科學主題樂園)',
      startDate: '2007-10-01',
    },
    {
      stationID: 'C0S700',
      stationName: '知本',
      stationNameEN: 'Jhihben',
      stationAltitude: 507,
      longitude: 121.005646,
      latitude: 22.684866,
      countyName: '臺東縣',
      stationAddress: '卑南鄉溫泉村(溫泉國小之樂山分校內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0S940',
      stationName: '石寧山',
      stationNameEN: 'Shiningshan',
      stationAltitude: 150,
      longitude: 121.40664,
      latitude: 23.223772,
      countyName: '臺東縣',
      stationAddress: '長濱鄉寧埔村石寧山附近山區',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1Z140',
      stationName: '荖溪',
      stationNameEN: 'Laoxi',
      stationAltitude: 950,
      longitude: 121.45964,
      latitude: 23.890575,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0X170',
      stationName: '關廟',
      stationNameEN: 'Guanmiao',
      stationAltitude: 50,
      longitude: 120.3278,
      latitude: 22.963017,
      countyName: '臺南市',
      stationAddress: '關廟區中正路998號(關廟區公所3樓頂)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0X150',
      stationName: '安定',
      stationNameEN: 'Anding',
      stationAltitude: 18,
      longitude: 120.22784,
      latitude: 23.102613,
      countyName: '臺南市',
      stationAddress: '安定區南安里33-11號(安定消防分隊)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0Z330',
      stationName: '安通山',
      stationNameEN: 'Antongshan',
      stationAltitude: 488,
      longitude: 121.348526,
      latitude: 23.290728,
      countyName: '花蓮縣',
      stationAddress: '玉里鎮安通產業道路附近山區',
      startDate: '2018-05-09',
    },
    {
      stationID: 'C1T830',
      stationName: '布洛灣',
      stationNameEN: 'Buluowan',
      stationAltitude: 675,
      longitude: 121.57127,
      latitude: 24.17184,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉布洛灣地區(布洛灣遊客中心圓環之廣場旁邊)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0H960',
      stationName: '草屯',
      stationNameEN: 'Caotun',
      stationAltitude: 120,
      longitude: 120.68085,
      latitude: 23.973673,
      countyName: '南投縣',
      stationAddress: '草屯鎮中山里草鞋墩1街8號(草屯鎮公所樓頂)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0G870',
      stationName: '芳苑',
      stationNameEN: 'Fangyuan',
      stationAltitude: 12,
      longitude: 120.32002,
      latitude: 23.922302,
      countyName: '彰化縣',
      stationAddress: '芳苑鄉仁愛村斗苑路芳苑段211號(芳苑國中)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0D360',
      stationName: '梅花',
      stationNameEN: 'Meihua',
      stationAltitude: 523,
      longitude: 121.20856,
      latitude: 24.678347,
      countyName: '新竹縣',
      stationAddress: '尖石鄉梅花村1鄰11號(梅花國小校園內)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0S710',
      stationName: '鹿野',
      stationNameEN: 'Luye',
      stationAltitude: 382,
      longitude: 121.12299,
      latitude: 22.917711,
      countyName: '臺東縣',
      stationAddress: '鹿野鄉鹿野高台高眺亭公廁東側草地上',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R620',
      stationName: '墾雷',
      stationNameEN: 'Kenlei',
      stationAltitude: 34,
      longitude: 120.85527,
      latitude: 21.900837,
      countyName: '屏東縣',
      stationAddress: '恆春鎮燈塔路51巷33號(氣象局墾丁雷達站區內)',
      startDate: '2016-06-22',
    },
    {
      stationID: 'C0S950',
      stationName: '七塊厝',
      stationNameEN: 'Qikuaicuo',
      stationAltitude: 415,
      longitude: 121.22992,
      latitude: 22.954453,
      countyName: '臺東縣',
      stationAddress: '東河鄉尚德村七塊厝產業道路附近山區',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1E480',
      stationName: '鳳美',
      stationNameEN: 'Fongmei',
      stationAltitude: 576,
      longitude: 121.034744,
      latitude: 24.554804,
      countyName: '苗栗縣',
      stationAddress: '南庄鄉東河村24鄰石門1號(南庄往鹿場途中)',
      startDate: '1990-07-21',
    },
    {
      stationID: 'C1Z130',
      stationName: '銅門',
      stationNameEN: 'Tongmen',
      stationAltitude: 187,
      longitude: 121.49284,
      latitude: 23.965694,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉銅門村69號(銅門國小)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C1U920',
      stationName: '思源',
      stationNameEN: 'Siyuan',
      stationAltitude: 2085,
      longitude: 121.34721,
      latitude: 24.393126,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉與臺中市和平區交界之羅葉尾山步道邊(大甲溪事業區40林班內)',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0G640',
      stationName: '鹿港',
      stationNameEN: 'Lukang',
      stationAltitude: 17,
      longitude: 120.43048,
      latitude: 24.0753,
      countyName: '彰化縣',
      stationAddress: '鹿港鎮海埔里顏厝巷67號(行政院退輔會魚殖管理處彰化分場內)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0G880',
      stationName: '二水',
      stationNameEN: 'Ershui',
      stationAltitude: 92,
      longitude: 120.6173,
      latitude: 23.810741,
      countyName: '彰化縣',
      stationAddress: '二水鄉文化村119號(二水國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0T820',
      stationName: '天祥',
      stationNameEN: 'Tianxiang',
      stationAltitude: 550,
      longitude: 121.495636,
      latitude: 24.179598,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉立霧溪事業區80林班地(天祥祥德寺)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0U910',
      stationName: '冬山',
      stationNameEN: 'Dongshan',
      stationAltitude: 17,
      longitude: 121.7937,
      latitude: 24.63373,
      countyName: '宜蘭縣',
      stationAddress: '冬山鄉冬山路98-5號(冬山消防分隊)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0S960',
      stationName: '香蘭',
      stationNameEN: 'Xianglan',
      stationAltitude: 32,
      longitude: 120.99338,
      latitude: 22.587568,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉香蘭村四鄰28號 (香蘭國小)',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C1F9B1',
      stationName: '桐林',
      stationNameEN: 'Tonglin',
      stationAltitude: 130,
      longitude: 120.73519,
      latitude: 24.066172,
      countyName: '臺中市',
      stationAddress: '霧峰區桐林里民生路(桐林國小斜對面)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X190',
      stationName: '安平',
      stationNameEN: 'Anping',
      stationAltitude: 10,
      longitude: 120.15218,
      latitude: 22.99316,
      countyName: '臺南市',
      stationAddress: '安平區城平路2號(台江國家公園管理處)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1G691',
      stationName: '下水埔',
      stationNameEN: 'Xiashuipu',
      stationAltitude: 110,
      longitude: 120.5676,
      latitude: 23.816114,
      countyName: '彰化縣',
      stationAddress: '溪州鄉大莊國小(大庄村中橫巷1號)',
      startDate: '2011-10-06',
    },
    {
      stationID: 'C0ACA0',
      stationName: '新莊',
      stationNameEN: 'Xinzhuang',
      stationAltitude: 25,
      longitude: 121.446754,
      latitude: 25.051477,
      countyName: '新北市',
      stationAddress: '新莊區中和街193號(榮富國小校園內)',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0G650',
      stationName: '員林',
      stationNameEN: 'Yuanlin',
      stationAltitude: 34,
      longitude: 120.585594,
      latitude: 23.946503,
      countyName: '彰化縣',
      stationAddress: '員林市萬年里明倫路2號(明倫國中內)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0G890',
      stationName: '伸港',
      stationNameEN: 'Shenggang',
      stationAltitude: 24,
      longitude: 120.484406,
      latitude: 24.148933,
      countyName: '彰化縣',
      stationAddress: '伸港鄉新港村和平路60號(新港國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0S730',
      stationName: '綠島',
      stationNameEN: 'Ludao',
      stationAltitude: 110,
      longitude: 121.47533,
      latitude: 22.6672,
      countyName: '臺東縣',
      stationAddress: '綠島鄉',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0U900',
      stationName: '內城',
      stationNameEN: 'Neicheng',
      stationAltitude: 63,
      longitude: 121.68766,
      latitude: 24.71813,
      countyName: '宜蘭縣',
      stationAddress: '員山鄉內城路545號(內城國民中小學)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0R640',
      stationName: '東港',
      stationNameEN: 'Donggang',
      stationAltitude: 21,
      longitude: 120.44074,
      latitude: 22.46247,
      countyName: '屏東縣',
      stationAddress: '東港鎮豐漁里豐漁街67號（水產試驗所東港生技研究中心）',
      startDate: '2018-08-11',
    },
    {
      stationID: 'C0S970',
      stationName: '加津林',
      stationNameEN: 'Jiajinlin',
      stationAltitude: 19,
      longitude: 120.92694,
      latitude: 22.41621,
      countyName: '臺東縣',
      stationAddress: '大武鄉大竹村14鄰加津林4號(舊高溪派出所)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0X180',
      stationName: '山上',
      stationNameEN: 'Shanshang',
      stationAltitude: 62,
      longitude: 120.36325,
      latitude: 23.0757,
      countyName: '臺南市',
      stationAddress: '山上區豐德里隙子口5之1號(中油豐德供油中心2樓頂)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1F9D1',
      stationName: '白毛台',
      stationNameEN: 'Baimaotai',
      stationAltitude: 639,
      longitude: 120.88693,
      latitude: 24.158325,
      countyName: '臺中市',
      stationAddress: '新社區福興里福民路13號旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0D390',
      stationName: '關西',
      stationNameEN: 'Guanxi',
      stationAltitude: 146,
      longitude: 121.17395,
      latitude: 24.798227,
      countyName: '新竹縣',
      stationAddress: '關西鎮北斗里北門口41號(關西國中校園內)',
      startDate: '2008-01-01',
    },
    {
      stationID: 'C0G660',
      stationName: '溪湖',
      stationNameEN: 'Xihu',
      stationAltitude: 27,
      longitude: 120.47912,
      latitude: 23.94835,
      countyName: '彰化縣',
      stationAddress: '溪湖鎮大竹里彰水路2段725號(湖南國民小學內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0H990',
      stationName: '昆陽',
      stationNameEN: 'Kunyang',
      stationAltitude: 3076,
      longitude: 121.27364,
      latitude: 24.121323,
      countyName: '南投縣',
      stationAddress: '仁愛鄉昆陽警察局後方下坡處(太魯閣國家公園)石碑後20公尺',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0S740',
      stationName: '池上',
      stationNameEN: 'Chihshang',
      stationAltitude: 289,
      longitude: 121.20947,
      latitude: 23.119629,
      countyName: '臺東縣',
      stationAddress: '池上鄉新興村104號(退輔會臺東農場內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R650',
      stationName: '竹田',
      stationNameEN: 'Zhutian',
      stationAltitude: 29,
      longitude: 120.54344,
      latitude: 22.58486,
      countyName: '屏東縣',
      stationAddress: '竹田鄉中正路123號(竹田鄉公所清潔隊)',
      startDate: '2018-08-11',
    },
    {
      stationID: 'C0S980',
      stationName: '勝林山',
      stationNameEN: 'Shenglinshan',
      stationAltitude: 903,
      longitude: 120.8938,
      latitude: 22.430439,
      countyName: '臺東縣',
      stationAddress: '達仁鄉勝林山山頂停機坪旁(與大武鄉交界附近)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0Z150',
      stationName: '吉安光華',
      stationNameEN: 'Guanghua Ji-an',
      stationAltitude: 27,
      longitude: 121.59515,
      latitude: 23.930925,
      countyName: '花蓮縣',
      stationAddress: '吉安鄉南濱路一段531號(水資源回收中心)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C1F9C1',
      stationName: '白冷',
      stationNameEN: 'Baileng',
      stationAltitude: 610,
      longitude: 120.929886,
      latitude: 24.182844,
      countyName: '臺中市',
      stationAddress: '和平區天輪里天輪巷42號(臺中市和平區白冷國民小學校園內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1I400',
      stationName: '凌霄',
      stationNameEN: 'Lingxiao',
      stationAltitude: 1399,
      longitude: 121.004456,
      latitude: 24.018778,
      countyName: '南投縣',
      stationAddress: '仁愛鄉守城段4地號',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0S750',
      stationName: '向陽',
      stationNameEN: 'Siangyang',
      stationAltitude: 2280,
      longitude: 120.98613,
      latitude: 23.248398,
      countyName: '臺東縣',
      stationAddress: '海端鄉向陽(公路局向陽工務段圍牆內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R660',
      stationName: '枋寮',
      stationNameEN: 'Fangliao',
      stationAltitude: 20,
      longitude: 120.590645,
      latitude: 22.367722,
      countyName: '屏東縣',
      stationAddress: '枋寮鄉枋寮村德興路197號(枋寮國小)',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0S990',
      stationName: '山豬窟',
      stationNameEN: 'Shanzhuku',
      stationAltitude: 409,
      longitude: 120.85737,
      latitude: 22.318619,
      countyName: '臺東縣',
      stationAddress: '大武鄉南興村山豬窟產業道路附近山區(梅園涼亭旁)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1V600',
      stationName: '藤枝',
      stationNameEN: 'Tengzhi',
      stationAltitude: 1561,
      longitude: 120.75704,
      latitude: 23.07196,
      countyName: '高雄市',
      stationAddress: '桃源區藤枝森林遊樂區遊客中心',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1F9F1',
      stationName: '伯公龍',
      stationNameEN: 'Bogonglong',
      stationAltitude: 500,
      longitude: 120.849045,
      latitude: 24.272392,
      countyName: '臺中市',
      stationAddress: '東勢區勢林街(宏覺寺旁)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0U950',
      stationName: '鶯子嶺',
      stationNameEN: 'Yingziling',
      stationAltitude: 1012,
      longitude: 121.79244,
      latitude: 24.89754,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮鶯子嶺通信站區內',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0U710',
      stationName: '太平山',
      stationNameEN: 'Taipingshan',
      stationAltitude: 1942,
      longitude: 121.52566,
      latitude: 24.505486,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉太平山93林班地(林務局路標22.3k處)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0V800',
      stationName: '六龜',
      stationNameEN: 'Liugui',
      stationAltitude: 256,
      longitude: 120.63358,
      latitude: 22.997889,
      countyName: '高雄市',
      stationAddress: '六龜區民治路9號(六龜消防隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0S760',
      stationName: '紅石',
      stationNameEN: 'Hongshih',
      stationAltitude: 1621,
      longitude: 121.12622,
      latitude: 23.069132,
      countyName: '臺東縣',
      stationAddress: '海端鄉紅石(紅石瞭望臺旁)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R670',
      stationName: '楓港',
      stationNameEN: 'Fenggang',
      stationAltitude: 26,
      longitude: 120.692154,
      latitude: 22.19013,
      countyName: '屏東縣',
      stationAddress: '枋山鄉善餘村光復路24號(楓港國小)',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0F9A0',
      stationName: '中竹林',
      stationNameEN: 'Zhongzhulin',
      stationAltitude: 425,
      longitude: 120.75106,
      latitude: 24.103556,
      countyName: '臺中市',
      stationAddress: '太平區長龍路中埔二號橋至站址直線距離約1.7km',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0Z170',
      stationName: '卓溪',
      stationNameEN: 'Zhuoxi',
      stationAltitude: 199,
      longitude: 121.3033,
      latitude: 23.34425,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉卓溪村中正路65-5號(卓溪鄉立圖書館)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1I430',
      stationName: '翠華',
      stationNameEN: 'Cuihua',
      stationAltitude: 2415,
      longitude: 121.22434,
      latitude: 24.191961,
      countyName: '南投縣',
      stationAddress: '仁愛鄉翠華村華岡段70地號內',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1U501',
      stationName: '牛鬥',
      stationNameEN: 'Nioudou',
      stationAltitude: 280,
      longitude: 121.573654,
      latitude: 24.637836,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉牛鬥地區',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C1V830',
      stationName: '國三S383K',
      stationNameEN: 'Freeway No. 3 - RAIN – S383K',
      stationAltitude: 114,
      longitude: 120.426476,
      latitude: 22.781967,
      countyName: '高雄市',
      stationAddress: '燕巢區國道3號南向383K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C1F9E1',
      stationName: '龍安',
      stationNameEN: 'Long-an',
      stationAltitude: 563,
      longitude: 120.83196,
      latitude: 24.1605,
      countyName: '臺中市',
      stationAddress: '新社區中和里內(距龍安橋約1.2k處)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0U940',
      stationName: '羅東',
      stationNameEN: 'Luodong',
      stationAltitude: 25,
      longitude: 121.74934,
      latitude: 24.68184,
      countyName: '宜蘭縣',
      stationAddress: '羅東鎮羅東運動公園南側(北成路二段停車場旁自行車道入口)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0R440',
      stationName: '大漢山',
      stationNameEN: 'Dahanshan',
      stationAltitude: 1654,
      longitude: 120.75318,
      latitude: 22.405264,
      countyName: '屏東縣',
      stationAddress: '春日鄉大漢山頂',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0S770',
      stationName: '大溪山',
      stationNameEN: 'Dasishan',
      stationAltitude: 375,
      longitude: 120.94332,
      latitude: 22.478476,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉多良村(廢棄中華電信轉播站旁)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0R680',
      stationName: '佳樂水',
      stationNameEN: 'Jialeshui',
      stationAltitude: 13,
      longitude: 120.86163,
      latitude: 21.993578,
      countyName: '屏東縣',
      stationAddress: '滿州鄉佳樂水風景區內',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0Z160',
      stationName: '鳳林',
      stationNameEN: 'Fenglin',
      stationAltitude: 124,
      longitude: 121.45341,
      latitude: 23.746115,
      countyName: '花蓮縣',
      stationAddress: '鳳林鎮信義路200號(鳳林地政事務所)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C0C660',
      stationName: '楊梅',
      stationNameEN: 'Yangmei',
      stationAltitude: 176,
      longitude: 121.14304,
      latitude: 24.912374,
      countyName: '桃園市',
      stationAddress: '楊梅區金華街100號(楊心國小)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0F930',
      stationName: '大甲',
      stationNameEN: 'Dajia',
      stationAltitude: 100,
      longitude: 120.6404,
      latitude: 24.347668,
      countyName: '臺中市',
      stationAddress: '大甲區開元路71號(大甲高級工業職業學校內)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X320',
      stationName: '柳營',
      stationNameEN: 'Liuying',
      stationAltitude: 30,
      longitude: 120.37525,
      latitude: 23.253328,
      countyName: '臺南市',
      stationAddress: '臺南市柳營區國道3號北上324.7K處',
      startDate: '2020-02-05',
    },
    {
      stationID: 'C0O980',
      stationName: '新市',
      stationNameEN: 'Xinshi',
      stationAltitude: 18,
      longitude: 120.298195,
      latitude: 23.061613,
      countyName: '臺南市',
      stationAddress: '新市區永就里53號(番子寮農場)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0UA70',
      stationName: '桃源谷',
      stationNameEN: 'Taoyuangu',
      stationAltitude: 547,
      longitude: 121.88888,
      latitude: 24.962606,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮及新北市貢寮區交界之桃源谷附近空地',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C1H920',
      stationName: '長豐',
      stationNameEN: 'Changfeng',
      stationAltitude: 736,
      longitude: 120.8799,
      latitude: 24.10204,
      countyName: '南投縣',
      stationAddress: '國姓鄉長豐村東二巷71號(仙徠山莊內)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0F0A0',
      stationName: '雪山圈谷',
      stationNameEN: 'Xueshanjuangu',
      stationAltitude: 3587,
      longitude: 121.236336,
      latitude: 24.388594,
      countyName: '臺中市',
      stationAddress: '和平區(往雪山主峰路上9.8公里處右邊)',
      startDate: '2016-07-15',
    },
    {
      stationID: 'C1E890',
      stationName: '國三N149K',
      stationNameEN: 'Freeway No. 1 - RAIN – N149K',
      stationAltitude: 53,
      longitude: 120.680016,
      latitude: 24.463413,
      countyName: '苗栗縣',
      stationAddress: '國道3號北向149K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C0C670',
      stationName: '龍潭',
      stationNameEN: 'Longtan',
      stationAltitude: 250,
      longitude: 121.22139,
      latitude: 24.870056,
      countyName: '桃園市',
      stationAddress: '龍潭區中興路726號（龍潭消防分隊）',
      startDate: '2014-04-01',
    },
    {
      stationID: 'C0E850',
      stationName: '大河',
      stationNameEN: 'Dahe',
      stationAltitude: 104,
      longitude: 120.94858,
      latitude: 24.618069,
      countyName: '苗栗縣',
      stationAddress: '三灣鄉大河村48號(大河派出所)',
      startDate: '2015-08-04',
    },
    {
      stationID: 'C0V370',
      stationName: '古亭坑',
      stationNameEN: 'Gutingkeng',
      stationAltitude: 74,
      longitude: 120.40195,
      latitude: 22.893179,
      countyName: '高雄市',
      stationAddress: '田寮區古亭里',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0E610',
      stationName: '馬都安',
      stationNameEN: 'Madu-an',
      stationAltitude: 850,
      longitude: 120.93019,
      latitude: 24.451271,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉錦水村(由泰安開車約2小時瞭望台旁)',
      startDate: '1990-07-21',
    },
    {
      stationID: 'C0X310',
      stationName: '七股',
      stationNameEN: 'Qigu',
      stationAltitude: 9,
      longitude: 120.08625,
      latitude: 23.14729,
      countyName: '臺南市',
      stationAddress: '七股區鹽埕237-11號(氣象局七股雷達站區內)',
      startDate: '2016-02-01',
    },
    {
      stationID: 'C1V170',
      stationName: '排雲',
      stationNameEN: 'Paiyun',
      stationAltitude: 3690,
      longitude: 120.95415,
      latitude: 23.46362,
      countyName: '高雄市',
      stationAddress: '桃源區排雲山莊往南峰方向步行約6小時(登山口-8.5k排雲山莊-1.5k測站)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0O990',
      stationName: '媽廟',
      stationNameEN: 'Mamiao',
      stationAltitude: 18,
      longitude: 120.293526,
      latitude: 22.99175,
      countyName: '臺南市',
      stationAddress: '歸仁區中正北路3段60號(保西國民小學內)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0UA60',
      stationName: '樟樹山',
      stationNameEN: 'Zhangshushan',
      stationAltitude: 566,
      longitude: 121.78325,
      latitude: 24.41393,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉武塔村樟樹山附近山區',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0K390',
      stationName: '土庫',
      stationNameEN: 'Tuku',
      stationAltitude: 31,
      longitude: 120.39558,
      latitude: 23.67903,
      countyName: '雲林縣',
      stationAddress: '土庫鎮建國路41號(土庫消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0F0B0',
      stationName: '石岡',
      stationNameEN: 'Shigang',
      stationAltitude: 311,
      longitude: 120.77764,
      latitude: 24.27606,
      countyName: '臺中市',
      stationAddress: '石岡區石岡街6號 (臺中市石岡區衛生所)',
      startDate: '2016-10-01',
    },
    {
      stationID: 'C0AC60',
      stationName: '三峽',
      stationNameEN: 'Sanshia',
      stationAltitude: 55,
      longitude: 121.36973,
      latitude: 24.939026,
      countyName: '新北市',
      stationAddress: '三峽區復興路238號(三峽國中校園內)',
      startDate: '2009-10-01',
    },
    {
      stationID: 'C0C680',
      stationName: '龜山',
      stationNameEN: 'Guishan',
      stationAltitude: 228,
      longitude: 121.38656,
      latitude: 25.02846,
      countyName: '桃園市',
      stationAddress: '龜山區文化一路250號(國立體育大學田徑場)',
      startDate: '2017-04-12',
    },
    {
      stationID: 'C1M390',
      stationName: '龍美',
      stationNameEN: 'Longmei',
      stationAltitude: 1090,
      longitude: 120.65442,
      latitude: 23.406729,
      countyName: '嘉義縣',
      stationAddress: '番路鄉公興村隆美地區(舊地名為瀨頭)私人茶園內',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0G800',
      stationName: '埔鹽',
      stationNameEN: 'Puyan',
      stationAltitude: 15,
      longitude: 120.43169,
      latitude: 24.000395,
      countyName: '彰化縣',
      stationAddress: '埔鹽鄉永樂村永樂路600號(永樂國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X100',
      stationName: '臺南市北區',
      stationNameEN: 'Beiqu Tainan City',
      stationAltitude: 10,
      longitude: 120.19423,
      latitude: 23.010393,
      countyName: '臺南市',
      stationAddress: '北區文賢路678號(和緯消防分隊)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1H941',
      stationName: '雙冬',
      stationNameEN: 'Shuangdong',
      stationAltitude: 630,
      longitude: 120.80219,
      latitude: 23.967379,
      countyName: '南投縣',
      stationAddress: '草屯鎮雙冬里',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0T9M0',
      stationName: '靜浦',
      stationNameEN: 'Jingpu',
      stationAltitude: 92,
      longitude: 121.495026,
      latitude: 23.455168,
      countyName: '花蓮縣',
      stationAddress: '豐濱鄉靜浦村活動中心後山上',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C1N001',
      stationName: '沙崙',
      stationNameEN: 'Shalun',
      stationAltitude: 24,
      longitude: 120.3094,
      latitude: 22.935472,
      countyName: '臺南市',
      stationAddress: '歸仁區沙崙里184號北沙崙農場',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C0F0C0',
      stationName: '中坑',
      stationNameEN: 'Zhongkeng',
      stationAltitude: 983,
      longitude: 120.90332,
      latitude: 24.248516,
      countyName: '臺中市',
      stationAddress: '和平區中坑里中坑段39地號內',
      startDate: '2017-06-14',
    },
    {
      stationID: 'C0AC70',
      stationName: '信義',
      stationNameEN: 'Xinyi',
      stationAltitude: 71,
      longitude: 121.5646,
      latitude: 25.037823,
      countyName: '臺北市',
      stationAddress: '信義區市府路1號(台北市政府頂樓)',
      startDate: '2009-10-01',
    },
    {
      stationID: 'C1E670',
      stationName: '南礦',
      stationNameEN: 'Nankuang',
      stationAltitude: 1209,
      longitude: 121.00284,
      latitude: 24.538279,
      countyName: '苗栗縣',
      stationAddress: '南庄鄉蓬萊林道末端(南庄事業區18林班地)',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0E870',
      stationName: '高鐵苗栗',
      stationNameEN: 'THSR Miaoli',
      stationAltitude: 34,
      longitude: 120.826096,
      latitude: 24.60725,
      countyName: '苗栗縣',
      stationAddress: '後龍鎮高鐵三路268號(高鐵苗栗站外)',
      startDate: '2017-08-01',
    },
    {
      stationID: 'C0D540',
      stationName: '橫山',
      stationNameEN: 'Hengshan',
      stationAltitude: 227,
      longitude: 121.11693,
      latitude: 24.71116,
      countyName: '新竹縣',
      stationAddress: '橫山鄉橫山村橫山段426-6地號上',
      startDate: '2007-10-01',
    },
    {
      stationID: 'C0G810',
      stationName: '埔心',
      stationNameEN: 'Puxin',
      stationAltitude: 26,
      longitude: 120.52546,
      latitude: 23.947634,
      countyName: '彰化縣',
      stationAddress: '埔心鄉舊館村員鹿路四段201號(舊館國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0R800',
      stationName: '丹路',
      stationNameEN: 'Danlu',
      stationAltitude: 131,
      longitude: 120.75099,
      latitude: 22.204481,
      countyName: '屏東縣',
      stationAddress: '屏東縣獅子鄉丹路一巷1號',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1V190',
      stationName: '南天池',
      stationNameEN: 'Nantianchi',
      stationAltitude: 2700,
      longitude: 120.91176,
      latitude: 23.27403,
      countyName: '高雄市',
      stationAddress: '桃源區長春祠旁之步道步行約50分鐘',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0AC80',
      stationName: '文山',
      stationNameEN: 'Wenshan',
      stationAltitude: 40,
      longitude: 121.57573,
      latitude: 25.00235,
      countyName: '臺北市',
      stationAddress: '文山區木柵路4段159巷14-1號(博嘉國小校園內)',
      startDate: '2009-12-01',
    },
    {
      stationID: 'C0F0D0',
      stationName: '審馬陣',
      stationNameEN: 'Shenmazhen',
      stationAltitude: 3171,
      longitude: 121.4203,
      latitude: 24.381676,
      countyName: '臺中市',
      stationAddress: '和平區南湖大山指標16.2K處(審馬陣山附近)',
      startDate: '2017-12-28',
    },
    {
      stationID: 'C0C460',
      stationName: '復興',
      stationNameEN: 'Fuxing',
      stationAltitude: 482,
      longitude: 121.35228,
      latitude: 24.820208,
      countyName: '桃園市',
      stationAddress: '復興區澤仁里中正路248號(介壽國民中學校園內)',
      startDate: '2007-10-01',
    },
    {
      stationID: 'C0F970',
      stationName: '大坑',
      stationNameEN: 'Dakeng',
      stationAltitude: 145,
      longitude: 120.72229,
      latitude: 24.173141,
      countyName: '臺中市',
      stationAddress: '北屯區軍福十三路268號(東山消防分隊)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0D550',
      stationName: '雪霸',
      stationNameEN: 'Xueba',
      stationAltitude: 1956,
      longitude: 121.11605,
      latitude: 24.526972,
      countyName: '新竹縣',
      stationAddress: '五峰鄉桃山村民石380號(雪霸農場內)',
      startDate: '2007-10-01',
    },
    {
      stationID: 'C0E880',
      stationName: '三義',
      stationNameEN: 'Sanyi',
      stationAltitude: 507,
      longitude: 120.74982,
      latitude: 24.40548,
      countyName: '苗栗縣',
      stationAddress: '苗栗縣三義鄉130縣道13.5K附近（慈濟山茶園內）',
      startDate: '2020-01-07',
    },
    {
      stationID: 'C0Z300',
      stationName: '東里',
      stationNameEN: 'Dongli',
      stationAltitude: 182,
      longitude: 121.30547,
      latitude: 23.26853,
      countyName: '花蓮縣',
      stationAddress: '富里鄉東里村大莊路52號(東里消防分隊)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0X120',
      stationName: '麻豆',
      stationNameEN: 'Madou',
      stationAltitude: 28,
      longitude: 120.24861,
      latitude: 23.183289,
      countyName: '臺南市',
      stationAddress: '麻豆區興國路9號(麻豆消防分隊5樓頂)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0G820',
      stationName: '田尾',
      stationNameEN: 'Tianwei',
      stationAltitude: 29,
      longitude: 120.50939,
      latitude: 23.901669,
      countyName: '彰化縣',
      stationAddress: '田尾鄉中正路二段101號(仁豐國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0S900',
      stationName: '蘭嶼高中',
      stationNameEN: 'Lanyu High School',
      stationAltitude: 27,
      longitude: 121.51214,
      latitude: 22.04909,
      countyName: '臺東縣',
      stationAddress: '蘭嶼鄉椰油村37號（蘭嶼高中內）',
      startDate: '2018-10-26',
    },
    {
      stationID: 'C0R810',
      stationName: '內獅',
      stationNameEN: 'Neishi',
      stationAltitude: 25,
      longitude: 120.64298,
      latitude: 22.305063,
      countyName: '屏東縣',
      stationAddress: '獅子鄉內獅村1號(內獅國小)',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1E691',
      stationName: '南湖',
      stationNameEN: 'Nanhu',
      stationAltitude: 786,
      longitude: 120.83753,
      latitude: 24.40135,
      countyName: '苗栗縣',
      stationAddress: '大湖鄉(位於南湖台3線135k處之吊樑道山上)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0F0E0',
      stationName: '南湖圈谷',
      stationNameEN: 'Nanhuquangu',
      stationAltitude: 3557,
      longitude: 121.444664,
      latitude: 24.363714,
      countyName: '臺中市',
      stationAddress: '和平區南湖大山指標20K處(南湖主峰東峰岔路附近)',
      startDate: '2017-12-28',
    },
    {
      stationID: 'C1T800',
      stationName: '洛韶',
      stationNameEN: 'Luoshao',
      stationAltitude: 1260,
      longitude: 121.45396,
      latitude: 24.204557,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉富世村中橫公路洛韶11號旁',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C1E451',
      stationName: '象鼻',
      stationNameEN: 'Xiangbi',
      stationAltitude: 950,
      longitude: 120.94023,
      latitude: 24.368616,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉象鼻村',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0D560',
      stationName: '竹東',
      stationNameEN: 'Zhudong',
      stationAltitude: 147,
      longitude: 121.057884,
      latitude: 24.767147,
      countyName: '新竹縣',
      stationAddress: '竹東鎮二重國民小學校園內',
      startDate: '2008-01-01',
    },
    {
      stationID: 'C0G830',
      stationName: '埤頭',
      stationNameEN: 'Pitou',
      stationAltitude: 27,
      longitude: 120.452805,
      latitude: 23.866003,
      countyName: '彰化縣',
      stationAddress: '埤頭鄉大湖村大湖路260號(大湖國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0X110',
      stationName: '臺南市南區',
      stationNameEN: 'Nanqu Tainan City',
      stationAltitude: 20,
      longitude: 120.18838,
      latitude: 22.96119,
      countyName: '臺南市',
      stationAddress: '南區明興路2號(南區區公所)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0S910',
      stationName: '蘭嶼燈塔',
      stationNameEN: 'Lanyu Lighthouse',
      stationAltitude: 212,
      longitude: 121.50312,
      latitude: 22.081198,
      countyName: '臺東縣',
      stationAddress: '蘭嶼鄉椰油段1-7地號（蘭嶼燈塔旁）',
      startDate: '2018-10-26',
    },
    {
      stationID: 'C0R820',
      stationName: '白鷺',
      stationNameEN: 'Bailu',
      stationAltitude: 588,
      longitude: 120.66622,
      latitude: 22.454275,
      countyName: '屏東縣',
      stationAddress: '屏東縣來義鄉白鷺大橋沿白鷺道路5.5公里處',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C0T9N0',
      stationName: '富里',
      stationNameEN: 'Fuli',
      stationAltitude: 261,
      longitude: 121.249374,
      latitude: 23.171638,
      countyName: '花蓮縣',
      stationAddress: '富里鄉永安街52號(富里國小)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C1R610',
      stationName: '西大武山',
      stationNameEN: 'Xidawushan',
      stationAltitude: 1828,
      longitude: 120.7322,
      latitude: 22.61772,
      countyName: '屏東縣',
      stationAddress: '泰武鄉北大武山步道2.6k',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1E681',
      stationName: '南勢山',
      stationNameEN: 'Nanshishan',
      stationAltitude: 977,
      longitude: 120.92383,
      latitude: 24.421669,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉大興村(從泰安國小進入站址車程約10分鐘)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0G840',
      stationName: '北斗',
      stationNameEN: 'Beidou',
      stationAltitude: 32,
      longitude: 120.51422,
      latitude: 23.877586,
      countyName: '彰化縣',
      stationAddress: '北斗鎮斗苑路二段150號(螺陽國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0E420',
      stationName: '竹南',
      stationNameEN: 'Jhunan',
      stationAltitude: 19,
      longitude: 120.8889,
      latitude: 24.708979,
      countyName: '苗栗縣',
      stationAddress: '竹南鎮大埔里仁愛路1092號(竹南鎮大埔國民小學內)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0X140',
      stationName: '西港',
      stationNameEN: 'Xigang',
      stationAltitude: 17,
      longitude: 120.20311,
      latitude: 23.125608,
      countyName: '臺南市',
      stationAddress: '西港區中山路450號(西港消防分隊4樓頂)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0Z320',
      stationName: '清水林道',
      stationNameEN: 'Qingshuilindao',
      stationAltitude: 646,
      longitude: 121.18827,
      latitude: 23.27125,
      countyName: '花蓮縣',
      stationAddress: '卓溪鄉清水林道北側山區(新生段1地號)',
      startDate: '2018-05-09',
    },
    {
      stationID: 'C0C480',
      stationName: '桃園',
      stationNameEN: 'Taoyuan',
      stationAltitude: 105,
      longitude: 121.32317,
      latitude: 24.992426,
      countyName: '桃園市',
      stationAddress: '桃園區成功路二段144號(桃園高級農工職業學校校園內)',
      startDate: '2008-01-01',
    },
    {
      stationID: 'C0S920',
      stationName: '金峰嘉蘭',
      stationNameEN: 'Jialan Jinfeng',
      stationAltitude: 133,
      longitude: 120.9609,
      latitude: 22.598759,
      countyName: '臺東縣',
      stationAddress: '金峰鄉嘉蘭村12鄰665號(金峰鄉嘉蘭村活動中心)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0D570',
      stationName: '香山',
      stationNameEN: 'Siangshan',
      stationAltitude: 15,
      longitude: 120.90546,
      latitude: 24.746893,
      countyName: '新竹市',
      stationAddress: '香山區長興街路旁',
      startDate: '2007-12-01',
    },
    {
      stationID: 'C0R830',
      stationName: '高士',
      stationNameEN: 'Gaoshi',
      stationAltitude: 383,
      longitude: 120.836784,
      latitude: 22.121824,
      countyName: '屏東縣',
      stationAddress: '屏東縣牡丹鄉高士社區進水廠水塔頂',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1D380',
      stationName: '新埔',
      stationNameEN: 'Sinpu',
      stationAltitude: 65,
      longitude: 121.03834,
      latitude: 24.84745,
      countyName: '新竹縣',
      stationAddress: '新埔鎮下寮里43號義民廟右方路旁',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C1Z120',
      stationName: '壽豐',
      stationNameEN: 'Shoufeng',
      stationAltitude: 62,
      longitude: 121.50808,
      latitude: 23.870853,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉壽豐村壽山路37號(壽豐鄉文康中心)',
      startDate: '2017-06-03',
    },
    {
      stationID: 'C0E430',
      stationName: '南庄',
      stationNameEN: 'Nanzhuang',
      stationAltitude: 258,
      longitude: 121.00021,
      latitude: 24.601774,
      countyName: '苗栗縣',
      stationAddress: '南庄鄉東村文化路3號(南庄國小校園內)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0X130',
      stationName: '官田',
      stationNameEN: 'Guantian',
      stationAltitude: 27,
      longitude: 120.31542,
      latitude: 23.193216,
      countyName: '臺南市',
      stationAddress: '官田區中山路一段132號(官田區公所)',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C1R630',
      stationName: '龍泉',
      stationNameEN: 'Longquan',
      stationAltitude: 60,
      longitude: 120.59091,
      latitude: 22.66701,
      countyName: '屏東縣',
      stationAddress: '內埔鄉大新路109號（屏東縣新生國民小學）',
      startDate: '2018-08-11',
    },
    {
      stationID: 'C0Z310',
      stationName: '清水斷崖',
      stationNameEN: 'Qingshui Cliff',
      stationAltitude: 59,
      longitude: 121.68914,
      latitude: 24.218294,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉臺9線大清水遊憩區下方空地',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0C490',
      stationName: '八德',
      stationNameEN: 'Bade',
      stationAltitude: 157,
      longitude: 121.28329,
      latitude: 24.928707,
      countyName: '桃園市',
      stationAddress: '八德區興仁里中山路94號(八德國民小學校園內)',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C0D580',
      stationName: '寶山',
      stationNameEN: 'Baoshan',
      stationAltitude: 120,
      longitude: 121.02519,
      latitude: 24.735006,
      countyName: '新竹縣',
      stationAddress: '寶山鄉新湖路280號旁',
      startDate: '2007-12-01',
    },
    {
      stationID: 'C0R600',
      stationName: '舊泰武',
      stationNameEN: 'Jiutaiwu',
      stationAltitude: 766,
      longitude: 120.66495,
      latitude: 22.588194,
      countyName: '屏東縣',
      stationAddress: '泰武鄉泰武村良武巷101號(舊泰武村老人活動中心)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0S930',
      stationName: '延平',
      stationNameEN: 'Yanping',
      stationAltitude: 248,
      longitude: 121.08406,
      latitude: 22.90258,
      countyName: '臺東縣',
      stationAddress: '臺東縣延平鄉昇平路75號(延平消防分隊)',
      startDate: '2019-07-05',
    },
    {
      stationID: 'C0R840',
      stationName: '牡丹池山',
      stationNameEN: 'Mudanchisahn',
      stationAltitude: 536,
      longitude: 120.841225,
      latitude: 22.168388,
      countyName: '屏東縣',
      stationAddress: '屏東縣牡丹鄉牡丹池山山頂',
      startDate: '2020-04-09',
    },
    {
      stationID: 'C1H971',
      stationName: '六分寮',
      stationNameEN: 'Liufenliao',
      stationAltitude: 428,
      longitude: 120.6378,
      latitude: 23.92507,
      countyName: '南投縣',
      stationAddress: '南投市鳳鳴里八卦路831號(南投縣政府消防局第一大隊鳳鳴分隊)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1Z110',
      stationName: '三棧',
      stationNameEN: 'Sanzhan',
      stationAltitude: 680,
      longitude: 121.60501,
      latitude: 24.11557,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉雙露山',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1E461',
      stationName: '松安',
      stationNameEN: 'Song-an',
      stationAltitude: 1325,
      longitude: 120.98602,
      latitude: 24.397972,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉雪霸國家公園二本松解說站旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1T810',
      stationName: '慈恩',
      stationNameEN: 'Ci-en',
      stationAltitude: 2049,
      longitude: 121.38839,
      latitude: 24.192043,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉富世村關原路3號(救國團慈恩山莊)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0V660',
      stationName: '岡山',
      stationNameEN: 'Gangshan',
      stationAltitude: 25,
      longitude: 120.29496,
      latitude: 22.797077,
      countyName: '高雄市',
      stationAddress: '岡山區岡山路341號(岡山地政事務所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1I290',
      stationName: '豐丘',
      stationNameEN: 'Fengqiu',
      stationAltitude: 1151,
      longitude: 120.88742,
      latitude: 23.668179,
      countyName: '南投縣',
      stationAddress: '信義鄉豐丘村豐丘產業道路末端',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1V220',
      stationName: '小關山',
      stationNameEN: 'Xiaoguanshan',
      stationAltitude: 1781,
      longitude: 120.81361,
      latitude: 23.154217,
      countyName: '高雄市',
      stationAddress: '桃源區老濃溪事業區99林班地',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0A520',
      stationName: '山佳',
      stationNameEN: 'Shanjia',
      stationAltitude: 48,
      longitude: 121.40201,
      latitude: 24.974943,
      countyName: '新北市',
      stationAddress: '樹林區中山路3段5號(山佳國民小學校園內)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0I480',
      stationName: '梅峰',
      stationNameEN: 'Meifeng',
      stationAltitude: 2165,
      longitude: 121.17357,
      latitude: 24.085272,
      countyName: '南投縣',
      stationAddress: '仁愛鄉大同村仁和路215號(梅峰農場)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0K420',
      stationName: '西螺',
      stationNameEN: 'Xiluo',
      stationAltitude: 42,
      longitude: 120.46739,
      latitude: 23.800407,
      countyName: '雲林縣',
      stationAddress: '西螺鎮公正路211號(西螺消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1C510',
      stationName: '水尾',
      stationNameEN: 'Shueiwei',
      stationAltitude: 106,
      longitude: 121.08716,
      latitude: 24.94008,
      countyName: '桃園市',
      stationAddress: '楊梅區豐野里1鄰151號(富崗水尾工作站內)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C1I050',
      stationName: '丹大',
      stationNameEN: 'Danda',
      stationAltitude: 2568,
      longitude: 121.14114,
      latitude: 23.753748,
      countyName: '南投縣',
      stationAddress: '信義鄉丹大事業區第十七林班地(瞭望台旁)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0AH70',
      stationName: '松山',
      stationNameEN: 'Songshan',
      stationAltitude: 34,
      longitude: 121.55042,
      latitude: 25.04871,
      countyName: '臺北市',
      stationAddress: '松山區敦化北路1號(臺北市松山運動中心)',
      startDate: '2017-04-26',
    },
    {
      stationID: 'C0F9K0',
      stationName: '大安',
      stationNameEN: 'Da-an',
      stationAltitude: 30,
      longitude: 120.58803,
      latitude: 24.34506,
      countyName: '臺中市',
      stationAddress: '大安區中庄里中山南路296號(大安國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1I280',
      stationName: '埔中',
      stationNameEN: 'Puzhong',
      stationAltitude: 439,
      longitude: 120.64259,
      latitude: 23.820778,
      countyName: '南投縣',
      stationAddress: '名間鄉埔中村11之3號後面',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0AD00',
      stationName: '三芝',
      stationNameEN: 'Sanzhi',
      stationAltitude: 86,
      longitude: 121.501915,
      latitude: 25.258131,
      countyName: '新北市',
      stationAddress: '三芝區育英路22號(三芝國小內)',
      startDate: '2009-11-01',
    },
    {
      stationID: 'C0I010',
      stationName: '廬山',
      stationNameEN: 'Lushan',
      stationAltitude: 1562,
      longitude: 121.18181,
      latitude: 24.033312,
      countyName: '南投縣',
      stationAddress: '仁愛鄉大同村仁和路21號(埔里-霧社收費站-盧山電力公司瞭望台)',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0K430',
      stationName: '褒忠',
      stationNameEN: 'Baozhong',
      stationAltitude: 30,
      longitude: 120.303635,
      latitude: 23.690939,
      countyName: '雲林縣',
      stationAddress: '褒忠鄉中正路451號(褒忠鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0A530',
      stationName: '坪林',
      stationNameEN: 'Pinglin',
      stationAltitude: 300,
      longitude: 121.70938,
      latitude: 24.938204,
      countyName: '新北市',
      stationAddress: '坪林區',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0V650',
      stationName: '彌陀',
      stationNameEN: 'Mituo',
      stationAltitude: 28,
      longitude: 120.24636,
      latitude: 22.783806,
      countyName: '高雄市',
      stationAddress: '彌陀區中正北路7號(彌陀消防分隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0M850',
      stationName: '表湖',
      stationNameEN: 'Biaohu',
      stationAltitude: 695,
      longitude: 120.64166,
      latitude: 23.24186,
      countyName: '嘉義縣',
      stationAddress: '大埔鄉溪頭段1地號',
      startDate: '2018-02-14',
    },
    {
      stationID: 'C0I490',
      stationName: '萬大林道',
      stationNameEN: 'Wandalindao',
      stationAltitude: 1601,
      longitude: 121.09141,
      latitude: 23.928228,
      countyName: '南投縣',
      stationAddress: '仁愛鄉曲冰段(萬大林道約7.2k附近高地)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1M640',
      stationName: '十字',
      stationNameEN: 'Shizi',
      stationAltitude: 1567,
      longitude: 120.753685,
      latitude: 23.492153,
      countyName: '嘉義縣',
      stationAddress: '阿里山鄉十字村16號（十字國小樓頂）',
      startDate: '2019-01-01',
    },
    {
      stationID: 'C0C700',
      stationName: '中壢',
      stationNameEN: 'Zhongli',
      stationAltitude: 151,
      longitude: 121.25638,
      latitude: 24.977661,
      countyName: '桃園市',
      stationAddress: '中壢區成章四街120號(內壢高中樓頂)',
      startDate: '2017-12-27',
    },
    {
      stationID: 'C0AH80',
      stationName: '深坑',
      stationNameEN: 'Shenkeng',
      stationAltitude: 68,
      longitude: 121.62833,
      latitude: 25.002703,
      countyName: '新北市',
      stationAddress: '深坑區文山路1段35號(深坑消防分隊樓頂)',
      startDate: '2017-08-02',
    },
    {
      stationID: 'C0F9L0',
      stationName: '后里',
      stationNameEN: 'Houli',
      stationAltitude: 253,
      longitude: 120.72973,
      latitude: 24.310436,
      countyName: '臺中市',
      stationAddress: '后里區甲后路110號(后里國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1M400',
      stationName: '菜瓜坪',
      stationNameEN: 'Caiguaping',
      stationAltitude: 369,
      longitude: 120.5761,
      latitude: 23.251928,
      countyName: '嘉義縣',
      stationAddress: '大埔鄉西興村',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0V680',
      stationName: '仁武',
      stationNameEN: 'Renwu',
      stationAltitude: 40,
      longitude: 120.347725,
      latitude: 22.701082,
      countyName: '高雄市',
      stationAddress: '仁武區中正路133號(仁武區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1E701',
      stationName: '八卦',
      stationNameEN: 'Bagua',
      stationAltitude: 737,
      longitude: 120.92762,
      latitude: 24.491756,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉(與八卦派出所相距約1.2k處)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0V440',
      stationName: '鳳山',
      stationNameEN: 'Fengshan',
      stationAltitude: 27,
      longitude: 120.356125,
      latitude: 22.646652,
      countyName: '高雄市',
      stationAddress: '鳳山區文山路園藝巷4號（鳳山熱帶園藝試驗分所）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1I070',
      stationName: '和社',
      stationNameEN: 'Heshe',
      stationAltitude: 825,
      longitude: 120.88886,
      latitude: 23.591131,
      countyName: '南投縣',
      stationAddress: '信義鄉中橫同富村同和巷47號和社分社觀測坪內',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0K440',
      stationName: '二崙',
      stationNameEN: 'Erlun',
      stationAltitude: 35,
      longitude: 120.40894,
      latitude: 23.772797,
      countyName: '雲林縣',
      stationAddress: '二崙鄉中正路80號(二崙消防分隊)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0A540',
      stationName: '四堵',
      stationNameEN: 'Sihdu',
      stationAltitude: 401,
      longitude: 121.745895,
      latitude: 24.892761,
      countyName: '新北市',
      stationAddress: '坪林區碧湖(北宜路旁之碧湖橋道路上行約10分鐘)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0M860',
      stationName: '新美',
      stationNameEN: 'Shinmei',
      stationAltitude: 578,
      longitude: 120.68317,
      latitude: 23.338017,
      countyName: '嘉義縣',
      stationAddress: '嘉義縣阿里山鄉新美村78號',
      startDate: '2020-03-31',
    },
    {
      stationID: 'C0AD10',
      stationName: '八里',
      stationNameEN: 'Bali',
      stationAltitude: 27,
      longitude: 121.403946,
      latitude: 25.150211,
      countyName: '新北市',
      stationAddress: '八里區中山路二段338號(八里國小內)',
      startDate: '2009-11-01',
    },
    {
      stationID: 'C0C710',
      stationName: '大溪永福',
      stationNameEN: 'Yongfu Daxi',
      stationAltitude: 143,
      longitude: 121.324974,
      latitude: 24.892937,
      countyName: '桃園市',
      stationAddress: '大溪區信義路1165號（永福國小）',
      startDate: '2018-12-26',
    },
    {
      stationID: 'C1O850',
      stationName: '環湖',
      stationNameEN: 'Huanhu',
      stationAltitude: 44,
      longitude: 120.41863,
      latitude: 23.148617,
      countyName: '臺南市',
      stationAddress: '大內區頭社里10號(大內國小鳴頭分校內)',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0F9M0',
      stationName: '豐原',
      stationNameEN: 'Fengyuan',
      stationAltitude: 230,
      longitude: 120.720695,
      latitude: 24.254322,
      countyName: '臺中市',
      stationAddress: '豐原區豐榮里12鄰新生北路155號(豐原國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0AH90',
      stationName: '福山植物園',
      stationNameEN: 'Fushan Botanical Garden',
      stationAltitude: 660,
      longitude: 121.58135,
      latitude: 24.760717,
      countyName: '新北市',
      stationAddress: '烏來區福山植物園內(生活植物區之民俗植物區觀測坪)',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0V670',
      stationName: '楠梓',
      stationNameEN: 'Nanzi',
      stationAltitude: 20,
      longitude: 120.286,
      latitude: 22.71975,
      countyName: '高雄市',
      stationAddress: '楠梓區德民路1010號(國昌國民中學)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1V231',
      stationName: '高中',
      stationNameEN: 'Gaozhong',
      stationAltitude: 731,
      longitude: 120.71671,
      latitude: 23.13491,
      countyName: '高雄市',
      stationAddress: '桃源區高中里',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0O810',
      stationName: '曾文',
      stationNameEN: 'Cengwen',
      stationAltitude: 161,
      longitude: 120.49733,
      latitude: 23.21968,
      countyName: '臺南市',
      stationAddress: '楠西區曾文新村(曾文水庫管理局內)',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0K450',
      stationName: '大埤',
      stationNameEN: 'Dapi',
      stationAltitude: 35,
      longitude: 120.43153,
      latitude: 23.646074,
      countyName: '雲林縣',
      stationAddress: '大埤鄉中山路8號(大埤鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0A550',
      stationName: '泰平',
      stationNameEN: 'Taiping',
      stationAltitude: 422,
      longitude: 121.82371,
      latitude: 24.971197,
      countyName: '新北市',
      stationAddress: '雙溪區泰平里8鄰大平路43號(泰平國小分校附近)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0F9N0',
      stationName: '大里',
      stationNameEN: 'Dali',
      stationAltitude: 75,
      longitude: 120.70138,
      latitude: 24.092464,
      countyName: '臺中市',
      stationAddress: '大里區文化街120號(塗城國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0K460',
      stationName: '斗南',
      stationNameEN: 'Dounan',
      stationAltitude: 60,
      longitude: 120.478355,
      latitude: 23.678734,
      countyName: '雲林縣',
      stationAddress: '斗南鎮延平路二段266號(斗南鎮停一立體停車場)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0A560',
      stationName: '福山',
      stationNameEN: 'Fushan',
      stationAltitude: 405,
      longitude: 121.50281,
      latitude: 24.776203,
      countyName: '新北市',
      stationAddress: '烏來區福山里李茂岸八號(福山國小旁)',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0M640',
      stationName: '中埔',
      stationNameEN: 'Zhongpu',
      stationAltitude: 155,
      longitude: 120.5229,
      latitude: 23.42538,
      countyName: '嘉義縣',
      stationAddress: '中埔鄉中埔村128號(中埔鄉公所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0F9O0',
      stationName: '潭子',
      stationNameEN: 'Tanzi',
      stationAltitude: 175,
      longitude: 120.70393,
      latitude: 24.213106,
      countyName: '臺中市',
      stationAddress: '潭子區中山路二段435號(潭子國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1D400',
      stationName: '鳥嘴山',
      stationNameEN: 'Niaozueishan',
      stationAltitude: 839,
      longitude: 121.28387,
      latitude: 24.71909,
      countyName: '新竹縣',
      stationAddress: '尖石鄉新樂村復興礦場',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0AD30',
      stationName: '蘆洲',
      stationNameEN: 'Lujhou',
      stationAltitude: 20,
      longitude: 121.47233,
      latitude: 25.086594,
      countyName: '新北市',
      stationAddress: '蘆洲區三民路96號(三民高中內)',
      startDate: '2009-11-01',
    },
    {
      stationID: 'C1D640',
      stationName: '外坪(五指山)',
      stationNameEN: 'Waiping(Wuzhihshan)',
      stationAltitude: 718,
      longitude: 121.09096,
      latitude: 24.644447,
      countyName: '新竹縣',
      stationAddress: '北埔鄉大坪段內大坪小段36-54號地',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C1O870',
      stationName: '大棟山',
      stationNameEN: 'Dadongshan',
      stationAltitude: 1249,
      longitude: 120.52226,
      latitude: 23.31157,
      countyName: '臺南市',
      stationAddress: '白河區大棟山三角點',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0V690',
      stationName: '鼓山',
      stationNameEN: 'Gushan',
      stationAltitude: 30,
      longitude: 120.27557,
      latitude: 22.623325,
      countyName: '高雄市',
      stationAddress: '鼓山區臨海一路61-1號(鼓山消防分隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0F9Q0',
      stationName: '外埔',
      stationNameEN: 'Waipu',
      stationAltitude: 149,
      longitude: 120.70569,
      latitude: 24.347816,
      countyName: '臺中市',
      stationAddress: '外埔區土城里土城西路55號(安定國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0V450',
      stationName: '鳳森',
      stationNameEN: 'Fengsen',
      stationAltitude: 51,
      longitude: 120.39259,
      latitude: 22.545065,
      countyName: '高雄市',
      stationAddress: '小港區水庫段96-1地號（鳳山給水廠內）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1E711',
      stationName: '馬拉邦山',
      stationNameEN: 'Malabangshan',
      stationAltitude: 990,
      longitude: 120.90166,
      latitude: 24.367306,
      countyName: '苗栗縣',
      stationAddress: '大湖鄉東興村(馬拉邦山登山口)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0V210',
      stationName: '復興',
      stationNameEN: 'Fuxing',
      stationAltitude: 734,
      longitude: 120.80609,
      latitude: 23.222403,
      countyName: '高雄市',
      stationAddress: '桃源區復興里復興巷1鄰15號',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0A570',
      stationName: '桶後',
      stationNameEN: 'Tonghou',
      stationAltitude: 360,
      longitude: 121.59799,
      latitude: 24.848223,
      countyName: '新北市',
      stationAddress: '烏來區后桶街49號孝義里5-6k處路旁',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0M650',
      stationName: '朴子',
      stationNameEN: 'Puzi',
      stationAltitude: 20,
      longitude: 120.23902,
      latitude: 23.43463,
      countyName: '嘉義縣',
      stationAddress: '朴子市竹村里36號(竹村國小)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0O830',
      stationName: '北寮',
      stationNameEN: 'Beiliao',
      stationAltitude: 127,
      longitude: 120.49501,
      latitude: 23.079561,
      countyName: '臺南市',
      stationAddress: '南化區北寮里48-4號',
      startDate: '2013-04-18',
    },
    {
      stationID: 'C0M410',
      stationName: '馬頭山',
      stationNameEN: 'Matoushan',
      stationAltitude: 245,
      longitude: 120.582016,
      latitude: 23.324362,
      countyName: '嘉義縣',
      stationAddress: '大埔鄉永樂村1鄰內灣仔2號',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0K470',
      stationName: '林內',
      stationNameEN: 'Linnei',
      stationAltitude: 97,
      longitude: 120.60964,
      latitude: 23.748674,
      countyName: '雲林縣',
      stationAddress: '林內鄉中正路685號(林內消防分隊)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1E721',
      stationName: '泰安',
      stationNameEN: 'Tai-an',
      stationAltitude: 1409,
      longitude: 120.96803,
      latitude: 24.489017,
      countyName: '苗栗縣',
      stationAddress: '泰安鄉橫龍古道上(走路約5分鐘)之大湖事業區18林班地',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0F9P0',
      stationName: '清水',
      stationNameEN: 'Qingshui',
      stationAltitude: 26,
      longitude: 120.56224,
      latitude: 24.312298,
      countyName: '臺中市',
      stationAddress: '清水區護岸路37號(高美國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0AD40',
      stationName: '土城',
      stationNameEN: 'Tucheng',
      stationAltitude: 32,
      longitude: 121.44517,
      latitude: 24.973207,
      countyName: '新北市',
      stationAddress: '土城區金城路一段90號(新北市政府消防局土城分隊大樓樓頂)',
      startDate: '2009-11-01',
    },
    {
      stationID: 'C1D630',
      stationName: '飛鳳山',
      stationNameEN: 'Fei Feng Mountain',
      stationAltitude: 462,
      longitude: 121.11918,
      latitude: 24.766464,
      countyName: '新竹縣',
      stationAddress: '芎林鄉王爺坑段83-7號地',
      startDate: '2011-12-01',
    },
    {
      stationID: 'C0F9R0',
      stationName: '龍井',
      stationNameEN: 'Longjing',
      stationAltitude: 20,
      longitude: 120.52897,
      latitude: 24.184536,
      countyName: '臺中市',
      stationAddress: '龍井區西里龍門路51號(龍井國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0K240',
      stationName: '草嶺',
      stationNameEN: 'Caoling',
      stationAltitude: 1132,
      longitude: 120.69346,
      latitude: 23.59555,
      countyName: '雲林縣',
      stationAddress: '古坑鄉草嶺村龍頭',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0M660',
      stationName: '溪口',
      stationNameEN: 'Xikou',
      stationAltitude: 40,
      longitude: 120.40357,
      latitude: 23.604132,
      countyName: '嘉義縣',
      stationAddress: '溪口鄉中正東路103號(溪口國中學生活動中心)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0O840',
      stationName: '王爺宮',
      stationNameEN: 'Wangyegong',
      stationAltitude: 134,
      longitude: 120.40085,
      latitude: 23.222063,
      countyName: '臺南市',
      stationAddress: '六甲區王爺里24號（六甲國小湖東分校）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0K480',
      stationName: '莿桐',
      stationNameEN: 'Citong',
      stationAltitude: 56,
      longitude: 120.50237,
      latitude: 23.761192,
      countyName: '雲林縣',
      stationAddress: '莿桐鄉和平路53號(莿桐鄉公所)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1E511',
      stationName: '新開',
      stationNameEN: 'Xinkai',
      stationAltitude: 326,
      longitude: 120.82702,
      latitude: 24.347704,
      countyName: '苗栗縣',
      stationAddress: '大湖鄉新開村新開4鄰20號旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1D420',
      stationName: '太閣南',
      stationNameEN: 'Taigenan',
      stationAltitude: 1279,
      longitude: 121.160576,
      latitude: 24.632809,
      countyName: '新竹縣',
      stationAddress: '五峰鄉羅山工作站後方山頂羅山瞭望台旁',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C0AD50',
      stationName: '鶯歌',
      stationNameEN: 'Yingge',
      stationAltitude: 84,
      longitude: 121.34629,
      latitude: 24.951532,
      countyName: '新北市',
      stationAddress: '鶯歌區尖山埔路106號(鶯歌國小內)',
      startDate: '2009-11-01',
    },
    {
      stationID: 'C1F9W0',
      stationName: '德基',
      stationNameEN: 'Deji',
      stationAltitude: 1970,
      longitude: 121.19026,
      latitude: 24.254784,
      countyName: '臺中市',
      stationAddress: '和平區台8甲線69-70k處，彎入勝利路接翠巒產業道路約6.2km，路邊白色水塔旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0F9S0',
      stationName: '烏日',
      stationNameEN: 'Wuri',
      stationAltitude: 43,
      longitude: 120.6241,
      latitude: 24.107058,
      countyName: '臺中市',
      stationAddress: '烏日區中山路二段196號(烏日國小)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C1O880',
      stationName: '關山',
      stationNameEN: 'Guanshan',
      stationAltitude: 223,
      longitude: 120.59409,
      latitude: 23.173397,
      countyName: '臺南市',
      stationAddress: '南化區關山里西阿里關83號(瑞峰國小附近)',
      startDate: '1988-12-01',
    },
    {
      stationID: 'C0M670',
      stationName: '大林',
      stationNameEN: 'Dalin',
      stationAltitude: 43,
      longitude: 120.45841,
      latitude: 23.600616,
      countyName: '嘉義縣',
      stationAddress: '大林鎮新興街110號(大林地政事務所)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0K250',
      stationName: '崙背',
      stationNameEN: 'Lunbei',
      stationAltitude: 12,
      longitude: 120.318924,
      latitude: 23.75555,
      countyName: '雲林縣',
      stationAddress: '崙背鄉阿勸村阿勸農場(阿勸段487號)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0K490',
      stationName: '古坑',
      stationNameEN: 'Gukeng',
      stationAltitude: 91,
      longitude: 120.55975,
      latitude: 23.6543,
      countyName: '雲林縣',
      stationAddress: '古坑鄉中山路393號(古坑消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1D410',
      stationName: '白蘭',
      stationNameEN: 'Bailan',
      stationAltitude: 1290,
      longitude: 121.07995,
      latitude: 24.579365,
      countyName: '新竹縣',
      stationAddress: '五峰鄉桃山村',
      startDate: '1987-06-01',
    },
    {
      stationID: 'C1H9B1',
      stationName: '阿眉',
      stationNameEN: 'Amei',
      stationAltitude: 1710,
      longitude: 120.994675,
      latitude: 24.1263,
      countyName: '南投縣',
      stationAddress: '仁愛鄉(水利局雨量站旁)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0U970',
      stationName: '大福',
      stationNameEN: 'Dafu',
      stationAltitude: 7,
      longitude: 121.81389,
      latitude: 24.80064,
      countyName: '宜蘭縣',
      stationAddress: '壯圍鄉大福路一段62巷26號(大福國小)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0V820',
      stationName: '小林',
      stationNameEN: 'Xiaolin',
      stationAltitude: 441,
      longitude: 120.63325,
      latitude: 23.148409,
      countyName: '高雄市',
      stationAddress: '甲仙區五里路24-2號（小林國小）',
      startDate: '2018-12-20',
    },
    {
      stationID: 'C0R690',
      stationName: '墾丁',
      stationNameEN: 'Kenting',
      stationAltitude: 348,
      longitude: 120.81027,
      latitude: 21.966757,
      countyName: '屏東縣',
      stationAddress: '恆春鎮墾丁森林遊樂區林業試驗所觀測坪',
      startDate: '2019-11-28',
    },
    {
      stationID: 'C0Z190',
      stationName: '富世',
      stationNameEN: 'Fushi',
      stationAltitude: 62,
      longitude: 121.6297,
      latitude: 24.14743,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉富世村127號(富世國小校園內)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C1I450',
      stationName: '望鄉山',
      stationNameEN: 'Wangxiangshan',
      stationAltitude: 3025,
      longitude: 120.94428,
      latitude: 23.594152,
      countyName: '南投縣',
      stationAddress: '信義鄉巒大事業區第131林班內(郡大林道約32K附近，望鄉山三角點旁)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1I211',
      stationName: '蘆竹湳',
      stationNameEN: 'Luzhunan',
      stationAltitude: 529,
      longitude: 120.812,
      latitude: 23.93402,
      countyName: '南投縣',
      stationAddress: '中寮鄉清水村龍林巷4鄰58之6號旁',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0AH00',
      stationName: '汐止',
      stationNameEN: 'Xizhi',
      stationAltitude: 38,
      longitude: 121.658775,
      latitude: 25.066881,
      countyName: '新北市',
      stationAddress: '汐止區大同路二段394號(汐止國中)',
      startDate: '2011-09-01',
    },
    {
      stationID: 'C1R250',
      stationName: '力里',
      stationNameEN: 'Lili',
      stationAltitude: 92,
      longitude: 120.62923,
      latitude: 22.428118,
      countyName: '屏東縣',
      stationAddress: '春日鄉七佳村自強一路92號(力里國民小學內)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C0T870',
      stationName: '鯉魚潭',
      stationNameEN: 'Liyutan',
      stationAltitude: 135,
      longitude: 121.50894,
      latitude: 23.935638,
      countyName: '花蓮縣',
      stationAddress: '壽豐鄉池南村池南路一段8號(鯉魚潭風景區旁)',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0U960',
      stationName: '翠峰湖',
      stationNameEN: 'Cuifenghu',
      stationAltitude: 1885,
      longitude: 121.60805,
      latitude: 24.510857,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉太平山翠峰湖停車場木棧道旁',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0I410',
      stationName: '名間',
      stationNameEN: 'Mingjian',
      stationAltitude: 180,
      longitude: 120.70145,
      latitude: 23.837053,
      countyName: '南投縣',
      stationAddress: '名間鄉董門巷2號(名間鄉立體停車場)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1F9G1',
      stationName: '慶福山',
      stationNameEN: 'Cingfushan',
      stationAltitude: 810,
      longitude: 120.84838,
      latitude: 24.180853,
      countyName: '臺中市',
      stationAddress: '東勢區(台8線10.5k往馬鞍農場方向.車程約半小時)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0U720',
      stationName: '南山',
      stationNameEN: 'Nanshan',
      stationAltitude: 1260,
      longitude: 121.38167,
      latitude: 24.43742,
      countyName: '宜蘭縣',
      stationAddress: '大同鄉南山村4鄰埤南巷31-3號(南山國民小學內)',
      startDate: '2018-03-23',
    },
    {
      stationID: 'C0V810',
      stationName: '左營',
      stationNameEN: 'Zuoying',
      stationAltitude: 32,
      longitude: 120.28491,
      latitude: 22.67491,
      countyName: '高雄市',
      stationAddress: '左營區左營大路1號(高雄市立海青高級工商職業學校資訊大樓樓頂)',
      startDate: '2015-09-21',
    },
    {
      stationID: 'C1I201',
      stationName: '中心崙',
      stationNameEN: 'Zhongxinlun',
      stationAltitude: 677,
      longitude: 120.7121,
      latitude: 23.728722,
      countyName: '南投縣',
      stationAddress: '竹山鎮之中心崙林道4.1km處',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0A920',
      stationName: '富貴角',
      stationNameEN: 'Fugueijiao',
      stationAltitude: 196,
      longitude: 121.565254,
      latitude: 25.263783,
      countyName: '新北市',
      stationAddress: '石門區山溪里10鄰九芎林13號旁(石門國小山溪分班附近)',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0R220',
      stationName: '潮州',
      stationNameEN: 'Chaojhou',
      stationAltitude: 23,
      longitude: 120.54,
      latitude: 22.53436,
      countyName: '屏東縣',
      stationAddress: '潮州鎮光春路290號(屏東縣立光春國民中學內)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0S790',
      stationName: '金崙',
      stationNameEN: 'Jinlun',
      stationAltitude: 236,
      longitude: 120.96687,
      latitude: 22.538122,
      countyName: '臺東縣',
      stationAddress: '太麻里鄉金崙段0304-1地號',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C1I440',
      stationName: '新高口',
      stationNameEN: 'Xingaokou',
      stationAltitude: 2540,
      longitude: 120.87894,
      latitude: 23.478745,
      countyName: '南投縣',
      stationAddress: '信義鄉新高口(臺18線約107.5k處路旁，對高岳營林區第31林班446號林地內)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0Z180',
      stationName: '新城',
      stationNameEN: 'Xincheng',
      stationAltitude: 34,
      longitude: 121.60454,
      latitude: 24.03946,
      countyName: '花蓮縣',
      stationAddress: '新城鄉大漢村光復路570號(新城鄉公所)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0AH10',
      stationName: '永和',
      stationNameEN: 'Yonghe',
      stationAltitude: 30,
      longitude: 121.50811,
      latitude: 25.01125,
      countyName: '新北市',
      stationAddress: '永和區永平路205號(永平高中校園內)',
      startDate: '2014-01-01',
    },
    {
      stationID: 'C0U990',
      stationName: '員山',
      stationNameEN: 'Yuanshan',
      stationAltitude: 31,
      longitude: 121.72247,
      latitude: 24.74435,
      countyName: '宜蘭縣',
      stationAddress: '員山鄉員山村復興路39號(員山國小)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0U750',
      stationName: '龜山島',
      stationNameEN: 'Gueishandao',
      stationAltitude: 398,
      longitude: 121.95313,
      latitude: 24.841667,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮龜山島山頂401高地觀景台',
      startDate: '2002-09-13',
    },
    {
      stationID: 'C0I420',
      stationName: '國姓',
      stationNameEN: 'Guoxing',
      stationAltitude: 305,
      longitude: 120.85507,
      latitude: 24.037804,
      countyName: '南投縣',
      stationAddress: '國姓鄉民族街40號(國姓鄉老人文康活動中心)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1F9J1',
      stationName: '清水林',
      stationNameEN: 'Qingshuilin',
      stationAltitude: 723,
      longitude: 120.82042,
      latitude: 24.071241,
      countyName: '臺中市',
      stationAddress: '太平區136縣道9.5k處旁約500公尺',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0A931',
      stationName: '三和',
      stationNameEN: 'Sanhe',
      stationAltitude: 216,
      longitude: 121.59524,
      latitude: 25.233152,
      countyName: '新北市',
      stationAddress: '金山區三和國小兩湖分校',
      startDate: '2014-04-01',
    },
    {
      stationID: 'C1TA00',
      stationName: '太魯閣',
      stationNameEN: 'Taroko',
      stationAltitude: 94,
      longitude: 121.6236,
      latitude: 24.15773,
      countyName: '花蓮縣',
      stationAddress: '秀林鄉富世291號（太魯閣國家公園管理處內）',
      startDate: '2018-08-06',
    },
    {
      stationID: 'C0R470',
      stationName: '高樹',
      stationNameEN: 'Gaoshu',
      stationAltitude: 103,
      longitude: 120.60081,
      latitude: 22.82639,
      countyName: '屏東縣',
      stationAddress: '高樹鄉興中路330號(高樹圖書館)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1A750',
      stationName: '石碇服務區',
      stationNameEN: 'Shiding Service Area',
      stationAltitude: 92,
      longitude: 121.64624,
      latitude: 25.00872,
      countyName: '新北市',
      stationAddress: '新北市石碇區文山路一段2號',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C1I470',
      stationName: '杉林溪',
      stationNameEN: 'Shanlinxi',
      stationAltitude: 1680,
      longitude: 120.786446,
      latitude: 23.639517,
      countyName: '南投縣',
      stationAddress: '竹山鎮大鞍里(杉林溪森林生態渡假園區收費亭附近，向欣谷碑旁)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C1I230',
      stationName: '九份二山',
      stationNameEN: "Jiufen'ershan",
      stationAltitude: 837,
      longitude: 120.845,
      latitude: 23.961945,
      countyName: '南投縣',
      stationAddress: '國姓鄉南港村九份二山上(921地震紀念公園內)',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0U980',
      stationName: '坪林石牌',
      stationNameEN: 'Shipai Pinglin',
      stationAltitude: 573,
      longitude: 121.77396,
      latitude: 24.86478,
      countyName: '宜蘭縣',
      stationAddress: '頭城鎮與新北市坪林區交界附近之國有林班地(位於坪林石牌附近山區)',
      startDate: '2017-12-01',
    },
    {
      stationID: 'C0A940',
      stationName: '金山',
      stationNameEN: 'Jinshan',
      stationAltitude: 49,
      longitude: 121.64397,
      latitude: 25.223627,
      countyName: '新北市',
      stationAddress: '金山區中興段404地號(磺港社區活動中心內)',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0R240',
      stationName: '來義',
      stationNameEN: 'Laiyi',
      stationAltitude: 87,
      longitude: 120.625114,
      latitude: 22.527306,
      countyName: '屏東縣',
      stationAddress: '萬巒鄉新隆路64號萬巒農場（佳佐段180號）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0R480',
      stationName: '長治',
      stationNameEN: 'Changzhi',
      stationAltitude: 57,
      longitude: 120.542625,
      latitude: 22.693129,
      countyName: '屏東縣',
      stationAddress: '長治鄉德成村中興路572-1號(長治消防分隊)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1I220',
      stationName: '樟湖',
      stationNameEN: 'Zhanghu',
      stationAltitude: 1098,
      longitude: 120.846985,
      latitude: 23.905333,
      countyName: '南投縣',
      stationAddress: '國姓鄉147縣道9km處與站址直線距離約1.6km',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0M800',
      stationName: '茶山',
      stationNameEN: 'Chashan',
      stationAltitude: 440,
      longitude: 120.664764,
      latitude: 23.299074,
      countyName: '嘉義縣',
      stationAddress: '阿里山鄉茶山路62號之1(茶山村衛生室)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0AH30',
      stationName: '五分山',
      stationNameEN: 'Wufengshan',
      stationAltitude: 755,
      longitude: 121.78079,
      latitude: 25.07125,
      countyName: '新北市',
      stationAddress: '瑞芳區靜安路四段1巷1號',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0U770',
      stationName: '南澳',
      stationNameEN: 'Nanao',
      stationAltitude: 5,
      longitude: 121.81022,
      latitude: 24.44935,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉中央氣象局地震中心系統維護課之南澳地震站',
      startDate: '2005-10-01',
    },
    {
      stationID: 'C0V620',
      stationName: '永安',
      stationNameEN: "Yong'an",
      stationAltitude: 18,
      longitude: 120.236855,
      latitude: 22.82315,
      countyName: '高雄市',
      stationAddress: '永安區永安路32號(永安消防分隊)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0A950',
      stationName: '鼻頭角',
      stationNameEN: 'Bitoujiao',
      stationAltitude: 60,
      longitude: 121.92337,
      latitude: 25.129036,
      countyName: '新北市',
      stationAddress: '瑞芳區鼻頭角燈塔旁',
      startDate: '1995-01-01',
    },
    {
      stationID: 'C0H9A0',
      stationName: '神木村',
      stationNameEN: 'Shenmu Village',
      stationAltitude: 1595,
      longitude: 120.843666,
      latitude: 23.532742,
      countyName: '南投縣',
      stationAddress: '信義鄉神木村神木工作分站後方山坡上(對高岳營林區29林班地444號土地)',
      startDate: '1999-02-01',
    },
    {
      stationID: 'C0R490',
      stationName: '九如',
      stationNameEN: 'Jiuru',
      stationAltitude: 49,
      longitude: 120.4905,
      latitude: 22.7405,
      countyName: '屏東縣',
      stationAddress: '九如鄉九如路二段416號(九如鄉公所)',
      startDate: '2013-08-01',
    },
    {
      stationID: 'C1I250',
      stationName: '鯉潭',
      stationNameEN: 'Litan',
      stationAltitude: 732,
      longitude: 120.991135,
      latitude: 23.961922,
      countyName: '南投縣',
      stationAddress: '埔里鎮埔里高工旁中心路2km處',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1M610',
      stationName: '石磐龍',
      stationNameEN: 'Shipanlong',
      stationAltitude: 1083,
      longitude: 120.663506,
      latitude: 23.496607,
      countyName: '嘉義縣',
      stationAddress: '竹崎鄉光華村',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0AH40',
      stationName: '平等',
      stationNameEN: 'Pingdeng',
      stationAltitude: 426,
      longitude: 121.57709,
      latitude: 25.129143,
      countyName: '臺北市',
      stationAddress: '士林區平菁街101號(平等國小)',
      startDate: '2016-08-01',
    },
    {
      stationID: 'C1R290',
      stationName: '石門山',
      stationNameEN: 'Shihmenshan',
      stationAltitude: 260,
      longitude: 120.7566,
      latitude: 22.112612,
      countyName: '屏東縣',
      stationAddress: '牡丹鄉石門古戰場(恆春事業區29林班地)',
      startDate: '1997-12-20',
    },
    {
      stationID: 'C0A970',
      stationName: '三貂角',
      stationNameEN: 'Sandiaojiao',
      stationAltitude: 96,
      longitude: 122.00206,
      latitude: 25.007607,
      countyName: '新北市',
      stationAddress: '貢寮區三貂角燈塔旁',
      startDate: '1996-01-01',
    },
    {
      stationID: 'C0U760',
      stationName: '東澳',
      stationNameEN: 'Dong-ao',
      stationAltitude: 22,
      longitude: 121.83318,
      latitude: 24.52196,
      countyName: '宜蘭縣',
      stationAddress: '南澳鄉東岳村蘇花路三段209號(東澳國小校園內)',
      startDate: '2004-06-01',
    },
    {
      stationID: 'C0M810',
      stationName: '里佳',
      stationNameEN: 'Lijia',
      stationAltitude: 1354,
      longitude: 120.716644,
      latitude: 23.389126,
      countyName: '嘉義縣',
      stationAddress: '阿里山鄉里佳村',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0U520',
      stationName: '雙連埤',
      stationNameEN: 'Shuanglianpi',
      stationAltitude: 517,
      longitude: 121.641304,
      latitude: 24.753033,
      countyName: '宜蘭縣',
      stationAddress: '員山鄉雙連埤地區(需徒步20分鐘)',
      startDate: '2010-07-01',
    },
    {
      stationID: 'C0V610',
      stationName: '梓官',
      stationNameEN: 'Ziguan',
      stationAltitude: 22,
      longitude: 120.26721,
      latitude: 22.760557,
      countyName: '高雄市',
      stationAddress: '梓官區梓官路258號(梓官區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0R260',
      stationName: '春日',
      stationNameEN: 'Chunri',
      stationAltitude: 76,
      longitude: 120.6283,
      latitude: 22.370361,
      countyName: '屏東縣',
      stationAddress: '春日鄉鄉公所資源回收場旁',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C1I240',
      stationName: '外大坪',
      stationNameEN: 'Waidaping',
      stationAltitude: 725,
      longitude: 120.91793,
      latitude: 23.958696,
      countyName: '南投縣',
      stationAddress: '埔里鎮種瓜路(投68)路口至站址車程約20分鐘',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1A760',
      stationName: '坪林交控',
      stationNameEN: 'Pinglin Traffic Control Center',
      stationAltitude: 217,
      longitude: 121.71344,
      latitude: 24.94284,
      countyName: '新北市',
      stationAddress: '新北市坪林區坪雙路一段52號',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C1M600',
      stationName: '頭凍',
      stationNameEN: 'Toudong',
      stationAltitude: 986,
      longitude: 120.60334,
      latitude: 23.406364,
      countyName: '嘉義縣',
      stationAddress: '番路鄉阿里山公路23k處轉入興社產業道路盡頭',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C1E900',
      stationName: '國一N128K',
      stationNameEN: 'Freeway No. 1 - RAIN – N128K',
      stationAltitude: 80,
      longitude: 120.844185,
      latitude: 24.560997,
      countyName: '苗栗縣',
      stationAddress: '國道1號北向128K',
      startDate: '2021-10-02',
    },
    {
      stationID: 'C1V200',
      stationName: '梅山',
      stationNameEN: 'Meishan',
      stationAltitude: 870,
      longitude: 120.823555,
      latitude: 23.268448,
      countyName: '高雄市',
      stationAddress: '桃源區梅山里(梅山口遊客中心旁道路步行約10分鐘)',
      startDate: '1992-05-01',
    },
    {
      stationID: 'C0V640',
      stationName: '湖內',
      stationNameEN: 'Hunei',
      stationAltitude: 35,
      longitude: 120.2445,
      latitude: 22.887138,
      countyName: '高雄市',
      stationAddress: '湖內區中山路一段290之1號(湖街派出所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0I460',
      stationName: '南投',
      stationNameEN: 'Nantou',
      stationAltitude: 110,
      longitude: 120.68733,
      latitude: 23.911469,
      countyName: '南投縣',
      stationAddress: '南投市龍泉里玉井街5號(南投市公所樓頂)',
      startDate: '2015-09-21',
    },
    {
      stationID: 'C0A980',
      stationName: '社子',
      stationNameEN: 'Shezih',
      stationAltitude: 11,
      longitude: 121.46968,
      latitude: 25.109509,
      countyName: '臺北市',
      stationAddress: '士林區延平北路九段212號(臺北海洋科技大學內)',
      startDate: '1997-12-01',
    },
    {
      stationID: 'C0M820',
      stationName: '達邦',
      stationNameEN: 'Dabang',
      stationAltitude: 980,
      longitude: 120.74952,
      latitude: 23.453995,
      countyName: '嘉義縣',
      stationAddress: '阿里山鄉達邦村21號(鄒族自然與文化中心)',
      startDate: '2016-01-01',
    },
    {
      stationID: 'C0V400',
      stationName: '阿公店',
      stationNameEN: 'Agongdian',
      stationAltitude: 56,
      longitude: 120.35553,
      latitude: 22.804193,
      countyName: '高雄市',
      stationAddress: '燕巢區西燕里工程路1號（阿公店水庫內）',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0K400',
      stationName: '斗六',
      stationNameEN: 'Douliu',
      stationAltitude: 65,
      longitude: 120.54113,
      latitude: 23.720583,
      countyName: '雲林縣',
      stationAddress: '斗六市鎮北路345-1號(斗六消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C0H9C0',
      stationName: '合歡山',
      stationNameEN: 'Hehuan Mountain',
      stationAltitude: 3402,
      longitude: 121.272545,
      latitude: 24.143442,
      countyName: '南投縣',
      stationAddress: '仁愛鄉(近合歡山三角點，由松雪樓開車約15分鐘，大甲溪事業區第81林班)',
      startDate: '2008-02-25',
    },
    {
      stationID: 'C0AH50',
      stationName: '林口',
      stationNameEN: 'Linkou',
      stationAltitude: 275,
      longitude: 121.38084,
      latitude: 25.072182,
      countyName: '新北市',
      stationAddress: '林口區仁愛路二段173號(林口高中內)',
      startDate: '2017-01-10',
    },
    {
      stationID: 'C0R270',
      stationName: '琉球嶼',
      stationNameEN: 'Liouciouyu',
      stationAltitude: 51,
      longitude: 120.362236,
      latitude: 22.332245,
      countyName: '屏東縣',
      stationAddress: '琉球鄉天台段596及597地號',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C1M870',
      stationName: '國三N285K',
      stationNameEN: 'Freeway No. 3 - RAIN –N285K',
      stationAltitude: 121,
      longitude: 120.50584,
      latitude: 23.55032,
      countyName: '嘉義縣',
      stationAddress: '國道3號北向285K',
      startDate: '2021-01-26',
    },
    {
      stationID: 'C1I030',
      stationName: '武界',
      stationNameEN: 'Wujie',
      stationAltitude: 948,
      longitude: 121.05248,
      latitude: 23.913225,
      countyName: '南投縣',
      stationAddress: '仁愛鄉法治村',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C0F9I0',
      stationName: '神岡',
      stationNameEN: 'Shengang',
      stationAltitude: 194,
      longitude: 120.65832,
      latitude: 24.272482,
      countyName: '臺中市',
      stationAddress: '神岡區三民路639號(圳堵國小校園南側大樓屋頂平台)',
      startDate: '2011-11-01',
    },
    {
      stationID: 'C0U780',
      stationName: '五結',
      stationNameEN: 'Wujie',
      stationAltitude: 15,
      longitude: 121.79794,
      latitude: 24.68637,
      countyName: '宜蘭縣',
      stationAddress: '五結鄉五結中路二段88號(五結國小)',
      startDate: '2010-07-01',
    },
    {
      stationID: 'C0V630',
      stationName: '茄萣',
      stationNameEN: 'Qieding',
      stationAltitude: 19,
      longitude: 120.18261,
      latitude: 22.906643,
      countyName: '高雄市',
      stationAddress: '茄萣區濱海路四段27號(茄萣區公所)',
      startDate: '2013-12-01',
    },
    {
      stationID: 'C0K410',
      stationName: '北港',
      stationNameEN: 'Beigang',
      stationAltitude: 20,
      longitude: 120.2928,
      latitude: 23.573957,
      countyName: '雲林縣',
      stationAddress: '北港鎮大同路565號(北港消防分隊)',
      startDate: '2015-07-03',
    },
    {
      stationID: 'C1I260',
      stationName: '北坑',
      stationNameEN: 'Beikeng',
      stationAltitude: 601,
      longitude: 121.006905,
      latitude: 23.9246,
      countyName: '南投縣',
      stationAddress: '埔里鎮麒麟里4鄰武界路31號旁',
      startDate: '2002-08-01',
    },
    {
      stationID: 'C0R280',
      stationName: '檳榔',
      stationNameEN: 'Binlang',
      stationAltitude: 242,
      longitude: 120.83678,
      latitude: 22.076078,
      countyName: '屏東縣',
      stationAddress: '滿州鄉長樂村大公路14號(往南仁山方向)',
      startDate: '1997-07-01',
    },
    {
      stationID: 'C1I020',
      stationName: '萬大',
      stationNameEN: 'Wanda',
      stationAltitude: 1120,
      longitude: 121.13226,
      latitude: 23.979816,
      countyName: '南投縣',
      stationAddress: '仁愛鄉親愛村萬大水庫宿舍旁',
      startDate: '1992-07-01',
    },
    {
      stationID: 'C1M620',
      stationName: '瑞里',
      stationNameEN: 'Ruili',
      stationAltitude: 1252,
      longitude: 120.67519,
      latitude: 23.545134,
      countyName: '嘉義縣',
      stationAddress: '梅山鄉瑞里派出所旁小路至站址(公墓旁)車程約10分鐘',
      startDate: '2002-08-01',
    },
  ],
};
