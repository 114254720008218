<template>
  <admin-main-layout>
    <template slot="toolbar">
      <el-row class="no-margin">
        <h1>{{ $t("page.device.title") }}</h1>
      </el-row>
    </template>

    <div class="drone-list-container">
      <div
        v-if="source.items.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>{{ $t("page.device.noDrone.title") }}</h3>
          <p>{{ $t("page.device.noDrone.content") }}</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="dialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row
          class="section-button-row"
          type="flex"
          align="bottom"
          justify="end"
        >
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="dialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </el-row>
        <el-row
          class="section-label-row"
          type="flex"
          align="bottom"
          justify="start"
        >
          <div class="section-label">
            {{ $t("page.device.allDrones") }} ({{ source.items.length }})
          </div>
        </el-row>

        <div
          v-if="droneMq.length > 0"
          class="drones-selection"
        >
          <div class="drones">
            <div
              v-for="(drone, index) in drones"
              :key="`drone-${drone.id}`"
              :class="`drone`"
            >
              <div v-if="droneMq[index].timeout">
                <img
                  :key="`drone-img-${index}`"
                  :class="
                    `thumbnail ${drone.inactive ? 'inactive' : droneMq[index].timeout.alive ? 'online' : 'opacity'}`
                  "
                  src="/asserts/icon/devices-drone-icon.svg"
                  @click="viewProfile(drone.id, index)"
                >
                <span @click="dialogOpen(drone.id, index)">{{ drone.name }}</span>
                <span
                  class="button"
                  @click="dialogOpen(drone.id, index)"
                >{{ $t("button.edit") }}</span>
                <img
                  class="select"
                  src="/asserts/icon/loadmission-selected-icon.svg"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <default-dialog
        :title="droneMode == 'add' ? $t('page.device.dialog.addTitle') : $t('page.device.dialog.updateTitle')"
        :show-dialog="droneShow"
        width="651px"
        :next-button-text="droneMode == 'add' ? $t('button.create') : $t('button.save')"
        :footer-align="droneMode == 'add' ? 'center' : 'right'"
        @open="on_open_drone"
        @close="dialogClose"
        @next="dialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="drone"
            label-width="120px"
          >
            <el-form-item
              v-if="selectedDrone >= 0"
              :class="
                `${
                  drone.inactive
                    ? 'iconInactiveDrone'
                    : droneMq[selectedDrone].timeout.alive
                      ? 'iconDrone'
                      : 'iconOfflineDrone'
                }`
              "
            >
              <el-input
                v-model="drone.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <el-form-item
              v-else
              class="iconDrone"
            >
              <el-input
                v-model="drone.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <hr style="margin-bottom: 35px;">

            <el-form-item :label="$t('page.device.dialog.inActive')">
              <el-switch
                v-model="selectDroneActive"
                class="action-button coretronic"
              />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.mac')">
              <el-input
                v-model="drone.address"
                :disabled="droneMode == 'update'"
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.type')">
              <el-input
                disabled
                :value="$t(`page.device.dialog.${drone.type}`)"
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.model')">
              <el-select
                v-model="drone.model"
                @change="change_drone_model"
              >
                <el-option
                  v-for="(modes, index) in model"
                  :key="`${modes.id}-${index}`"
                  :label="modes.name"
                  :value="modes.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.gimbal') + ' 1'">
              <el-select
                v-model="drone.gimbal1"
                :disabled="!gimbal1Enabled"
              >
                <el-option
                  v-for="(gimbal, index) in gimbals"
                  :key="`${gimbal.id}-${index}`"
                  :label="gimbal.name"
                  :value="gimbal.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.gimbal') + ' 2'">
              <el-select
                v-model="drone.gimbal2"
                :disabled="!gimbal2Enabled"
              >
                <el-option
                  v-for="(gimbal, index) in gimbals"
                  :key="`${gimbal.id}-${index}`"
                  :label="gimbal.name"
                  :value="gimbal.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="droneMode == 'update'"
              :label="$t('page.device.dialog.location')"
            >
              <el-select
                v-model="newDroneGroupId"
                :placeholder="$t('page.device.dialog.moveTo')"
              >
                <el-option
                  v-for="group in groups.filter(g => g.id !== groupId)"
                  :key="group.id"
                  :label="group.name"
                  :value="group.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                class="main-actions right-button adv"
                type="main"
                @click="advDialogOpen"
              >
                {{ $t("page.device.dialog.advTitle") }}
              </el-button>
            </el-form-item>
          </el-form>
        </template>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="droneMode == 'update'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="deleteShow = true"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </default-dialog>
      <default-dialog
        :title="$t('page.device.dialog.advTitle')"
        :show-dialog="advShow"
        width="651px"
        :next-button-text="$t('button.ok')"
        footer-align="center"
        @close="advDialogClose"
        @next="advDialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="drone"
            label-width="120px"
          >
            <el-form-item
              v-if="droneMode !== 'add'"
              :label="$t('page.device.dialog.powerOff')"
            >
              <el-switch
                v-model="power"
                class="action-button coretronic"
                :disabled="powerOffDisable"
                @change="
                  showPowerOffConfirm = true;
                  selectedDroneName = drone.name;
                "
              />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.c_rgb_push')">
              <el-input
                v-if="drone.custom_rgb_url"
                v-model="drone.custom_rgb_url.push_url"
                value=""
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.c_rgb_pop')">
              <el-input
                v-if="drone.custom_rgb_url"
                v-model="drone.custom_rgb_url.pop_url"
                value=""
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.c_thermal_push')">
              <el-input
                v-if="drone.custom_thermal_url"
                v-model="drone.custom_thermal_url.push_url"
                value=""
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.c_thermal_pop')">
              <el-input
                v-if="drone.custom_thermal_url"
                v-model="drone.custom_thermal_url.pop_url"
                value=""
              />
            </el-form-item>
          </el-form>
        </template>
      </default-dialog>
      <el-dialog
        class="delete"
        :visible.sync="deleteShow"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t("page.device.deleteConfirm.title") }}</h2>
          <p>{{ $t("page.device.deleteConfirm.body") }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="deleteClose"
          >{{ $t("button.cancel") }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="deleteAction"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </el-dialog>
      <default-dialog
        v-if="showPowerOffConfirm"
        :show-dialog="true"
        :has-cancel="false"
        :next-button-text="$t('button.yes')"
        :center="true"
        width="558px"
        footer-align="center"
        @next="powerOff"
      >
        <template slot="main">
          <div class="notification-body">
            {{
              $t("validation.droneName", {
                droneName: drone.name
              })
            }}
          </div>
        </template>
        <template slot="footer">
          <el-button
            class="main-actions"
            type="info"
            @click="
              showPowerOffConfirm = false;
              power = true;
            "
          >
            {{ $t("button.no") }}
          </el-button>
        </template>
      </default-dialog>
    </div>

    <div class="nest-list-container marign-top_80">
      <div
        v-if="source.nestItems.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>{{ $t("page.device.noNest.title") }}</h3>
          <p>{{ $t("page.device.noNest.content") }}</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="nestDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row
          class="section-button-row"
          type="flex"
          align="bottom"
          justify="end"
        >
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="nestDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </el-row>
        <el-row
          class="section-label-row"
          type="flex"
          align="bottom"
          justify="start"
        >
          <div class="section-label">
            {{ $t("page.device.allNests") }} ({{ source.nestItems.length }})
          </div>
        </el-row>

        <div class="drones-selection">
          <div class="drones">
            <div
              v-for="(nest, index) in source.nestItems"
              :key="`nest-${index}`"
              :class="`drone`"
            >
              <img
                :class="`thumbnail ${nest.inactive ? 'inactive' : ''}`"
                src="/asserts/icon/devices-nest-icon.svg"
                @click="viewNestProfile(nest.id)"
              >
              <span @click="nestDialogOpen(nest.id)">{{ nest.name }}</span>
              <span
                class="button"
                @click="nestDialogOpen(nest.id)"
              >{{ $t("button.edit") }}</span>
              <img
                class="select"
                src="/asserts/icon/loadmission-selected-icon.svg"
              >
            </div>
          </div>
        </div>
      </div>
      <default-dialog
        :title="nestMode == 'add' ? $t('page.device.dialog.addNestTitle') : $t('page.device.dialog.updateNestTitle')"
        :show-dialog="nestShow"
        width="651px"
        :next-button-text="nestMode == 'add' ? $t('button.create') : $t('button.save')"
        :footer-align="nestMode == 'add' ? 'center' : 'right'"
        @close="nestDialogClose"
        @next="nestDialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="nest"
            label-width="120px"
          >
            <el-form-item class="iconNest">
              <el-input
                v-model="nest.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <hr style="margin-bottom: 35px;">

            <el-form-item :label="$t('page.device.dialog.inActive')">
              <el-switch
                v-model="selectNestActive"
                class="action-button coretronic"
              />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.mac')">
              <el-input
                v-model="nest.address"
                :disabled="nestMode == 'update'"
              />
            </el-form-item>

            <div class="wrapNumber">
              <el-form-item :label="$t('page.device.dialog.gpsLocation')">
                <label>{{ $t("page.device.dialog.latitude") }}</label>

                <el-input-number
                  v-model="nest.latitude"
                  size="Default"
                  :controls="false"
                  :min="-90"
                />
                <label style="margin-left:12px;">{{ $t("page.device.dialog.longitude") }}</label>
                <el-input-number
                  v-model="nest.longitude"
                  size="Default"
                  :controls="false"
                  :min="-180"
                />
                <el-button
                  class="image-button gps-set"
                  @click="handleChange('nest')"
                />
              </el-form-item>
            </div>

            <el-form-item :label="$t('map.altitude')">
              <div class="altitude-slide">
                <div class="row">
                  <el-input-number
                    v-model="nest.altitude"
                    :controls="false"
                  />
                  <Label class="with_error_hint">{{ `   ${altitudeUnitHint}` }}</Label>
                  <div class="slider">
                    <div class="slider__content">
                      <el-slider
                        v-model="nest.altitude"
                        :show-tooltip="false"
                        class="setting-altitude-slider-reverse"
                        :min="0"
                        :max="maxNestAltitude"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button
                class="main-actions right-button adv"
                type="main"
                @click="advNestDialogOpen"
              >
                {{ $t("page.device.dialog.advTitle") }}
              </el-button>
            </el-form-item>
          </el-form>
        </template>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="nestMode == 'update'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="nestDeleteShow = true"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </default-dialog>
      <default-dialog
        :title="$t('page.device.dialog.advTitle')"
        :show-dialog="advNestShow"
        width="651px"
        :next-button-text="$t('button.ok')"
        footer-align="center"
        :has-cancel="false"
        @next="advNestDialogClose"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="nest"
            label-width="120px"
          >
            <el-form-item
              :label="$t('page.device.dialog.tempLimit')"
              class="setting-slide-and-box"
            >
              <div class="location-slider-group1">
                <div class="start-number">
                  <el-input-number
                    v-model="nest.temperature[0]"
                    :controls="false"
                    @blur="handleTempChange"
                  />
                </div>
                <Label class="with_error_hint">{{ `   ${tmpUnitHint}` }}</Label>
                <div class="slider">
                  <div class="slider__content">
                    <el-slider
                      :key="nestTmpSliderKey"
                      v-model="nest.temperature"
                      range
                      :show-tooltip="false"
                      class="setting-altitude-slider"
                      :min="nestMinTmp"
                      :max="nestMaxTmp"
                    />
                    <label class="hint">{{ $t("page.device.dialog.envTemp") }}{{ minMaxTmpUnitHint }}</label>
                  </div>
                </div>
                <div class="end-number">
                  <el-input-number
                    v-model="nest.temperature[1]"
                    :controls="false"
                    @blur="handleTempChange"
                  />
                </div>
                <Label class="with_error_hint">{{ `   ${tmpUnitHint}` }}</Label>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </default-dialog>
      <el-dialog
        class="delete"
        :visible.sync="nestDeleteShow"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t("page.device.deleteConfirm.title") }}</h2>

          <p>{{ $t("page.device.deleteConfirm.nestBody") }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="nestDeleteClose"
          >{{ $t("button.cancel") }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="nestDeleteAction"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="camera-list-container marign-top_80">
      <div
        v-if="source.cameraItems.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>{{ $t("page.device.noCamera.title") }}</h3>
          <p>{{ $t("page.device.noCamera.content") }}</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="cameraDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row
          class="section-button-row"
          type="flex"
          align="bottom"
          justify="end"
        >
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="cameraDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </el-row>
        <el-row
          class="section-label-row"
          type="flex"
          align="bottom"
          justify="start"
        >
          <div class="section-label">
            {{ $t("page.device.allCameras") }} ({{ source.cameraItems.length }})
          </div>
        </el-row>

        <div class="drones-selection">
          <div class="drones">
            <div
              v-for="(camera, index) in source.cameraItems"
              :key="`camera-${index}`"
              :class="`drone`"
            >
              <img
                class="thumbnail"
                src="/asserts/icon/devices-ipcam-icon.svg"
                @click="viewCameraProfile(camera.id)"
              >
              <span @click="cameraDialogOpen(camera.id)">{{ camera.name }}</span>
              <span
                class="button"
                @click="cameraDialogOpen(camera.id)"
              >{{ $t("button.edit") }}</span>
              <img
                class="select"
                src="/asserts/icon/loadmission-selected-icon.svg"
              >
            </div>
          </div>
        </div>
      </div>
      <default-dialog
        :title="
          cameraMode == 'add' ? $t('page.device.dialog.addCameraTitle') : $t('page.device.dialog.updateCameraTitle')
        "
        :show-dialog="cameraShow"
        width="651px"
        :next-button-text="cameraMode == 'add' ? $t('button.create') : $t('button.save')"
        :footer-align="cameraMode == 'add' ? 'center' : 'right'"
        @close="cameraDialogClose"
        @next="cameraDialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="camera"
            label-width="120px"
          >
            <el-form-item class="iconCamera">
              <el-input
                v-model="camera.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <hr style="margin-bottom: 35px;">

            <el-form-item :label="$t('page.device.dialog.inActive')">
              <el-switch
                v-model="selectCameraActive"
                class="action-button coretronic"
              />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.url')">
              <el-input v-model="camera.url" />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.account')">
              <el-input v-model="camera.account" />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.password')">
              <el-input v-model="camera.password" />
            </el-form-item>

            <div class="wrapNumber">
              <el-form-item :label="$t('page.device.dialog.gpsLocation')">
                <label>{{ $t("page.device.dialog.latitude") }}</label>

                <el-input-number
                  v-model="camera.latitude"
                  size="Default"
                  :controls="false"
                  :min="-90"
                />
                <label style="margin-left:12px;">{{ $t("page.device.dialog.longitude") }}</label>
                <el-input-number
                  v-model="camera.longitude"
                  size="Default"
                  :controls="false"
                  :min="-180"
                />
                <el-button
                  class="image-button gps-set"
                  @click="handleChange('camera')"
                />
              </el-form-item>
            </div>
          </el-form>
        </template>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="cameraMode == 'update'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="cameraDeleteShow = true"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </default-dialog>
      <el-dialog
        class="delete"
        :visible.sync="cameraDeleteShow"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t("page.device.deleteConfirm.title") }}</h2>

          <p>{{ $t("page.device.deleteConfirm.cameraBody") }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="cameraDeleteClose"
          >{{ $t("button.cancel") }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="cameraDeleteAction"
          >{{
            $t("button.delete")
          }}</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="weather-list-container marign-top_80">
      <div
        v-if="source.weatherItems.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>{{ $t("page.device.noWeather.title") }}</h3>
          <p>{{ $t("page.device.noWeather.content") }}</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="weatherDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row
          class="section-button-row"
          type="flex"
          align="bottom"
          justify="end"
        >
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="weatherDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </el-row>
        <el-row
          class="section-label-row"
          type="flex"
          align="bottom"
          justify="start"
        >
          <div class="section-label">
            {{ $t("page.device.allWeathers") }}({{ source.weatherItems.length }})
          </div>
        </el-row>

        <div class="drones-selection">
          <div class="drones">
            <div
              v-for="(_weather, index) in source.weatherItems"
              :key="`weather-${index}`"
              :class="`drone`"
            >
              <img
                :class="`thumbnail ${_weather.inactive ? 'inactive' : ''}`"
                src="/asserts/icon/weather-station-icon.svg"
                @click="viewWeatherProfile(_weather.id)"
              >
              <span
                class="name"
                @click="weatherDialogOpen(_weather.id)"
              >{{ _weather.name }}</span>
              <span
                class="button"
                @click="weatherDialogOpen(_weather.id)"
              >{{ $t("button.edit") }}</span>
              <img
                class="select"
                src="/asserts/icon/loadmission-selected-icon.svg"
              >
            </div>
          </div>
        </div>
      </div>
      <default-dialog
        :title="
          weatherMode == 'add' ? $t('page.device.dialog.addWeatherTitle') : $t('page.device.dialog.updateWeatherTitle')
        "
        :show-dialog="weatherShow"
        width="651px"
        :next-button-text="weatherMode == 'add' ? $t('button.create') : $t('button.save')"
        :footer-align="weatherMode == 'add' ? 'center' : 'right'"
        @close="weatherDialogClose"
        @next="weatherDialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="weather"
            label-width="120px"
          >
            <el-form-item class="iconWeather">
              <el-select
                v-model="weather.name"
                class="device-name-align-center"
                clearable
                filterable
                allow-create
                @change="onStationSelected"
              >
                <el-option
                  v-for="(item, index) in weatherStations"
                  :key="`weather-station-${index}`"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <hr style="margin-bottom: 35px;">

            <el-form-item :label="$t('page.device.dialog.inActive')">
              <el-switch
                v-model="selectWeatherActive"
                class="action-button coretronic"
              />
            </el-form-item>
            <el-form-item :label="$t('page.device.dialog.stationID')">
              <el-input
                v-model="weather.station_id"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item
              v-if="weather.station_id==''"
              :label="$t('page.device.dialog.mac')"
            >
              <el-input
                v-model="weather.address"
                :disabled="weatherMode == 'update'"
              />
            </el-form-item>
            <div class="wrapNumber">
              <el-form-item :label="$t('page.device.dialog.gpsLocation')">
                <label>{{ $t("page.device.dialog.latitude") }}</label>

                <el-input-number
                  v-model="weather.latitude"
                  size="Default"
                  :controls="false"
                  :min="-90"
                />
                <label style="margin-left:12px;">{{ $t("page.device.dialog.longitude") }}</label>
                <el-input-number
                  v-model="weather.longitude"
                  size="Default"
                  :controls="false"
                  :min="-180"
                />
                <el-button
                  v-if="weather.station_id==''"
                  class="image-button gps-set"
                  @click="handleChange('weather')"
                />
              </el-form-item>
            </div>
          </el-form>
        </template>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="weatherMode == 'update'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="weatherDeleteShow = true"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </default-dialog>
      <el-dialog
        class="delete"
        :visible.sync="weatherDeleteShow"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t("page.device.deleteConfirm.title") }}</h2>

          <p>{{ $t("page.device.deleteConfirm.weatherBody") }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="weatherDeleteClose"
          >{{ $t("button.cancel") }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="weatherDeleteAction"
          >{{
            $t("button.delete")
          }}</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="nvr-list-container marign-top_80">
      <div
        v-if="source.nvrItems.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>{{ $t("page.device.noNVR.title") }}</h3>
          <p>{{ $t("page.device.noNVR.content") }}</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="nvrDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row
          class="section-button-row"
          type="flex"
          align="bottom"
          justify="end"
        >
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="nvrDialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t("button.add") }}
          </el-button>
        </el-row>
        <el-row
          class="section-label-row"
          type="flex"
          align="bottom"
          justify="start"
        >
          <div class="section-label">
            {{ $t("page.device.nvrs") }} ({{ source.nvrItems.length }})
          </div>
        </el-row>

        <div
          v-if="source.nvrItems.length > 0"
          class="drones-selection"
        >
          <div class="drones">
            <div
              v-for="(nvr, index) in source.nvrItems"
              :key="`nvr-${index}`"
              :class="`drone`"
            >
              <img
                class="thumbnail"
                src="/asserts/icon/devices-nvr-icon.svg"
                @click="viewNVRProfile(nvr.id)"
              >
              <span @click="nvrDialogOpen(nvr.id)">{{ nvr.name }}</span>
              <span
                class="button"
                @click="nvrDialogOpen(nvr.id)"
              >{{ $t("button.edit") }}</span>
              <img
                class="select"
                src="/asserts/icon/loadmission-selected-icon.svg"
              >
            </div>
          </div>
        </div>
      </div>
      <default-dialog
        :title="nvrMode == 'add' ? $t('page.device.dialog.addNVRTitle') : $t('page.device.dialog.updateNVRTitle')"
        :show-dialog="nvrShow"
        width="651px"
        :next-button-text="nvrMode == 'add' ? $t('button.create') : $t('button.save')"
        :footer-align="nvrMode == 'update' ? 'right' : 'center'"
        @close="nvrDialogClose"
        @next="nvrDialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="nvr"
            label-width="120px"
          >
            <el-form-item class="iconNVR">
              <el-input
                v-model="nvr.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <hr style="margin-bottom: 35px;">

            <el-form-item :label="$t('page.device.dialog.ip')">
              <el-input v-model="nvr.ip" />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.port')">
              <el-input v-model="nvr.port" />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.account')">
              <el-input v-model="nvr.account" />
            </el-form-item>

            <el-form-item :label="$t('page.device.dialog.password')">
              <el-input
                v-model="nvr.password"
                type="password"
              />
            </el-form-item>
          </el-form>
        </template>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="nvrMode == 'update'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="nvrDeleteShow = true"
          >{{ $t("button.delete") }}</el-button>
        </span>
      </default-dialog>
      <el-dialog
        class="delete"
        :visible.sync="nvrDeleteShow"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t("page.device.deleteConfirm.title") }}</h2>

          <p>{{ $t("page.device.deleteConfirm.nvrBody") }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="nvrDeleteClose"
          >{{ $t("button.cancel") }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="nvrDeleteAction"
          >{{ $t("button.delete") }} </el-button>
        </span>
      </el-dialog>
    </div>
  </admin-main-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { DisMeasure, TmpMeasure } from '@/utils/measure';
import { getDistance } from '@/utils/mission';

import droneApi from '@/services/api/domain/group/drone';
import nestApi from '@/services/api/domain/group/nest';
import cameraApi from '@/services/api/domain/group/camera';
import weatherApi from '@/services/api/domain/group/weather';
import nvrApi from '@/services/api/domain/group/nvr';
import DefaultDialog from '@/components/DefaultDialog.vue';
import stations from '@/config/cwb_stations';

export default {
  components: {
    DefaultDialog,
  },
  data() {
    return {
      // googleMapKey: config.googleMapKey,
      deleteShow: false,
      nestDeleteShow: false,
      cameraDeleteShow: false,
      weatherDeleteShow: false,
      nvrDeleteShow: false,
      droneId: null,
      nestId: null,
      cameraId: null,
      weatherId: null,
      nvrId: null,
      source: {
        items: [],
        nestItems: [],
        cameraItems: [],
        weatherItems: [],
        nvrItems: [],
        paging: {
          total: 0,
        },
      },
      filter: {},
      page: {
        loading: false,
      },
      drone: CopyObject(this.$store.state.drone.drone),
      nest: CopyObject(this.$store.state.nest.nest),
      camera: CopyObject(this.$store.state.camera.camera),
      weather: CopyObject(this.$store.state.weather.weather),
      nvr: CopyObject(this.$store.state.nvr.nvr),
      advShow: false,
      advNestShow: false,
      drones: [],
      selectedDrone: -1,
      powerOffDisable: null,
      power: true,
      timeout: 10000,
      showPowerOffConfirm: false,
      selectedDroneName: null,
      model: [
        {
          id: 'G52', name: 'G5.2', value: 'G5.2', gimbal1: true, gimbal2: true,
        },
        {
          id: 'G50', name: 'G5.0', value: 'G5.0', gimbal1: true, gimbal2: false,
        },
        {
          id: 'G60', name: 'G6.0', value: 'G6.0', gimbal1: false, gimbal2: false,
        },
      ],
      gimbal_1s: [
        { id: 'cam10', name: 'Camera10', value: 'Camera10' },
        { id: 'cam11', name: 'Camera11', value: 'Camera11' },
      ],
      gimbal_2s: [
        { id: 'cam20', name: 'Camera20', value: 'Camera20' },
        { id: 'cam21', name: 'Camera21', value: 'Camera21' },
      ],
      gimbal2: '',
      gimbal1: '',
      payload_info: [],
      newDroneGroupId: '',
      nestTmpSliderIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      groupId: 'user/groupId',
      droneMode: 'drone/getMode',
      droneData: 'drone/getDrone',
      droneShow: 'drone/shouldShow',
      authUser: 'user/user',
      group: 'user/group',
      groups: 'user/groups',
      nestMode: 'nest/getMode',
      nestData: 'nest/getNest',
      nestShow: 'nest/shouldShow',
      cameraMode: 'camera/getMode',
      cameraData: 'camera/getCamera',
      cameraShow: 'camera/shouldShow',
      weatherMode: 'weather/getMode',
      weatherData: 'weather/getWeather',
      weatherShow: 'weather/shouldShow',
      nvrMode: 'nvr/getMode',
      nvrData: 'nvr/getNVR',
      nvrShow: 'nvr/shouldShow',
      isAdmin: 'user/isAdmin',
      droneLatestMessage: 'mqtt/droneLatestMessage',
    }),
    maxNestAltitude() {
      if (this.group && this.group.max_altitude) {
        return DisMeasure.display(this.group.max_altitude);
      }
      return 120;
    },
    altitudeUnitHint() {
      return DisMeasure.unit();
    },
    nestTmpSliderKey() {
      return `sliderKey-${this.nestTmpSliderIndex}`;
    },
    nestMinTmp() {
      return TmpMeasure.display(0, 0, 0, 'ceil');
    },
    nestMaxTmp() {
      return TmpMeasure.display(40, 0, 0, 'floor');
    },
    tmpUnitHint() {
      return TmpMeasure.unit();
    },
    minMaxTmpUnitHint() {
      return `(${this.nestMinTmp} ~ ${this.nestMaxTmp} ${TmpMeasure.unit()})`;
    },
    gimbals() {
      if (this.payload_info.length > 0) {
        return this.payload_info.map((e) => {
          const v = `${e.payload_Id}-${e.description}`;
          return {
            id: e.payload_Id,
            name: v,
            value: v,
          };
        });
      }
      return [];
    },
    gimbal1Enabled() {
      if (this.drone && this.drone.model) {
        const model = this.model.find((m) => m.value == this.drone.model);
        return model != null && model.gimbal1 == true;
      }
      return false;
    },
    gimbal2Enabled() {
      if (this.drone && this.drone.model) {
        const model = this.model.find((m) => m.value == this.drone.model);
        return model != null && model.gimbal2 == true;
      }
      return false;
    },
    droneMq() {
      return this.drones.map((drone) => ({
        timeout: this.droneLatestMessage[drone.id].timeout ? this.droneLatestMessage[drone.id].timeout : {},
      }));
    },
    selectDroneActive: {
      set(value) {
        if (value) {
          this.drone.inactive = false;
        } else {
          this.drone.inactive = true;
        }
      },
      get() {
        return !this.drone.inactive;
      },
    },
    selectNestActive: {
      set(value) {
        if (value) {
          this.nest.inactive = false;
        } else {
          this.nest.inactive = true;
        }
      },
      get() {
        return !this.nest.inactive;
      },
    },
    selectCameraActive: {
      set(value) {
        if (value) {
          this.camera.inactive = false;
        } else {
          this.camera.inactive = true;
        }
      },
      get() {
        return !this.camera.inactive;
      },
    },
    selectWeatherActive: {
      set(value) {
        if (value) {
          this.weather.inactive = false;
        } else {
          this.weather.inactive = true;
        }
      },
      get() {
        return !this.weather.inactive;
      },
    },
    droneGroupCount() {
      return Math.floor(this.source.items.length / 5) + 1;
    },
    nestGroupCount() {
      return Math.floor(this.source.nestItems.length / 5) + 1;
    },
    droneIds() {
      return this.drones.map((drone) => drone.id);
    },
    canShutDown() {
      return (
        this.selectedDrone !== -1
        && this.drones[this.selectedDrone].inactive === false
        && this.droneMq[this.selectedDrone].timeout.alive
      );
    },
    weatherStations() {
      return stations.manneds
        .concat(stations.unmanneds)
        .map((station) => ({
          value: `${station.stationName}[${station.stationNameEN}]`,
          distance: getDistance(this.group.latitude, this.group.longitude, station.latitude, station.longitude),
          ...station,
        }))
        .sort((a, b) => a.distance - b.distance);
    },
  },

  watch: {
    drones(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }
      old.forEach((drone) => {
        this.unsubscribeDrone(drone);
      });
      now.forEach((drone) => {
        this.subscribeDrone({ drone });
      });
    },
  },
  async created() {
    if (this.$router.currentRoute.params.clear) {
      this.setDroneMode(null);
      this.setNestMode(null);
      this.setCameraMode(null);
      this.setWeatherMode(null);
      this.setNVRMode(null);
    }
    if (this.$route.query.nestId !== undefined) {
      await this.nestDialogOpen(this.$route.query.nestId);
      this.advNestShow = true;
    }
  },
  mounted() {
    this.loadNVRData();
    this.loadWeatherData();
    this.loadCameraData();
    this.loadNestData();
    this.loadData();
  },
  async destroyed() {
    this.drones.forEach((drone) => {
      this.unsubscribeDrone({ drone });
    });
  },
  methods: {
    ...mapActions({
      setDroneMode: 'drone/setMode',
      setDrone: 'drone/setDrone',
      setNestMode: 'nest/setMode',
      setNest: 'nest/setNest',
      setCameraMode: 'camera/setMode',
      setCamera: 'camera/setCamera',
      setWeatherMode: 'weather/setMode',
      setWeather: 'weather/setWeather',
      setNVRMode: 'nvr/setMode',
      setNVR: 'nvr/setNVR',
      importNest: 'nest/importNest',
      exportNest: 'nest/exportNest',
      subscribeDrone: 'mqtt/subscribeDrone',
      unsubscribeDrone: 'mqtt/unsubscribeDrone',
    }),
    onStationSelected(item) {
      const selected = this.weatherStations.find((station) => station.value === item);
      if (selected) {
        this.weather.station_id = selected.stationID;
        this.weather.latitude = selected.latitude;
        this.weather.longitude = selected.longitude;
      } else {
        this.weather.station_id = '';
        this.weather.latitude = null;
        this.weather.longitude = null;
      }
    },
    handleTempChange() {
      this.nestTmpSliderIndex += 1;
      if (this.nestTmpSliderIndex > 100) {
        this.nestTmpSliderIndex = 0;
      }
    },
    async powerOff() {
      if (this.droneMode === 'add') {
        this.drone.charge = !this.drone.charge;
        this.showPowerOffConfirm = false;
        return;
      }
      this.powerOffDisable = true;
      this.cwb476Await = await droneApi.execute(this.group.id, this.drones[this.selectedDrone].id, 'shutdown', {
        // tilt: this.tilt,
      });
      this.showPowerOffConfirm = false;
      this.dialogClose();
    },
    async viewProfile(droneId) {
      this.droneId = droneId;
      try {
        this.page.loading = true;
        const { data } = await droneApi.get(this.groupId, droneId);
        this.setDrone(data);
        this.drone = CopyObject(this.$store.state.drone.drone);
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.$router.push({
        name: 'deviceProfile',
      });
    },

    async viewNestProfile(nestId) {
      // this.nestId = nestId;
      try {
        this.page.loading = true;
        this.nestId = nestId;
        const { data } = await nestApi.get(this.groupId, nestId);
        this.setNest({
          ...data,
          temperature: [data.min_temperature, data.max_temperature],
        });
        this.nest = CopyObject(this.$store.state.nest.nest);
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.$router.push({
        name: 'deviceNestProfile',
      });
    },

    async viewCameraProfile(cameraId) {
      // this.cameraId = cameraId;
      try {
        this.page.loading = true;
        this.cameraId = cameraId;
        const { data } = await cameraApi.get(this.groupId, cameraId);
        this.setCamera(data);
        this.camera = CopyObject(this.$store.state.camera.camera);
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.$router.push({
        name: 'deviceCameraProfile',
      });
    },

    async viewWeatherProfile(weatherId) {
      // this.cameraId = cameraId;
      try {
        this.page.loading = true;
        this.weatherId = weatherId;
        const { data } = await weatherApi.get(this.groupId, weatherId);
        this.setWeather(data);
        this.weather = CopyObject(this.$store.state.weather.weather);
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.$router.push({
        name: 'deviceWeatherProfile',
      });
    },

    async viewNVRProfile(nvrId) {
      // this.cameraId = cameraId;
      try {
        this.page.loading = true;
        this.nvrId = nvrId;
        const { data } = await nvrApi.get(this.groupId, nvrId);
        this.setNVR(data);
        this.nvr = CopyObject(this.$store.state.nvr.nvr);
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.$router.push({
        name: 'deviceNVRProfile',
      });
    },

    handleChange(type) {
      if (type === 'nest') {
        this.setNest(this.nest);
      }
      if (type === 'camera') {
        this.setCamera(this.camera);
      }
      if (type === 'weather') {
        this.setWeather(this.weather);
      }

      this.$router.push({
        name: 'deviceMap',
        params: {
          title: type,
          type,
        },
      });
    },

    dialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setDroneMode(null);
      this.drone = CopyObject(this.$store.state.drone.drone);
      this.advShow = false;
    },

    advDialogOpen() {
      if (this.canShutDown) {
        this.power = true;
        this.powerOffDisable = false;
      } else {
        this.power = false;
        this.powerOffDisable = true;
      }
      this.advShow = true;
    },

    advNestDialogOpen() {
      this.advNestShow = true;
    },

    advDialogSave() {
      if (this.droneMode === 'add') {
        this.advShow = false;
      } else {
        this.dialogSave();
      }
    },

    advDialogClose() {
      if (this.droneMode === 'add') {
        this.drone.custom_rgb_url.push_url = '';
        this.drone.custom_rgb_url.pop_url = '';
        this.drone.custom_thermal_url.push_url = '';
        this.drone.custom_thermal_url.pop_url = '';
      }
      this.advShow = false;
    },

    advNestDialogClose() {
      this.advNestShow = false;
    },

    nestDialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setNestMode(null);
      this.nest = CopyObject(this.$store.state.nest.nest);
    },

    cameraDialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setCameraMode(null);
      this.camera = CopyObject(this.$store.state.camera.camera);
    },

    weatherDialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setWeatherMode(null);
      this.weather = CopyObject(this.$store.state.weather.weather);
    },

    nvrDialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setNVRMode(null);
      this.nvr = CopyObject(this.$store.state.nvr.nvr);
    },
    async dialogSave() {
      if (this.droneMode === 'update' && this.newDroneGroupId) {
        this.drone.new_group_id = this.newDroneGroupId;
        this.newDroneGroupId = '';
        this.selectedDrone = -1;
      }

      this.setDrone(this.drone);
      try {
        if (this.droneMode === 'add') {
          const status = await droneApi.create(this.groupId, this.drone);
          this.$showSuccess(status.msg);
        } else {
          const status = await droneApi.update(this.groupId, this.drone.id, this.drone);
          this.$showSuccess(status.msg);
        }
      } catch (error) {
        this.$showFail(error);
        return;
      }
      this.setDroneMode(null);
      this.drone = CopyObject(this.$store.state.drone.drone);
      this.loadData();
      this.advDialogClose();
    },
    advNestDialogSave() {
      if (this.nestMode === 'add') {
        this.advNestShow = false;
      } else {
        this.nestDialogSave();
      }
    },

    async nestDialogSave() {
      this.setNest(this.nest);
      const nest = await this.exportNest();
      nest.max_temperature = TmpMeasure.real(nest.max_temperature, 2);
      nest.min_temperature = TmpMeasure.real(nest.min_temperature, 2);
      nest.altitude = DisMeasure.real(nest.altitude, 2);

      try {
        if (this.nestMode === 'add') {
          this.nest = { ...this.nest, ...nest };
          const status = await nestApi.create(this.groupId, this.nest);
          this.$showSuccess(status.msg);
        } else {
          const status = await nestApi.update(this.groupId, this.nest.id, nest);
          this.$showSuccess(status.msg);
        }
      } catch (error) {
        this.$showFail(error);
        return;
      }
      this.setNestMode(null);
      this.nest = CopyObject(this.$store.state.nest.nest);
      this.advNestShow = false;
      this.loadNestData();
    },
    async cameraDialogSave() {
      this.setCamera(this.camera);
      try {
        if (this.cameraMode === 'add') {
          const status = await cameraApi.create(this.groupId, this.camera);
          this.$showSuccess(status.msg);
        } else {
          const status = await cameraApi.update(this.groupId, this.camera.id, this.camera);
          this.$showSuccess(status.msg);
        }
      } catch (error) {
        this.$showFail(error);
        return;
      }
      this.setCameraMode(null);
      this.camera = CopyObject(this.$store.state.camera.camera);
      this.loadCameraData();
    },

    async weatherDialogSave() {
      this.setWeather(this.weather);
      try {
        if (this.weatherMode === 'add') {
          const status = await weatherApi.create(this.groupId, this.weather);
          this.$showSuccess(status.msg);
        } else {
          const status = await weatherApi.update(this.groupId, this.weather.id, this.weather);
          this.$showSuccess(status.msg);
        }
      } catch (error) {
        this.$showFail(error);
        return;
      }
      this.setWeatherMode(null);
      this.weather = CopyObject(this.$store.state.weather.weather);
      this.loadWeatherData();
    },

    async nvrDialogSave() {
      this.setNVR(this.nvr);
      try {
        if (this.nvrMode === 'add') {
          const status = await nvrApi.create(this.groupId, this.nvr);
          this.$showSuccess(status.msg);
        } else {
          const status = await nvrApi.update(this.groupId, this.nvr.id, this.nvr);
          this.$showSuccess(status.msg);
        }
      } catch (error) {
        this.$showFail(error);
        return;
      }
      this.setNVRMode(null);
      this.nvr = CopyObject(this.$store.state.nvr.nvr);
      this.loadNVRData();
    },

    deleteClose() {
      this.deleteShow = false;
    },
    nestDeleteClose() {
      this.nestDeleteShow = false;
    },
    cameraDeleteClose() {
      this.cameraDeleteShow = false;
    },
    weatherDeleteClose() {
      this.weatherDeleteShow = false;
    },
    nvrDeleteClose() {
      this.nvrDeleteShow = false;
    },
    async deleteAction() {
      try {
        this.deleteShow = false;
        this.dialogClose();
        this.page.loading = true;

        const status = await droneApi.delete(this.groupId, this.droneId);
        this.$showSuccess(status.msg);
        this.selectedDrone = -1;
      } catch (error) {
        this.$showFail(error);
      }

      this.loadData();
    },

    async nestDeleteAction() {
      this.nestId = this.$store.state.nest.nest.id;
      try {
        this.nestDeleteShow = false;
        this.nestDialogClose();
        this.page.loading = true;

        const status = await nestApi.delete(this.groupId, this.nestId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadNestData();
    },

    async cameraDeleteAction() {
      this.cameraId = this.$store.state.camera.camera.id;
      try {
        this.cameraDeleteShow = false;
        this.cameraDialogClose();
        this.page.loading = true;

        const status = await cameraApi.delete(this.groupId, this.cameraId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadCameraData();
    },

    async weatherDeleteAction() {
      this.weatherId = this.$store.state.weather.weather.id;
      try {
        this.weatherDeleteShow = false;
        this.weatherDialogClose();
        this.page.loading = true;

        const status = await weatherApi.delete(this.groupId, this.weatherId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadWeatherData();
    },

    async nvrDeleteAction() {
      this.nvrId = this.$store.state.nvr.nvr.id;
      try {
        this.nvrDeleteShow = false;
        this.setNVRMode(null);
        this.page.loading = true;

        const status = await nvrApi.delete(this.groupId, this.nvrId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadNVRData();
    },
    async loadData() {
      try {
        this.page.loading = true;
        const { data } = await droneApi.getAll(this.groupId);
        // this.source.items = data.drones;
        // this.source.items=this.source.items.filter(()=>true);
        const drones = data.drones.filter(() => true);
        this.drones = drones;
        this.source.items = data.drones;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },
    async loadNestData() {
      try {
        this.page.loading = true;
        const { data } = await nestApi.getAll(this.groupId);
        this.source.nestItems = data.nests;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },
    async loadCameraData() {
      try {
        this.page.loading = true;
        const { data } = await cameraApi.getAll(this.groupId);
        this.source.cameraItems = data.cameras;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },

    async loadWeatherData() {
      try {
        this.page.loading = true;
        const { data } = await weatherApi.getAll(this.groupId);
        this.source.weatherItems = data.stations;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },

    async loadNVRData() {
      try {
        this.page.loading = true;
        const { data } = await nvrApi.getAll(this.groupId);
        this.source.nvrItems = data.nvrs;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },

    async dialogOpen(droneId, index) {
      if (droneId) {
        this.droneId = droneId;
        try {
          this.page.loading = true;
          const { data } = await droneApi.get(this.groupId, droneId);
          this.setDrone(data);
          this.drone = CopyObject(this.$store.state.drone.drone);
          // this.drone.inactive=!this.drone.inactive;
          this.setDroneMode('update');
          this.selectedDrone = index;
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.selectedDrone = -1;
        this.setDroneMode('add');
      }
    },

    async nestDialogOpen(nestId) {
      if (nestId) {
        this.nestId = nestId;
        try {
          this.page.loading = true;
          const { data } = await nestApi.get(this.groupId, nestId);
          this.importNest(CopyObject(data));
          this.nest = CopyObject(this.$store.state.nest.nest);
          // this.nest.
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.nest = CopyObject(this.$store.state.nest.nest);
        this.setNestMode('add');
      }

      this.nest.temperature[0] = TmpMeasure.display(this.nest.temperature[0], 0, 0, 'round');
      this.nest.temperature[1] = TmpMeasure.display(this.nest.temperature[1], 0, 0, 'round');
      this.nest.altitude = DisMeasure.display(this.nest.altitude);
      return true;
    },

    async cameraDialogOpen(cameraId) {
      if (cameraId) {
        this.cameraId = cameraId;
        try {
          this.page.loading = true;
          const { data } = await cameraApi.get(this.groupId, cameraId);
          this.setCamera(data);
          this.camera = CopyObject(this.$store.state.camera.camera);
          this.setCameraMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setCamera(this.camera);
        this.setCameraMode('add');
      }
    },
    async weatherDialogOpen(weatherId) {
      if (weatherId) {
        this.weatherId = weatherId;
        try {
          this.page.loading = true;
          const { data } = await weatherApi.get(this.groupId, weatherId);
          this.setWeather(data);
          this.weather = CopyObject(this.$store.state.weather.weather);
          this.setWeatherMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setWeather(this.weather);
        this.setWeatherMode('add');
      }
    },
    async nvrDialogOpen(nvrId) {
      if (nvrId) {
        this.nvrId = nvrId;
        try {
          this.page.loading = true;
          const { data } = await nvrApi.get(this.groupId, nvrId);
          this.setNVR(data);
          this.nvr = CopyObject(this.$store.state.nvr.nvr);
          this.setNVRMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setNVR(this.nvr);
        this.setNVRMode('add');
      }
    },
    get_payload_info(model) {
      droneApi.getPayloadInfo(this.groupId, this.droneId, model).then((data) => {
        this.payload_info = data.data;
      });
    },
    change_drone_model(model) {
      this.drone.gimbal1 = '';
      this.drone.gimbal2 = '';
      this.get_payload_info(model);
    },
    on_open_drone() {
      this.get_payload_info(this.drone.model);
    },
  },
  beforeRouteEnter(to, from, next) {
    // 判斷網址來源，如果跟 mission 無關那就留下 params 告訴 created 要清掉現有任務
    const pathFirstLevel = from.path.split('/')[1];
    if (pathFirstLevel.toLocaleLowerCase() !== 'device') {
      to.params.clear = true;
    } else {
      to.params.clear = false;
    }
    next();
  },
};
</script>

<style lang="scss">
.marign-top_80 {
  margin-top: 80px;
}

.wrapNumber {
  .el-form-item {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-input-number {
        .el-input-number__decrease,
        .el-input-number__increase {
          position: absolute;
          z-index: 1;
          top: 1px;
          width: 40px;
          height: auto;
          text-align: center;
          background: #f5f7fa;
          color: #606266;
          cursor: pointer;
          font-size: 13px;
          display: none;
        }
        width: 165px;
      }

      // .el-input.el-input--small{
      //   width:135px;
      // }
    }
  }

  label {
    color: #606266;
    width: 30px;
  }
}

.no-mission {
  height: 461px;
  border-style: dashed;
  border-color: #e1e1e1;
  border-radius: 13px;
  text-align: center;

  .no-mission__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  h3 {
    color: #a5a5a5;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 22px;
  }

  p {
    color: rgba(165, 165, 165, 0.9);
  }

  button {
    margin-top: 66px;
    margin-bottom: 66px;
  }
}

.drones-selection {
  background-color: rgba(216, 216, 216, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  // overflow: auto;
  .drones {
    margin-top: 50px;
    margin-left: 38px;
    margin-right: 39px;
    // margin-right: 40px;
    .drone {
      cursor: pointer;
      float: left;
      width: 111px;
      height: 143px;
      border-radius: 4px;
      text-align: center;
      position: relative;
      margin-left: 15px;
      margin-right: 15px;
      span {
        display: block;
        margin-top: 4px;
        // font-family: HelveticaNeue;
        font-size: 12px;
        color: var(--greyish-brown-90);
      }
      img.thumbnail {
        width: 78px;
        height: 78px;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      img.inactive {
        width: 78px;
        height: 78px;
        filter: grayscale(100%);
      }

      img.select {
        display: none;
      }
      img.opacity {
        opacity: 0.4;
      }
      span.button {
        border-radius: 8.5px;
        border: solid 1px #979797;
        width: 40px;
        height: 15px;
        // font-family: HelveticaNeue;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #979797;
        text-align: center;
        margin-left: 34px;
        margin-top: 6px;
      }
      span.button:hover {
        color: #58bd29;
        border: solid 1px #58bd29;
      }
    }

    .drone:first-child:last-child {
      float: left;
      // margin-left: auto;
      // margin-right: auto;
    }
  }
}

.device-name-align-center {
  margin-top: 23px;
  width: 100%;
}

.right-button {
  float: left;
}

.adv {
  color: skyblue;
  background: #f5f5f5;
  border-color: skyblue;
}

.adv:hover {
  background: rgba(79, 159, 221, 0.1);
  color: #4f9fdd;
  border-color: #4f9fdd;
}

button.image-button.gps-set {
  background-image: url("/asserts/icon/devices-set-location-icon-focus.svg");
  outline: none;
  width: 24px;
  height: 24px;
}

.location-slider-group1 {
  display: flex;
  .start-number, .end-number {
    width: 60px;
    input.el-input__inner {
      text-align: center;
      width: 60px;
    }
  }
  label.with_error_hint{
    margin-left : 8px;
  }
  .slider {
    flex-grow: 1;
    margin: 0 20px;
    .slider__content {
      label.hint {
        text-align: center;
        color: #838383;
        height: 16px;
        line-height: 16px;
        width: 100%;
        display: inline-block;
      }
    }
  }
}

.el-slider.setting-altitude-slider {
  margin-left: 10px;
  margin-right: 10px;
  .el-slider__runway {
    background-color: #d8d8d8;
  }

  .el-slider__bar {
    background-color: #ffc900;
  }
  .el-slider__button {
    width: 22px;
    height: 22px;
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.altitude-slide {
  display: table;
  .row {
    display: flex;
    align-items: center;
    .el-input-number {
      width: 56px;
    }
    label.with_error_hint{
      margin-left : 8px;
    }
    .slider {
      display: table-cell;
      width: 280px;
      margin : 0 20px;

      .el-slider.setting-altitude-slider-reverse {
        margin-left: 10px;
        margin-right: 10px;
        .el-slider__runway {
          background-color: #ffc900;
        }
        .el-slider__bar {
          background-color: #d8d8d8;
        }
        .el-slider__button {
          width: 22px;
          height: 22px;
          border: 0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
